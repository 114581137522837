import { useState, useEffect } from "react";
import "./App.css";
import NationRoute from "./components/common/NationRoute";
import logoImg from "./assets/images/png/logo.png";
import AOS from "aos";

import "react-notifications/lib/notifications.css";
import "aos/dist/aos.css";
import "../src/assets/MainCss.css";
import "../src/assets/fontawesome/css/all.css";
import { NotificationContainer } from "react-notifications";
// import "../src/assets/scss/_style.scss";

function App() {
  const [preloaderActive, setpreloaderActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setpreloaderActive(true);
    }, 2000);
    if (preloaderActive) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");
    }
  });

  // You can also use <link> for styles
  // ..

  AOS.init({
    once: true, // whether animation should happen only once - while scrolling down
    duration: 800,
  });

  return (
    <>
      <div className='relative'>
        <div
          className={`${
            preloaderActive ? "left-[-50%]" : ""
          } bg-primary-load fixed h-screen left-0 top-0 w-[50%] z-[999] transition-all duration-[0.8s] ease-in-out `}
        ></div>

        <div
          className={`${
            preloaderActive ? "right-[-50%]" : ""
          } bg-primary-load fixed h-screen right-0 top-0 w-[50%] z-[999] transition-all duration-[0.8s] ease-in-out `}
        ></div>
        {preloaderActive ? (
          ""
        ) : (
          <div
            className={`
          } bg-primary-load fixed h-screen right-0 top-0 w-full z-[999] flex justify-center items-center `}
          >
            <img src={logoImg} alt='logoImg' />
          </div>
        )}
        <NotificationContainer />
        <NationRoute />
      </div>
    </>
  );
}

export default App;
