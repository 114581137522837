import React from "react";
import fullCustomizable from "../../../assets/images/jpg/DataSharing.jpg";
import { Link } from "react-router-dom";
const DataSharing = () => {
  return (
    <>
      <section className='py-12 md:py-20 lg:py-28 xl:py-[100px] bg-[rgba(168,168,168,0.08)] overflow-x-hidden'>
        <div className='container'>
          <div className='flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center'>
            <div
              className='order-2 lg:order-none'
              data-aos='fade-right'
              data-aos-duration='1500'
            >
              <div className='max-w-[558px]'>
                <h2 className='text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4'>
                  Data Sharing
                </h2>
                <p className='text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12'>
                  Storage units enable seamless data sharing and collaboration
                  among team members or across different departments. Users can
                  easily share files and folders, set permissions, and track
                  changes made to the data.
                </p>
                <Link
                  to='/sign-up'
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className='p-[8px_16px] inline-block sm:p-[14px_26px] first_btn font-semibold whitespace-nowrap font-Manrope text-base'
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
            <div data-aos='fade-left' data-aos-duration='1500'>
              <div className='max-w-[520px]'>
                <img
                  className='w-full'
                  src={fullCustomizable}
                  alt='fullCustomizable'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataSharing;
