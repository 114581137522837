import React from "react";
import BigMeetImg from "../../../assets/images/png/logo.png";
import MeetMiddleCricle from "../../../assets/images/png/logo.png";
const CostEfficiency = () => {
  return (
    <>
      <section className='my-12 md:my-20 lg:my-28 xl:mt-[100px] xl:mb-[160px] overflow-x-hidden'>
        <div className='container'>
          <div className='flex flex-col lg:flex-row gap-12 lg:gap-4 xl:gap-[62px] items-center'>
            <div
              className='order-2 lg:order-none'
              data-aos='fade-right'
              data-aos-delay='3000'
            >
              <div className='max-w-[553px]'>
                <h2 className='text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4'>
                  Cost Efficiency
                </h2>
                <p className='text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70'>
                  Storage units offer cost-effective solutions for storing large
                  amounts of data. They eliminate the need for physical storage
                  devices, such as external hard drives or servers, which can be
                  expensive to purchase and maintain.
                </p>
              </div>
            </div>
            <div>
              <div
                className='max-w-[553px]'
                data-aos='fade-left'
                data-aos-delay='3000'
              >
                <div className='lg:translate-x-[40px] relative overflow-hidden'>
                  {/* <img className='w-full  box' src={BigMeetImg} alt='' /> */}
                  <img className='w-full' src={BigMeetImg} alt='' />
                  {/* <img
                    className='absolute top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2 w-[100px] sm:w-[218px] '
                    src={MeetMiddleCricle}
                    alt=''
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CostEfficiency;
