import React from "react";
import BetterSimpler from "../../../assets/images/jpg/Accessability.jpg";
import { Link } from "react-router-dom";
const Accessibility = () => {
  return (
    <>
      <section className='my-12 md:my-20 lg:my-28 xl:my-[150px] overflow-x-hidden'>
        <div className='container'>
          <div className='flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center'>
            <div data-aos='fade-right' data-aos-duration='1500'>
              <div className='max-w-[520px]'>
                <img
                  className='w-full'
                  src={BetterSimpler}
                  alt='BetterSimpler'
                />
              </div>
            </div>
            <div data-aos='fade-left' data-aos-duration='1500'>
              <div className='max-w-[558px]'>
                <h2 className='text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4'>
                  Accessibility
                </h2>
                <p className='text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-0'>
                  Storage units provide easy access to stored data, allowing
                  users to retrieve and modify files quickly and efficiently.
                  This is especially important in a business setting where
                  multiple users may need to access the same data
                  simultaneously.
                </p>
                <Link
                  to='/account-sign-up'
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className='p-[8px_16px]  inline-block sm:p-[14px_26px] first_btn font-semibold whitespace-nowrap font-Manrope text-base'
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accessibility;
