import React from "react";
import { Link } from "react-router-dom";

const CollaborateInnovate = () => {
  return (
    <>
      <section className='bg-CollaborateInnovate bg-center  bg-no-repeat bg-cover min-h-[344px] md:min-h-[688px] flex items-center overflow-x-hidden relative'>
        {/* {mb-16 sm:mb-20 md:mb-28 lg:mb-[120px]} */}
        <div className='absolute top-0 right-0 bottom-0 left-0 bg-white opacity-75'></div>
        <div className='container z-10'>
          <div data-aos='fade-right' data-aos-delay='200'>
            <h2 className='text-[32px] md:text-xl lg:text-2xl font-extrabold text-black  font-Manrope leading-[130%] max-w-[826.01px] '>
              Safe and Secure Storage Solutions. Get Your Unit Today:{" "}
              <br className='hidden sm:block' />
              <span className='text-primary font-bold'>
                Partner with us
              </span>{" "}
              for
              <br className='hidden sm:block' /> success.
            </h2>
            <Link
              to='/get-started'
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              className='p-[8px_16px] inline-block sm:p-[14px_33px] first_btn font-semibold font-Manrope whitespace-nowrap font-base mt-8 lg:mt-[41px]'
            >
              Get Started
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default CollaborateInnovate;
