import React, { useState, useRef } from "react";

import { PlayButton } from "../../components/common/Icon";
import ProcessPoster from "../../assets/images/webp/Process.webp";
import ProcessImage from "../../assets/images/jpg/iStock-1652006079.jpg";

const Process = () => {
  const myvideo = useRef(null);
  const [videoIcon, setVideoIcon] = useState(false);
  const playVideo = () => {
    setVideoIcon(true);
    myvideo.current.play();
  };
  return (
    <>
      <section className='my-12 md:my-14 lg:my-[120px]'>
        <div className='container'>
          <h2 className=' text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold text-center'>
            How to Use Our Storage Units
          </h2>
          <div className='max-w-[1031px] mx-auto mt-8 sm:mt-14 lg:mt-20 h-[450px] lg:h-[580px]  relative'>
            <img
              // controls={videoIcon ? true : false}
              // ref={myvideo}
              className='w-full h-full object-cover border-8 md:border-[20px] border-[#C9CCD3]  rounded-[12px]'
              // muted
              // poster={ProcessPoster}
              src={ProcessImage}
            />
            {/* {videoIcon ? (
              ""
            ) : (
              <span
                className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer'
                onClick={() => playVideo()}
              >
                <PlayButton />
              </span>
            )} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Process;
