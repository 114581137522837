import React from "react";
import FindLocationBox from "./FindLocationBox";
import FindLocation from "../../common/FindLocation";

const Hero = () => {
  return (
    <>
      <section className='bg-HeroBg flex items-center bg-cover bg-no-repeat bg-white bg-opacity-75 z-0 py-10'>
        {/*h-[calc(100vh-500px)] bg-HeroBg hidden sm:visible <div className="h-full w-full md:hidden absolute bg-[#cac1c11e] top-0 z-[2]"></div> */}
        {/* Shading div */}
        {/* <div className='absolute top-0 left-0 w-full h-full bg-white/25 z-0'></div> */}
        {/* <div class='absolute top-0 right-0 bottom-0 left-0 bg-white opacity-50 z-0'></div> */}
        <div className='container'>
          <div className='max-w-[636px] '>
            <h1 className=' text-[32px] md:text-xl lg:text-2xl text-black font-extrabold font-Manrope leading-[130%] w-screen'>
              Self Storage Units Your Space,
              <br />
              <span className='text-primary font-bold'>Our Priority.</span>
            </h1>
            <p
              className='mt-4 mb-16 text-black font-Poppins me-4'
              style={{ maxWidth: "400px" }}
            >
              <span
                style={{
                  textShadow:
                    "1px 1px 2px white, 1px 1px 10px white, 1px 1px 14px white, 0 0 1em white, 0 0 0.2em white",
                }}
              >
                Lincoln Land Storage Units is a trusted and reliable storage
                facility that offers secure and convenient storage solutions for
                individuals and businesses. With a wide range of unit sizes
                available, customers can find the perfect space to store their
                belongings, whether it's furniture, documents, or equipment.
              </span>
            </p>
            {/* <form
              action=""
              className="flex h-[54px] justify-between items-center bg-white rounded-[6px] max-w-[416px] mt-10"
            >
              <input
                className=" bg-white h-full p-3 w-full sm:py-[16px] rounded-[6px] outline-none"
                type="Email"
                required
                placeholder=" Enter Your Email"
              />
              <button className="bg-primary h-[54px] px-2 py-3 sm:py-[16px] sm:px-[27px] text-xs sm:text-base font-semibold text-white font-Poppins    rounded-r-[6px] whitespace-nowrap">
                Request a Demo
              </button>
            </form> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
