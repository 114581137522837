import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from "react";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Paragraph from "antd/es/typography/Paragraph";
import GooglePlayIcon from "../../../src/assets/images/png/Google-Play-Logo.png";
import ApplePlayIcon from "../../../src/assets/images/png/apple-app-store.png";
import { NotificationManager } from "react-notifications";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import VideoPlayer from "./VideoP.jsx";
import Datepicker from "react-tailwindcss-datepicker";
import {
  Button,
  Select,
  Row,
  Col,
  Card,
  Tooltip,
  Badge,
  Statistic,
  List,
  Carousel,
  Space,
  Avatar,
  Rate,
  Image,
  Drawer,
  Typography,
  Divider,
  Tag,
  Modal,
  DatePicker as ADatePicker,
  Radio,
  Form,
  InputNumber,
  Checkbox,
  Popover,
  Input,
  Table,
  notification,
  Alert,
  Spin,
  Descriptions,
  Switch,
} from "antd";

const { Title, Text, Link } = Typography;
const { Search } = Input;
const { RangePicker } = ADatePicker;
const dateFormat = "MM/DD/YYYY";

import {
  GoogleOutlined,
  AppleOutlined,
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
  PhoneOutlined,
  MailOutlined,
  FacebookOutlined,
  SkypeOutlined,
  VideoCameraOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useSetState } from "ahooks";
import { actions as actionActions } from "../../reducers/actionReducer.js";
import { types as actionTypes } from "../../reducers/actionReducer.js";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import {
  getSiteDataSelector,
  // getUserDataSelector,
  getReferenceDataSelector,
  getSessionDataSelector,
  getErrorDataSelector,
  getMobileDeviceSelector,
} from "../../reselect/sagaReselect.js";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween.js";
dayjs.extend(isBetween);
import * as uuid from "uuid";
import AppConfig from "../../constants/AppConfig.js";
import { PNfetch } from "../../api/pnfetch.js";
import { isNil, trim, sortBy, round, cloneDeep } from "lodash";
import { PageHeader } from "@ant-design/pro-layout";
import { formatMoney, unformat } from "accounting-js";
import CameraRTC from "../ForTruckDrivers/WebRTC/cameraRtc.jsx";
import Login from "../ForTruckDrivers/login/custlogin.jsx";
import CloverConnect from "../ForTruckDrivers/cloversetup/index.jsx";
import {
  rangePresets,
  rangeForwardPresets,
  formatPhoneNumber,
  printCustName,
  printSiteName,
} from "../../../src/util/common.js";

// import ClipLoader from 'react-spinners/ClipLoader';
// const Spinner = () => {
//   return (
//     <div style={{ width: '100px', margin: 'auto', display: 'block' }}>
//       <ClipLoader color="#52bfd9" size={100}/>
//     </div>
//   );
// };

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const layoutCol = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const prorateArray = [
  // { id: 1, desc: "No Prorating" },
  // { id: 2, desc: "Custom Prorating" },
  { id: 2, desc: "From Move-in Date" },
  {
    id: 3,
    desc: "Prorate this month and bill full billing cycle starting next month",
  },
  {
    id: 4,
    desc: "Prorate this month and bill now for the first billing cycle",
  },
];

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const fileToBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(null, reader.result);
  };
  reader.onerror = function (error) {
    cb(error, null);
  };
};

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const gridStyle = {
  width: "100%",
  textAlign: "center",
  borderRadius: 12,
  padding: "12px 16px 16px",
  marginBottom: 16,
};

const contentStyle = {
  // height: "240px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  maxWidth: "300px",
  maxHeight: "300px",
};

const formatNumber = (val) => {
  return formatMoney(val, {
    symbol: "$",
    precision: 2,
    thousand: ",",
    format: {
      pos: "%s %v",
      neg: "%s (%v)",
      zero: "%s --",
    },
  });
};

const FindLocation = (props) => {
  const [formUseRef] = Form.useForm();
  const [formVehicleUseRef] = Form.useForm();

  const openNotificationWithIcon = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };

  const setSaveModalVisible = (visible) => {
    setState({ saveModalVisible: visible });
  };

  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const [dateRangeValue, setValue] = useState({
    startDate: dayjs(),
    endDate: dayjs(),
  });
  const [state, setState] = useSetState({
    subscription_deposit_amount: 0,
    subscription_total: 0,
    prorateOption: 4,
    createSubscription: "Y",
    invoiceRecurEndDtPeriod: "Never",
    spaceRecord: undefined,
    vehicleRecord: undefined,
    filteredSpaceTypeName: "ALL",
    tax_amount: 0,
    total_reserve: 0,
    showSiteDetailsModal: false,
    currentSite: undefined,
    saveReserveVersion: 1,
    saveModalVisible: false,
    file: undefined,
    fileType: undefined,
    fileContent: undefined,
    document_name: undefined,
    originalFile: undefined,
    newVehicleModalVisible: false,
    searchText: "",
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    animating: false,
    site_id: undefined,
    tax_rate: undefined,
    tax: true,
    site_name: undefined,
    vacancyData: undefined,
    vacancyFilteredData: undefined,
    cameraVisible: false,
    showLogin: false,
    showReserve: false,
    showSummary: false,
    invoice_user_id: undefined,
    cloverPaymentModalVisible: false,
    customer: undefined,
    unit: 1,
    sites: [],
    renderVersion: 1,
    space_id: undefined,
    prorateSubStartDt: dayjs(),
    subscription_start_dt: dayjs(),
    subscription_end_dt: dayjs(), //.add(7, "day"),
    space_type_id: -1,
    siteRec: undefined,
    quantity: 1,
    setupFee: 5,
    card_id: undefined,
    user: undefined,
    name: undefined,
    invoiceItemsData: [],
    serviceItemsData: [],
    discountItemsData: [],
    note: "",
    availableSpaces: [],
    vehicle_type_id: 1,
    vehicleTypes: [
      {
        vehicle_type_id: 1,
        vehicle_type_name: "Tractor w/Trailer",
      },
      {
        vehicle_type_id: 2,
        vehicle_type_name: "Tractor Only",
      },
      {
        vehicle_type_id: 3,
        vehicle_type_name: "Trailer Only",
      },
      {
        vehicle_type_id: 4,
        vehicle_type_name: "Oversize Load",
      },
      {
        vehicle_type_id: 5,
        vehicle_type_name: "Tanker",
      },
      {
        vehicle_type_id: 6,
        vehicle_type_name: "Box Truck or RV Less than 40",
      },
      {
        vehicle_type_id: 7,
        vehicle_type_name: "Locker",
      },
    ],

    mapSrc:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM&zoom=17&&maptype=satellite&q=",
  });

  const location = useLocation();
  // const nameValue = Form.useWatch("name", formRef);
  const formRef = React.useRef(null);
  const formVehicleRef = React.useRef(null);

  const firstTimeRender = useRef(true);
  const subscriptionStartDtCurrent = useRef(dayjs());
  const subscriptionEndDtCurrent = useRef(dayjs().add(7, "day"));

  useEffect(() => {
    if (!firstTimeRender.current) {
      if (!formRef.current) {
        return false;
      }
      (async () => {
        const { serviceItemsData, discountItemsData, invoice_user_id } = state;
        const foundRecords = (props.referenceData.users || []).filter(
          (rec) => rec.user_id == invoice_user_id
        );
        debugger;

        // const retItmObj = onFinishItem();
        // if (!retItmObj) {
        //   return false;
        // }
        //* (!isNil(rec.tax)  && rec.tax ? rec.tax_rate : 1)
        if (
          isNil(state.subscription_start_dt) ||
          isNil(state.subscription_end_dt)
        ) {
          openNotificationWithIcon(
            "error",
            "Save Invoice",
            "Please select date range"
          );
          return false;
        }

        formRef.current
          .validateFields({ validateOnly: true })
          .then(async (values) => {
            debugger;
            // const values = formRef.current.getFieldsValue(true);
            // if (trim(values.unit) == "1") {
            //   if (
            //     dayjs(values.subscription_end_dt).isBefore(
            //       dayjs(values.subscription_start_dt)
            //     )
            //   ) {
            //     openNotificationWithIcon(
            //       "error",
            //       "Save Invoice",
            //       "Please select end date greater than start date"
            //     );
            //     return false;
            //   }
            // } else {
            //   if (
            //     trim(values.subscription_start_dt) != "" &&
            //     trim(values.subscription_end_dt) != ""
            //   ) {
            //     if (
            //       dayjs(values.subscription_end_dt).isBefore(
            //         dayjs(values.subscription_start_dt)
            //       )
            //     ) {
            //       openNotificationWithIcon(
            //         "error",
            //         "Save Invoice",
            //         "Please select end date greater than start date"
            //       );
            //       return false;
            //     }
            //   }
            // }

            let item = { ...values };
            let key;

            // if (state.itemId == -1) {
            //   key = uuid.v4();
            //   item.mode = "A";
            // } else {
            //   key = state.itemId;
            //   item.mode = "E";
            // }
            key = uuid.v4();
            item.mode = "A";
            item.subscription_start_dt = state.subscription_start_dt;
            item.subscription_end_dt = state.subscription_end_dt;

            item.key = key;
            item.quantity = 1; //Number(unformat(item.quantity));
            item.price = Number(unformat(item.price));
            item.total = Math.round(
              Number(unformat(item.quantity)) * Number(unformat(item.price)),
              2
            );

            item.tax_rate = state.tax_rate
              ? Number(unformat(state.tax_rate))
              : 0;

            if (item.unit == 1) {
              //"Per Space")
              const foundRec = (props.referenceData.siteSpaces || []).filter(
                (itm) =>
                  itm.site_id == state.site_id && itm.space_id == state.space_id
              )[0];

              let truck_num = "N/A";
              if (!isNil(item.vehicle_id) && trim(item.vehicle_id) != "-1") {
                const foundVehicleRec = (
                  props.referenceData.vehicles || []
                ).filter(
                  (rec) =>
                    rec.user_id_fk == state.invoice_user_id &&
                    rec.vehicle_id == state.vehicle_id
                )[0];

                if (!isNil(foundVehicleRec)) {
                  truck_num =
                    !isNil(foundVehicleRec.truck_num) &&
                    foundVehicleRec.truck_num != "-1"
                      ? foundVehicleRec.truck_num
                      : "N/A";
                } else {
                  truck_num = "N/A";
                }
              }

              if (truck_num != "N/A") {
                item.item =
                  "Site(" +
                  foundRec.site_name +
                  ") -- Space(" +
                  foundRec.space_name +
                  ") -- Truck#(" +
                  truck_num +
                  ") -- Period(" +
                  dayjs(state.subscription_start_dt).format("YYYY/MM/DD") +
                  " thru " +
                  dayjs(state.subscription_end_dt).format("YYYY/MM/DD") +
                  ")";
              } else {
                item.item =
                  "Site(" +
                  foundRec.site_name +
                  ") -- Space(" +
                  foundRec.space_name +
                  ") -- Period(" +
                  dayjs(state.subscription_start_dt).format("YYYY/MM/DD") +
                  " thru " +
                  dayjs(state.subscription_end_dt).format("YYYY/MM/DD") +
                  ")";
              }
            }

            // const newData = [...state.invoiceItemsData];
            const newData = cloneDeep([item]); //As we are not having multiple items
            const index = newData.findIndex((item) => key === item.key);
            let updatedRecord = null;
            if (index > -1) {
              const record = newData[index];
              updatedRecord = { ...record, ...item };
              newData.splice(index, 1, updatedRecord);
            } else {
              updatedRecord = item;
              newData.push(updatedRecord);
            }

            const newDataSorted = newData.map((item, index) => {
              return {
                ...item,
                index,
              };
            });

            setState({
              invoiceItemsData: newDataSorted,
            });

            const invoiceItemsData = newDataSorted.map((item) => {
              item.vehicle_id =
                isNil(item.vehicle_id) ||
                trim(item.vehicle_id).toString() == "-1"
                  ? undefined
                  : item.vehicle_id;

              item.note =
                isNil(item.note) || trim(item.note).toString() == "-1"
                  ? undefined
                  : item.note;

              item.space_id =
                isNil(item.space_id) || trim(item.space_id).toString() == "-1"
                  ? undefined
                  : item.space_id;

              item.total = 1 * item.price; //item.quantity
              item.tax =
                !isNil(item.tax_rate) &&
                item.tax_rate &&
                trim(item.tax_rate) != ""
                  ? true
                  : false;

              return item;
            });

            debugger;
            const invoiceItemsDataTotal = invoiceItemsData.reduce(
              (a, rec) => a + Number(rec.price) * 1, //Number(rec.quantity)
              0
            );

            //tax is yes/no
            //tax_rate is the value
            const serviceItemsTaxDataTotal = invoiceItemsData
              .filter((rec) => !isNil(rec.tax) && rec.tax)
              .reduce(
                (a, rec) =>
                  a +
                  Number(rec.price) *
                    1 * // Number(rec.quantity)
                    (rec.tax_rate * 0.01),
                0
              );

            let discountTotal = 0;
            const discountItemsDataEnhanced = discountItemsData.map((rec) => {
              let discountAmountProrated = 0;
              if (rec.discountType == "P") {
                discountAmountProrated =
                  invoiceItemsDataTotal * rec.discountAmount * 0.01;
              } else {
                discountAmountProrated = rec.discountAmount;
              }

              discountTotal += discountAmountProrated;
              return {
                ...rec,
                discountAmountProrated,
              };
            });

            const invoiceSubTotal = invoiceItemsDataTotal - discountTotal;
            let serviceTotal = 0;
            const serviceItemsDataEnhanced = serviceItemsData.map((rec) => {
              let serviceAmountProrated = 0;
              if (rec.serviceType == "P") {
                serviceAmountProrated =
                  invoiceItemsDataTotal * rec.serviceAmount * 0.01;
              } else {
                serviceAmountProrated = rec.serviceAmount;
              }

              serviceTotal += serviceAmountProrated;
              return {
                ...rec,
                serviceAmountProrated,
              };
            });

            const invoiceTotal = round(
              invoiceSubTotal + serviceItemsTaxDataTotal + serviceTotal,
              2
            );

            let itemStr = "",
              itemFinalStr = "";
            // invoiceServiceCharge: serviceTotal,
            // invoiceDiscount: discountTotal,
            // invoiceSalesTax: serviceItemsTaxDataTotal,
            // invoiceTotal,

            // const explanation = computeExplanation({
            //   site_id: state.site_id,
            //   space_id: state.space_id,
            //   subscription_start_dt: state.subscription_start_dt,
            //   subscription_end_dt: state.subscription_end_dt,
            // });

            const explanation = state.explanation;

            const foundRec = (props.referenceData.siteSpaces || []).filter(
              (itm) =>
                itm.site_id == state.site_id && itm.space_id == state.space_id
            )[0];

            let truck_num = "N/A";
            if (!isNil(state.vehicle_id) && trim(state.vehicle_id) != "-1") {
              const foundVehicleRec = (
                props.referenceData.vehicles || []
              ).filter(
                (rec) =>
                  rec.user_id_fk == state.invoice_user_id &&
                  rec.vehicle_id == state.vehicle_id
              )[0];

              if (!isNil(foundVehicleRec)) {
                truck_num =
                  !isNil(foundVehicleRec.truck_num) &&
                  foundVehicleRec.truck_num != "-1"
                    ? foundVehicleRec.truck_num
                    : "N/A";
              } else {
                truck_num = "N/A";
              }
            }

            if (truck_num != "N/A") {
              itemStr =
                "Site(" +
                foundRec.site_name +
                ") -- Space(" +
                foundRec.space_name +
                ") -- Truck#(" +
                truck_num +
                ") -- Period(" +
                dayjs(state.subscription_start_dt).format("YYYY/MM/DD") +
                " thru " +
                dayjs(state.subscription_end_dt).format("YYYY/MM/DD") +
                ")";
            } else {
              itemStr =
                "Site(" +
                foundRec.site_name +
                ") -- Space(" +
                foundRec.space_name +
                ") -- Period(" +
                dayjs(state.subscription_start_dt).format("YYYY/MM/DD") +
                " thru " +
                dayjs(state.subscription_end_dt).format("YYYY/MM/DD") +
                ")";
            }

            itemFinalStr =
              // itemId +
              "-1" +
              "~" +
              state.site_id +
              "~" +
              state.space_id + //1  -- Unit Space
              "~" +
              (itemStr ? itemStr.replace(/'/g, "") : " ") +
              "~" +
              (state.unit ? state.unit : " ") +
              "~" +
              (state.note ? state.note : "-1") +
              "~" +
              Number(unformat(state.price)) +
              "~" +
              1 + //state.quantity
              "~" +
              state.tax_rate +
              "~" +
              (isNil(state.vehicle_id) ? "-1" : state.vehicle_id) +
              "~" +
              (state.unit == 1
                ? dayjs(state.subscription_start_dt).format("MM/DD/YYYY")
                : "01/01/1900") + //1  -- subscription_start_dt
              "~" +
              (state.unit == 1
                ? dayjs(state.subscription_end_dt).format("MM/DD/YYYY")
                : "01/01/1900") + //1  -- subscription_end_dt
              "~" +
              (state.unit == 1
                ? trim(explanation) != ""
                  ? "-1" //explanation
                  : "-1"
                : "-1") + //1  -- subscription_end_dt
              // (itm.unit == 1 ? JSON.stringify(itm.explanation) : " ") + //1  -- subscription_end_dt
              "^";

            let discountStr = "";

            // serviceName: '',
            // serviceDesc: '',
            // serviceType: 'P',
            // serviceAmount: null,
            // serviceAmountProrated: null,
            // serviceId: -1,
            // newServiceModalVisible: true,
            // mode:'A'
            let serviceStr = "";

            debugger;
            setState({ saveModalVisible: true });

            const parms = [
              "-1", //InvoiceID
              // props.fromLoginReserve == "Y"
              //   ? state.invoice_user_id
              //   : state.invoice_user_id,
              state.invoice_user_id,
              state.invoice_user_id,
              -1, //Subscription_id , it will be -1 for all Adhoc Invoices and will be populated only for Monthlies
              " ", //Extra
              "Reservation for " + dayjs().format("YYYY-MM-DD"), //invoiceTitle
              "Reservation", //state.invoiceMessage,
              dayjs().format("MM/DD/YYYY"),
              invoiceTotal, //Total
              serviceItemsTaxDataTotal, //Extra
              discountTotal, //Extra
              serviceTotal, //Extra
              dayjs().format("MM/DD/YYYY"), //invoiceSendDate
              dayjs().format("MM/DD/YYYY"), //invoiceDueDate
              `${dayjs().format("YYYYMMDD")}-${state.invoice_user_id}`, //state.invoiceReferenceId,
              "email", //state.shareInvoiceMode,
              " ", //state.hasReminders,
              JSON.stringify(" "), //state.reminderData
              state.site_id,
              trim(itemFinalStr) == "" ? "-1" : itemFinalStr,
              trim(discountStr) == "" ? "-1" : discountStr,
              trim(serviceStr) == "" ? "-1" : serviceStr,
              1, //isNil(state.invoice_status) ? 0 : 1,
              "01/01/1900",
              !isNil(state.card_id) ? state.card_id : "-1",
              "Y", //Created Using Reservations
            ];

            //!isNil(state.cardOption) ? state.cardOption : "-1",

            const paymentParms = {
              session_user_id: state.invoice_user_id,
              user_id: state.invoice_user_id,
              amount: round(invoiceTotal, 2),
              // amount: round(
              //   state.subscription_deposit_amount +
              //     state.subscription_total,
              //   2
              // ),
              invoice_id: "-1",
              // invoice_ids: [state.invoiceId],
              payment_mode: 1,
              // row.paymentType == "M" || //Cash
              // row.paymentType == "K"
              //   ? row.paymentType == "M"
              //     ? 3
              //     : 4 //3 -- cash, 4 -- check
              //   : 1, // 1 -- card, 2 -- Non-card,
              card_id: !isNil(state.card_id) ? state.card_id : "-1",
              stripe_card_id: "-1",
              stripe_payment_method_id: "-1",
              // send either source_id or (number,exp_month,exp_year,cvc,name, default, save_card, address_zip)
              stripe_user_id: "", // existing stripe card id
              charge_type: "1", //Card
              site_id: state.site_id,
              stripe_invoice_id: "-1",
              quickChargeDesc: `Payment on Account - ${dayjs().format(
                "YYYY/MM/DD"
              )}`,
              invoice_type: "IS",
              source: "Reservation from the Platform",
              defaultPaymentType: "N",
              sourceScreen: "TruckParkNation",
            };

            const resultObj = await saveInvoice(parms, paymentParms);
            // MySwal.close();
            setState({ saveModalVisible: false });
            console.log("resultObj", resultObj);
            // const data = resultObj.data;
            if (!hasErrors(resultObj)) {
              if (resultObj.messageType == "success") {
                //  // debugger;
                if (state.cardOption == 1) {
                  if (resultObj.payment_id && resultObj.payment_id != "") {
                    printPayment(resultObj);
                  }

                  //send the email with instructions
                  //  // debugger;
                  const retObj = await emailPass();
                  //  // debugger;
                  if (!hasErrors(retObj)) {
                    // openNotificationWithIcon(
                    //   "success",
                    //   "Success",
                    //   "Reservation Created, Please check your email for instructions on accessing the lot."
                    // );
                    // return resultObj;
                    //Close and refresh
                    const spaceRecord = (state.vacancyData || []).filter(
                      //props.referenceData.siteSpaces
                      (itm) =>
                        itm.site_id == state.site_id &&
                        itm.space_id == state.space_id
                    )[0];
                    const foundVehicleRec = (
                      props.referenceData.vehicles || []
                    ).find(
                      (rec) =>
                        rec.user_id_fk == state.invoice_user_id &&
                        rec.vehicle_id == state.vehicle_id
                    );

                    if (resultObj.payment_id != "") {
                      //We Have Charged the client

                      const parms = [
                        state.invoice_user_id,
                        resultObj.payment_id,
                      ];
                      const paymentObj = await getTransactionDetails(parms);

                      const data =
                        paymentObj.data.length == 1
                          ? paymentObj.data[0][0]
                          : paymentObj.data;
                      console.log("data", data);

                      const record = data;
                      const foundRecords = (
                        props.referenceData.users || []
                      ).filter((rec) => rec.user_id == record.user_id_fk);

                      setState({
                        // customers: props.referenceData.users,
                        spaceRecord,
                        vehicleRecord: foundVehicleRec
                          ? foundVehicleRec
                          : undefined,

                        payment_id: record.payment_id,
                        site_id: record.site_id_fk,
                        customer:
                          foundRecords.length > 0 ? foundRecords[0] : undefined,
                        name:
                          foundRecords[0]?.last_name +
                          ", " +
                          foundRecords[0]?.first_name,
                        // sites: props.referenceData.sites,
                        // spaceTypes: props.referenceData.spaceTypes,
                        // siteSpaces: props.referenceData.siteSpaces,

                        paymentItemsData: [record],
                        refundPaymentRows: [record],
                        invoice_user_id: record.user_id_fk,
                        refundReason: undefined,
                        refundReasonEntered: undefined,
                        refundCardId: undefined,
                        refundAmountForCard: undefined,
                        active: record.active,
                        note: record.note,
                        stripe_payment_id: record.stripe_payment_id,
                        payment_type: record.payment_type,
                        updated_at: record.updated_at,
                        paid_customer_name: record.paid_customer_name,
                        total_amount: record.total_amount,
                        activeRecord: record,
                        inquire_response: "",
                        showReserve: false,
                        showSummary: true,
                      });
                    } else {
                      //Customer has credit on the account
                      setState({
                        // customers: props.referenceData.users,
                        activeRecord: undefined,
                        spaceRecord,
                        vehicleRecord: foundVehicleRec
                          ? foundVehicleRec
                          : undefined,
                        payment_id: "",
                        site_id: state.site_id, // record.site_id_fk,
                        customer: state.customer, //foundRecords.length > 0 ? foundRecords[0] : undefined,
                        name:
                          state.customer.last_name +
                          ", " +
                          state.customer.first_name,
                        // sites: props.referenceData.sites,
                        // spaceTypes: props.referenceData.spaceTypes,
                        // siteSpaces: props.referenceData.siteSpaces,

                        // paymentItemsData: [record],
                        // refundPaymentRows: [record],
                        invoice_user_id: state.invoice_user_id,
                        refundReason: undefined,
                        refundReasonEntered: undefined,
                        refundCardId: undefined,
                        refundAmountForCard: undefined,
                        active: 1, //record.active,
                        // note: record.note,
                        // stripe_payment_id: record.stripe_payment_id,
                        // payment_type: record.payment_type,
                        // updated_at: record.updated_at,
                        // paid_customer_name:  record.paid_customer_name,
                        total_amount: invoiceTotal,
                        // activeRecord: record,
                        inquire_response: "",
                        showReserve: false,
                        showSummary: true,
                      });
                    }
                    // setState({ showReserve: false, showSummary : true });
                    getVacancies();
                    // scrollToTop();
                  } else {
                    openNotificationWithIcon(
                      "error",
                      "Error",
                      retObj.message ? retObj.message : "An error has occured!"
                    );
                    // return {
                    //   messageType: "error",
                    //   message: retObj.message
                    //     ? retObj.message
                    //     : "An error has occured!",
                    // };
                  }
                }
              } else {
                // MySwal.close();
                //Display Error
                openNotificationWithIcon(
                  "error",
                  "Error",
                  resultObj.message
                    ? resultObj.message
                    : "An error has occured!"
                );
              }
            } else {
              // MySwal.close();
              //Display Error
              openNotificationWithIcon(
                "error",
                "Error",
                resultObj.message ? resultObj.message : "An error has occured!"
              );
            }
          })
          .catch((err) => console.log(err));
      })();
    }
  }, [state.saveReserveVersion]); //[state.subscription_start_dt, state.subscription_end_dt, state.space_id]

  //This gets execcuted when the drawer is shown
  const fetchVacancies = useCallback(async () => {
    if (!firstTimeRender.current && state.showReserve) {
      formUseRef.resetFields();
      formRef.current &&
        formRef.current.setFieldsValue({
          // subscription_start_dt: dayjs(state.subscription_start_dt),
          // subscription_end_dt: dayjs(state.subscription_end_dt),
          space_id: state.space_id,
          name: state.name,
        });

      debugger;
      if (
        isNil(state.subscription_start_dt) ||
        isNil(state.subscription_end_dt)
      ) {
        return null;
      }

      const resultObj = await getVacancyData(
        state.subscription_start_dt,
        state.subscription_end_dt
      );
      if (!hasErrors(resultObj)) {
        console.log("resultObj", resultObj);
        debugger;
        let space_id = state.space_id;
        if (
          resultObj.data[0].filter(
            (rec) =>
              rec.site_id == state.site_id &&
              rec.space_id == state.space_id &&
              (state.space_type_id == -1 ||
                state.space_type_id == rec.space_type_id)
          ).length == 0
        ) {
          space_id = undefined;
        }
        const distinctSpaceTypeIds = [
          ...new Set(resultObj.data[0].map((item) => item.space_type_id)),
        ];
        let availableSpaces = [];
        distinctSpaceTypeIds.map((distinctSpaceTypeId) => {
          const spaceTypeRows = resultObj.data[0].filter(
            (rec) => rec.space_type_id == distinctSpaceTypeId
          );
          availableSpaces.push({
            space_type_id: distinctSpaceTypeId,
            space_type_name: spaceTypeRows[0].space_type_name,
            count: spaceTypeRows.length,
          });
        });

        setState({
          availableSpaces,
          vacancyData: resultObj.data[0],
          vacancyFilteredData: resultObj.data[0],
          filteredSpaceTypeName: "ALL",
          space_id,
        });

        formRef.current &&
          formRef.current.setFieldsValue({
            // subscription_start_dt: dayjs(state.subscription_start_dt),
            // subscription_end_dt: dayjs(state.subscription_end_dt),
            space_id: space_id,
            name: state.name,
          });
        const record = calcPriceForItem(resultObj.data[0]);
        if (!record) {
          formRef &&
            formRef.current &&
            formRef.current.setFieldsValue({
              ...record,
            });
        }
      }
    }
  }, [state.renderVersion]);

  useEffect(() => {
    debugger;
    fetchVacancies();
  }, [fetchVacancies]); //[state.subscription_start_dt, state.subscription_end_dt, state.space_id]

  const renderSiteData = useCallback(async () => {
    if (!firstTimeRender.current) {
      const sitePath = props.siteData;
      console.log("siteData", props.siteData);
      if (sitePath.loading) {
        // setLoading(true);
      } else if (!sitePath.hasError) {
        console.log("sss", sitePath);
        // console.log("sites", [...sitePath[0]]);

        if (sitePath && sitePath.length > 0) {
          setState({
            sites: sitePath[0].map((itm) => {
              // console.log("imagesURL", itm.imagesURL);
              // console.log("imagesURL", JSON.parse(JSON.stringify(itm.imagesURL)));
              return {
                ...itm,
                imagesURL: JSON.parse(JSON.stringify(itm.imagesURL))
                  ? JSON.parse(JSON.stringify(itm.imagesURL)).split("@@@@@@")
                  : [],
              };
            }),
          });
        }
      } else if (sitePath.hasError) {
      }
    }
  }, [props.siteData]);

  useEffect(() => {
    renderSiteData();
  }, [renderSiteData]);

  const renderMainVacancies = useCallback(async () => {
    if (!firstTimeRender.current && !state.showReserve) {
      debugger;
      fetchVacancies();
    }
  }, [state.site_id, dateRangeValue.startDate, dateRangeValue.endDate]);
  //state.subscription_start_dt,state.subscription_end_dt,
  useEffect(() => {
    renderMainVacancies();
  }, [renderMainVacancies]);

  useEffect(() => {
    firstTimeRender.current = false;
    (async () => {
      setState({});
      debugger;
      props.getSitesData({
        type: actionTypes.FETCH_SITEDATA_REQUEST,
        payload: {
          parms: [
            // `${dayjs(startDate).format("YYYY/MM/DD")}`,
            // `${dayjs(endDate).format("YYYY/MM/DD")}`,
          ],
        },
      });
    })();
  }, []);

  const getTransactionDetails = (parms) => {
    return new Promise((resolve, reject) => {
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_TransactionData",
          input: parms,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });
  };
  const filterSpaces = (rec) => {
    debugger;
    const vacancyFilteredData = state.vacancyData.filter(
      (record) => record.space_type_id == rec.space_type_id
    );
    setState({
      vacancyFilteredData,
      filteredSpaceTypeName: rec.space_type_name,
    });
  };

  const getSpacesForSite = (site_id, space_type_id) => {
    // debugger;
    const spaces = (state.vacancyData || []).filter(
      // props.referenceData.siteSpaces
      (rec) =>
        rec.site_id == site_id &&
        (rec.space_type_id == space_type_id || space_type_id == -1)
    );

    return sortBy(spaces, ["space_name,space_type_name"]);
  };

  const getUsageCount = (item) => {
    return props.referenceData.spaceUsuageDetails.filter(
      (itm) =>
        itm.site_id == state.site_id &&
        itm.space_id == item.space_id &&
        ((dayjs().isBetween(
          dayjs(itm.start_dt || undefined, "MM/DD/YYYY"),
          dayjs(itm.end_dt || undefined, "MM/DD/YYYY"),
          "days",
          "[]"
        ) &&
          !isNil(itm.start_dt) &&
          !isNil(itm.end_dt)) || //In between
          (dayjs(dayjs().format("MM/DD/YYYY")) >=
            dayjs(itm.start_dt || undefined, "MM/DD/YYYY") &&
            isNil(itm.end_dt)))
      //    || //Greater than Start Dt
      // (dayjs(dayjs().format("MM/DD/YYYY")) <
      //   dayjs(itm.start_dt || undefined, "MM/DD/YYYY") &&
      //   isNil(itm.end_dt))) //less than Start Dt
    ).length;
  };
  const printSpaceName = (item) => {
    if (isNil(item)) {
      return "";
    }

    const custStr =
      (item.space_name ? item.space_name : " ") +
      " | " +
      // (usageCount > 0 ? "Occupied" : "Vacant") +
      // " | " +
      (item.space_type_name ? item.space_type_name : " ") +
      " | " +
      (item.monthly_rate ? item.monthly_rate : " ") +
      " (Monthly)| ";
    // (item.daily_rate ? item.daily_rate : " ") +
    // " (Daily)| " +
    // (item.weekly_rate ? item.weekly_rate : " ") +
    // " (Weekly)| " +
    // (item.weekend_rate ? item.weekend_rate : " ") +
    // " (Weekend) ";
    return custStr;
  };

  const getTaxContent = () => {
    // debugger;
    console.log("getTaxContent");
    let record = null;
    if (formRef && formRef) {
      record = formRef.current.getFieldsValue(true);
    }

    if (formRef && record && record.price && record.quantity) {
      if (isNil(record.price) || isNil(record.quantity)) {
        return null;
      }

      const site_id = record.site_id;
      const foundRec = state.sites.filter((itm) => itm.site_id == site_id)[0];

      if (isNil(foundRec)) {
        return null;
      }
      //  // debugger;
      const tax_rate = foundRec.tax_rate;
      const newPriceWOTax = Number(
        (record.price * record.quantity) / (1 + foundRec.tax_rate / 100.0)
      ).toFixed(2);

      return (
        <div>
          {/* <p>{`Amount Without Including Taxes:
            ${record.price}
           `}</p> */}
          <p>{`Amount Without Taxes: ${newPriceWOTax}`}</p>
          <p>{` Taxes: ${Number(
            Number(record.price * record.quantity) - Number(newPriceWOTax)
          ).toFixed(2)}`}</p>
          <p>{` Amount: ${Number(record.price * record.quantity).toFixed(
            2
          )}`}</p>
        </div>
      );
    } else {
      const price = state.price;
      const quantity = state.quantity;
      const tax = state.tax;
      const tax_rate = state.tax_rate;

      const newPriceWOTax = Number(
        (price * quantity) / (1 + tax_rate / 100.0)
      ).toFixed(2);

      return (
        <div>
          {/* <p>{`Amount Without Including Taxes:
            ${record.price}
           `}</p> */}
          <p>{`Amount Without Taxes: ${newPriceWOTax}`}</p>
          <p>{` Taxes: ${Number(
            Number(price * quantity) - Number(newPriceWOTax)
          ).toFixed(2)}`}</p>
          <p>{` Amount: ${Number(price * quantity).toFixed(2)}`}</p>
        </div>
      );
    }
  };

  const onTaxChange = (e) => {
    if (e.target.checked) {
      const record = formRef.current.getFieldsValue(true);
      const site_id = record.site_id;

      const foundRec = state.sites.filter((itm) => itm.site_id == site_id)[0];
      setState({
        tax_rate: foundRec.tax_rate,
        tax: true,
      });
    } else {
      setState({
        tax_rate: 0,
        tax: false,
      });
    }
  };

  const getPriceContent = () => {
    return (
      <div>
        <div>No Of Days: {state.dateDiff}</div>
        <div>
          <div className="price-explanation-title">Explanation : </div>
          {state.explanation?.split("~").map((info, index) => {
            return (
              <div
                className={
                  index == 0
                    ? "price-explanation-detail first-detail"
                    : "price-explanation-detail"
                }
                key={uuid.v4()}
              >
                {info}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  // printInvoice = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const invoicePrintData = generateInvoiceData();
  //   setState({
  //     printModalVisible: true,
  //     invoicePrintData,
  //   });
  // };
  const saveSignature = () => {
    // debugger;
    setState({
      signatureData: sigPad.toDataURL("image/png"), //sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
  };

  const clearSignature = () => {
    sigPad.clear();
  };

  const setSignatureVisible = (visible) => {
    setState({ signatureModalVisible: visible });
  };
  const sigPad = null;

  const printVehicleName = (item) => {
    if (isNil(item)) {
      return "";
    }
    //
    const custStr =
      (item.license_num ? item.license_num : "No Lic#") +
      "| " +
      (item.truck_num ? item.truck_num : "No Truck#") +
      " | " +
      (item.trailer_num ? item.trailer_num : "No Trailer#") +
      " | " +
      (item.model ? item.model : "No Model") +
      " | " +
      (item.make ? item.make : "No Make") +
      " | " +
      (item.color ? item.color : "No  Color") +
      " | " +
      (item.vin ? item.vin : "No Vin#");
    return custStr;
  };

  const disabledSubscriptionStartDate = (current) => {
    const today = dayjs(); //.subtract(3, "year");
    const endDate = dayjs().add(1, "year");
    // const endDate = dayjs(new Date(),'MM/DD/YYYY').add(10,'days');

    let isWeekend = false;
    if (new Date(current).getDay() == 6 || new Date(current).getDay() == 0) {
      isWeekend = true;
    }

    isWeekend = false;
    return !dayjs(current).isBetween(today, endDate, "day", "[]") || isWeekend;
  };

  const disabledSubscriptionEndDate = (current) => {
    let startDt;
    const today = dayjs().subtract(3, "year");
    if (isNil(state.subscription_start_dt)) {
      startDt = today;
    } else {
      startDt = dayjs(
        dayjs(state.subscription_start_dt).add(2, "M"),
        "MM/DD/YYYY"
      );
    }
    const endDate = dayjs().add(2, "year");
    // const endDate = dayjs(new Date(),'MM/DD/YYYY').add(10,'days');

    let isWeekend = false;
    if (new Date(current).getDay() == 6 || new Date(current).getDay() == 0) {
      isWeekend = true;
    }
    isWeekend = false;
    return (
      !dayjs(current).isBetween(startDt, endDate, "day", "[]") || isWeekend
    );
  };

  const onFinishItem = (values) => {
    debugger;
    console.log(values);
  };

  const showSpaceUsuage = (rec) => {
    if (!formRef) return false;

    const record = formRef.current.getFieldsValue(true);
    const site_id = record.site_id;
    const space_id = record.space_id;

    const spaceDataUsage = (
      props.referenceData.spaceUsuageDetails || []
    ).filter((itm) => itm.site_id == site_id && itm.space_id == space_id);

    return (
      <Table
        // className="divContainer"
        // showHeader={false}
        // key={state.renderVersion}
        pagination={false}
        columns={spaceUsageColumns}
        dataSource={spaceDataUsage || []}
        rowKey="key"
        size="small"
        scroll={{ y: "220px", x: 800 }}
        // scroll={scroll}

        // rowClassName="editable-row"
        sortDirections={["descend", "ascend"]}
        // onChange={handleChange}
      />
    );
  };

  const spaceUsageColumns = [
    {
      title: "Usage Type",
      dataIndex: "usages_type",
      filterable: true,
      editable: true,
      inputType: "text",
      fixed: !props.isMobile ?? "left",
      width: "150px",
      render: (text, row) => {
        return <span>{row.usages_type}</span>;
      },
      sorter: (a, b) =>
        (a.usages_type || "").localeCompare(b.usages_type || ""),
    },
    {
      title: "Title",
      dataIndex: "title",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      width: "150px",
      render: (text, row) => {
        return <span>{row.title}</span>;
      },
      sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
    },
    {
      title: "Start Date",
      dataIndex: "start_dt",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      width: 110,
      render: (text, row) => {
        return <span> {row.start_dt ? row.start_dt : ""} </span>;
      },
      sorter: (a, b) =>
        Number(new Date(a.start_dt)) - Number(new Date(b.start_dt)),
    },
    {
      title: "End Date",
      dataIndex: "end_dt",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      width: 110,
      render: (text, row) => {
        return <span> {row.end_dt ? row.end_dt : ""} </span>;
      },
      sorter: (a, b) => Number(new Date(a.end_dt)) - Number(new Date(b.end_dt)),
    },
    {
      title: "User Name",
      dataIndex: "name",
      filterable: true,
      editable: true,
      inputType: "text",
      fixed: "right",
      width: "150px",
      render: (text, row) => {
        return <span>{row.name}</span>;
      },
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
    },
  ];

  const handleVehicle = (val) => {
    setState({
      vehicle_id: val,
    });
  };

  const handleVehicleType = (val) => {
    debugger;
    setState({
      vehicle_type_id: val,
    });
  };

  const getVehiclesForUser = (userId) => {
    return (props.referenceData.vehicles || []).filter(
      (rec) => rec.user_id_fk == userId
    );
  };

  const handleEndDtPeriod = (val) => {
    debugger;
    let subscription_end_dt = state.subscription_end_dt;
    if (val != "Never") {
      subscription_end_dt = dayjs(state.subscription_start_dt).add(2, "M");
    }

    formRef &&
      formRef.current &&
      formRef.current.setFieldsValue({
        subscription_end_dt: dayjs(subscription_end_dt),
      });

    setState({ invoiceRecurEndDtPeriod: val, subscription_end_dt });
  };

  const setSubscriptionStartDt = (val, dateStr) => {
    debugger;
    let subscription_end_dt = state.subscription_end_dt;
    if (dayjs(subscription_end_dt) < dayjs(dateStr).add(2, "M")) {
      subscription_end_dt = dayjs(dateStr).add(2, "M");
    }

    subscriptionStartDtCurrent.current = dateStr == "" ? undefined : dateStr;
    subscriptionEndDtCurrent.current = subscription_end_dt;

    formRef &&
      formRef.current &&
      formRef.current.setFieldsValue({
        subscription_start_dt: dateStr == "" ? undefined : dayjs(dateStr),
        subscription_end_dt: dayjs(subscription_end_dt),
      });

    // setDateRange([dateStr == "" ? undefined : dateStr, subscription_end_dt]);
    setState({
      subscription_start_dt: dateStr == "" ? undefined : dateStr,
      subscription_end_dt,
      renderVersion: state.renderVersion + 1,
    });
    handleProrateOption(state.prorateOption);
  };

  const setSubscriptionEndDt = (val, dateStr) => {
    //  subscriptionStartDtCurrent.current = subscription_start_dt;
    let subscription_start_dt = state.subscription_end_dt;
    subscriptionEndDtCurrent.current = dateStr == "" ? undefined : dateStr;
    formRef &&
      formRef.current &&
      formRef.current.setFieldsValue({
        subscription_end_dt: dateStr == "" ? undefined : dateStr,
      });

    // setDateRange([subscription_start_dt, dateStr == "" ? undefined : dateStr]);
    setState({
      subscription_end_dt: dateStr == "" ? undefined : dateStr,
      renderVersion: state.renderVersion + 1,
    });
  };

  const hasErrors = (retObj) => {
    //response.status >= 200 && response.status < 300
    if (isNil(retObj)) {
      return true;
    } else if (
      (!isNil(retObj.message) &&
        isNil(retObj.data) &&
        retObj.messageType == "error") ||
      (!isNil(retObj.message) &&
        // isNil(retObj.data) &&
        retObj.messageType == "error")
    ) {
      // console.log('retObj', retObj);
      return true;
    } else {
      return false;
    }
  };

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(dayjs().format("MM/DD/YYYY"))
  );
  const [endDate, setEndDate] = useState(
    new Date(dayjs().add(7, "days").format("MM/DD/YYYY"))
  );

  const checkTokenAPI = async (parms) => {
    //  debugger;

    let URL = process.env.REACT_APP_API_URL + `/checkTokenAPI`;
    // let URL = `http://localhost:8080/reactlogin`;
    // if (process.env.NODE_ENV == "production") {
    //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
    // }

    return await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...parms,
      }),
    })
      .then((response) => response.json())
      .catch((error) => error);
  };

  const checkToken = async (space) => {
    const accessToken = localStorage.getItem("accessToken");
    //Validate the Token on the backend
    if (!isNil(accessToken) && trim(accessToken) != "") {
      const parms = {
        accessToken: accessToken,
      };
      const resultObj = JSON.parse(await checkTokenAPI(parms));
      console.log("Sign In resultObj", resultObj);
      debugger;
      if (resultObj.messageType == "success") {
        //Load the reference Data if its not loaded
        if ((props.referenceData.users || []).length == 0) {
          props.refreshReferenceData({
            type: actionTypes.REFRESH_REFDATA_REQUEST,
            payload: {
              parms: [resultObj.userId], //Y will get balances, N all the users, as default
            },
            user_id_fk: resultObj.userId,
          });
        }

        setState({
          showLogin: false,
          showSummary: false,
          showReserve: true,
          invoice_user_id: resultObj.userId,
          space_id: space.space_id,
          createSubscription: "Y",
          customer: resultObj.user,
          name: resultObj.user.last_name + ", " + resultObj.user.first_name,
          renderVersion: state.renderVersion + 1,
        });

        setTimeout(() => {
          handleProrateOption(4);
        }, 300);
      } else {
        // openNotificationWithIcon(
        //   "error",
        //   "Error",
        //   "Token Expired, Please Re-Login."
        // );
        setState({ showLogin: true, space_id: space.space_id });
      }
    } else {
      setState({ showLogin: true, space_id: space.space_id });
    }
  };

  // const actionArray = [
  //   <Tooltip title="Reserve">
  //     <Button key="1" type="primary" danger onClick={(e) => checkToken()}>
  //       Reserve
  //     </Button>
  //   </Tooltip>,
  // ];

  const actionArray = (space) => {
    return (
      // <Tooltip title="Reserve">
      <Button
        key={uuid.v4()}
        type="primary"
        danger
        onClick={(e) => checkToken(space)}
      >
        Reserve
      </Button>
      // </Tooltip>
    );
  };

  const handleCard = (val) => {
    setState({
      card_id: val,
    });

    const record = formRef.current.getFieldsValue(true);
    const newRecord = {
      ...record,
      card_id: val,
    };

    formRef.current.setFieldsValue({
      ...newRecord,
    });
  };

  const onCardOptionChange = (e) => {
    debugger;
    let name = "";
    // if (
    //   trim(state.invoice_user_id) != "-1" &&
    //   !isNil(state.invoice_user_id)
    // ) {
    //   name =
    //     state.customer.last_name + ", " + state.customer.first_name;
    // }

    const option = e.target.value;
    let newPaymentModalVisible = false;
    if (option == "7") {
      newPaymentModalVisible = true;
    }
    let cloverPaymentModalVisible = false;
    if (option == "8") {
      cloverPaymentModalVisible = true;
    }
    setState({
      cardOption: e.target.value,
      newPaymentModalVisible,
      cloverPaymentModalVisible,
      name,
    });
    console.log("radio checked", e.target.value);
  };

  const setCloverPaymentModalVisible = (visible) => {
    setState({ cloverPaymentModalVisible: visible });
  };

  const closeCloverPaymentModalVisible = (saved, setupObject) => {
    // alert("in closeNewPaymentModalVisible");
    console.log(
      "closeCloverPaymentModalVisible setupObject ***********",
      setupObject
    );
    // debugger;
    if (!saved) {
      setState({ cloverPaymentModalVisible: false, cardOption: undefined }); //6
      const record = formRef.current.getFieldsValue(true);
      const newRecord = {
        ...record,
        cardOption: undefined,
      };

      formRef.current.setFieldsValue({
        ...newRecord,
      });
    } else {
      setState({
        cloverPaymentModalVisible: false,
        cardOption: 1,
        card_id: setupObject.card_id,
      });

      const record = formRef.current.getFieldsValue(true);
      const newRecord = {
        ...record,
        cardOption: 1,
        card_id: setupObject.card_id,
      };

      formRef.current.setFieldsValue({
        ...newRecord,
      });
    }
  };

  const onCameraDrawerClose = () => {
    setState({ cameraVisible: false });
  };

  const onReserveDrawerClose = () => {
    setState({ showReserve: false, showSummary: false });
    scrollToTop();
  };

  const onReserveSummaryDrawerClose = () => {
    setState({ showReserve: false, showSummary: false });
    scrollToTop();
  };

  const handleUnit = (e) => {
    const val = e.target.value;
    const record = formRef.current.getFieldsValue(true);

    setState({ unit: val }, () => {
      debugger;
      if (val != 12) {
        //Non Deposits
        const site_id = record.site_id;

        const foundRec = state.sites.filter((itm) => itm.site_id == site_id)[0];

        //Per Space
        const newRecord = {
          ...record,
          subscription_start_dt: undefined,
          subscription_end_dt: undefined,
          tax: true,
          tax_rate: foundRec.tax_rate,
          // renderVersion: state.renderVersion + 1,
        };

        formRef.current.setFieldsValue({
          ...newRecord,
        });
        setState({
          tax_rate: foundRec.tax_rate,
          tax: true,
          // renderVersion: state.renderVersion + 1,
        });
      } else if (val == 12) {
        // Deposit
        const newRecord = {
          ...record,
          tax: false,
          tax_rate: 0,
        };

        formRef.current.setFieldsValue({
          ...newRecord,
        });

        setState({
          tax_rate: 0,
          tax: false,
          // renderVersion: state.renderVersion + 1,
        });
      }
    });
  };

  const handleProrateOption = (val) => {
    debugger;
    const record = formRef.current.getFieldsValue(true);

    let prorateAmount = 0;
    let prorateSubStartDt;
    let total_due = 0;

    const startDt = dayjs(record.subscription_start_dt);
    prorateSubStartDt = startDt.format("MM/DD/YYYY");
    const lastDayOfTheMonth = dayjs(moment(startDt).endOf("month")); //lastday(startDt.year(), startDt.month());
    const dateDiff = lastDayOfTheMonth.diff(startDt, "day");
    const perDay = round(Number(unformat(record.subscription_total)) / 30.0, 2);
    prorateAmount = round((dateDiff ? dateDiff + 1 : 1) * perDay, 2);
    console.log("perDay", perDay);
    //Prorate Until End of the month from the satrt dt

    if (val == 2) {
      total_due = round(
        Number(
          unformat(record.subscription_total) + Number(record.setupFee) || 0
        ),
        // state.subscription_total,
        2
      );
    }
    if (val == 3) {
      prorateSubStartDt = startDt
        .add(2, "month")
        .startOf("month")
        .format("MM/DD/YYYY");

      total_due = round(
        Number(prorateAmount) +
          Number(
            unformat(record.subscription_total) + Number(record.setupFee) || 0
          ),
        // state.subscription_total,
        2
      );
    }
    if (val == 4) {
      prorateSubStartDt = startDt
        .add(1, "month")
        .startOf("month")
        .format("MM/DD/YYYY");

      total_due = round(
        Number(prorateAmount) + Number(record.setupFee),
        // state.subscription_total,
        2
      );
    }

    const newRecord = {
      ...record,
      prorateOption: val,
      prorateAmount,
      prorateSubStartDt,
      total_reserve: formatNumber(total_due),
      creditOnAccount: total_due,
      total_due,
      subscription_total: unformat(record.subscription_total),
      subscription_movein_date: startDt, //startDt.format("MM/DD/YYYY"),
      proratedMonthEndDt: lastDayOfTheMonth.format("MM/DD/YYYY"),
    };

    formRef.current.setFieldsValue({
      ...newRecord,
    });

    setState({
      prorateAmount: prorateAmount,
      prorateSubStartDt,
      prorateOption: val,
      creditOnAccount: total_due,
      subscription_total: unformat(record.subscription_total),
      total_reserve: formatNumber(total_due),
      total_due,
      subscription_movein_date: startDt.format("MM/DD/YYYY"),
      proratedMonthEndDt: lastDayOfTheMonth.format("MM/DD/YYYY"),
      // renderVersion: state.renderVersion + 1,
    });
  };

  const handleSpaceType = (val) => {
    const record = formRef.current.getFieldsValue(true);
    const newRecord = {
      ...record,
    };
    formRef.current.setFieldsValue({
      ...newRecord,
    });
    setState({ space_type_id: val, renderVersion: state.renderVersion + 1 });
  };

  const printCard = (item) => {
    //
    let cardStr;

    if (item.stripe_payment_type && trim(item.stripe_payment_type) != "") {
      if (trim(item.stripe_payment_type).toLowerCase() == "card") {
        cardStr =
          (item.cardholder_name ? item.cardholder_name : " ") +
          "| " +
          // (item.card_brand ? displayCardbrand(item.card_brand) : " ") +
          (item.card_brand ? item.card_brand : " ") +
          " | " +
          (item.last_4 ? item.last_4 : " ") +
          " | " +
          (item.exp_month ? item.exp_month : " ") +
          " | " +
          (item.exp_year ? item.exp_year : " ") +
          " | " +
          (item.defaultCard == "Y" ? "Default" : "Not Default") +
          " | " +
          (item?.payment_processor == "C" ? "Clover" : "Stripe") +
          " | " +
          (item?.payment_processor == "C"
            ? item?.clover_payment_type == "C"
              ? "Card"
              : "ACH"
            : item.stripe_payment_type
            ? item.stripe_payment_type
            : " ") +
          " | ";
      } else {
        cardStr =
          (item.cardholder_name ? item.cardholder_name : " ") +
          "| " +
          // (item.card_brand ? displayCardbrand(item.card_brand) : " ") +
          (item.bank_name ? item.bank_name : " ") +
          " | " +
          (item.last_4 ? item.last_4 : " ") +
          " | " +
          (item.routing_number ? item.routing_number : " ") +
          " | " +
          (item.defaultCard == "Y" ? "Default" : "Not Default") +
          " | " +
          (item?.payment_processor == "C" ? "Clover" : "Stripe") +
          " | " +
          (item?.payment_processor == "C"
            ? item?.clover_payment_type == "C"
              ? "Card"
              : "ACH"
            : item.stripe_payment_type
            ? item.stripe_payment_type
            : " ") +
          " | ";
      }
    } else {
      cardStr =
        (item.cardholder_name ? item.cardholder_name : " ") +
        "| " +
        // (item.card_brand ? displayCardbrand(item.card_brand) : " ") +
        (item.card_brand ? item.card_brand : " ") +
        " | " +
        (item.last_4 ? item.last_4 : " ") +
        " | " +
        (item.exp_month ? item.exp_month : " ") +
        " | " +
        (item.exp_year ? item.exp_year : " ") +
        " | " +
        (item.defaultCard == "Y" ? "Default" : "Not Default") +
        " | " +
        "card";
    }

    return cardStr;
  };

  const getDistinctSpaceTypes = () => {
    const uniqSpaceTypes = [
      ...new Set(
        (state.vacancyData || [])
          .filter((rec) => rec.site_id == state.site_id)
          .map((itm) => itm.space_type_id)
      ),
    ];

    console.log("state.vacancyData", state.vacancyData);
    console.log("uniqSpaceTypes", uniqSpaceTypes);
    return uniqSpaceTypes.map((spaceType) =>
      state.vacancyData.find(
        (rec) => rec.site_id == state.site_id && rec.space_type_id == spaceType
      )
    );
  };

  const calcPriceForItem = (vacancyData) => {
    debugger;
    if (!formRef || !formRef.current) return false;

    const record = formRef.current.getFieldsValue(true);
    let price = undefined,
      tax_amount = undefined,
      subscription_total = undefined,
      total_reserve = undefined;
    let quantity = 1;
    let explanation = " ";
    let dateDiff = undefined;

    if (
      record.subscription_start_dt != "" &&
      !isNil(record.subscription_start_dt) &&
      record.subscription_end_dt != "" &&
      !isNil(record.subscription_end_dt) &&
      !isNil(record.space_id) &&
      record.space_id != "" &&
      record.createSubscription != "Y"
    ) {
      const site_id = record.site_id;
      const space_id = record.space_id;

      const foundRec = (vacancyData || []).filter(
        //props.referenceData.siteSpaces
        (itm) => itm.site_id == site_id && itm.space_id == space_id
      )[0];

      if (isNil(foundRec)) {
        return false;
      }
      let dayDiff = Math.ceil(
        record.subscription_end_dt.diff(record.subscription_start_dt, "days")
      );

      // if (dayDiff > 1) {
      //   dayDiff = dayDiff - 1;
      // }

      if (dayDiff == 0) {
        dayDiff = 1;
      }

      dateDiff = dayDiff;

      //0 sunday
      //1 monday
      //2 Tuesday
      //3 wed
      //4 Thu
      //5 // Fri
      //6 Sat

      //To check for the weekend Special
      if (
        (dayjs(record.subscription_start_dt).day() == 5 &&
          (dayDiff == 2 || dayDiff == 3)) ||
        (dayjs(record.subscription_start_dt).day() == 6 && dayDiff == 2)
      ) {
        //Friday/Syaturdat
        record.price = foundRec.weekend_rate;
        explanation = `${foundRec.weekend_rate} Weekend rate`;
        record.explanation = explanation;
        setState({ explanation });
      } else {
        let months = 0,
          weeks = 0,
          days = 0;
        months = Math.floor(dayDiff / 30.0);
        let monthlyReminder = dayDiff % 30;

        if (monthlyReminder > 18) {
          // anything greater than 18 is a month
          months += 1;
        } else {
          weeks = Math.floor(monthlyReminder / 7.0);
          let weeklyReminder = monthlyReminder % 7;
          if (weeklyReminder > 4) {
            weeks += 1;
          } else {
            days = weeklyReminder;
          }
        }

        console.log("months:", months);
        console.log("weeks:", weeks);
        console.log("days:", days);

        price =
          foundRec.monthly_rate * months +
          foundRec.weekly_rate * weeks +
          foundRec.daily_rate * days;

        explanation = `(${formatNumber(
          foundRec.monthly_rate
        )}) Monthly Rate * ${months} months  = ${formatNumber(
          foundRec.monthly_rate * months
        )} ~
         (${formatNumber(
           foundRec.weekly_rate
         )}) Weekly Rate * ${weeks} weeks =  ${formatNumber(
          foundRec.weekly_rate * weeks
        )}  ~
          (${formatNumber(
            foundRec.daily_rate
          )}) Daily Rate * ${days} days = ${formatNumber(
          foundRec.daily_rate * days
        )} `;

        record.explanation = explanation;
        record.price = formatNumber(price);
        tax_amount = formatNumber(
          price * (record.tax_rate ? Number(record.tax_rate) : 0) * 0.01
        );
        total_reserve = formatNumber(
          price + price * (record.tax_rate ? Number(record.tax_rate) : 0) * 0.01
        );
        record.tax_amount = tax_amount;
        record.total_reserve = total_reserve;
      }
    } else {
      if (record.createSubscription == "Y") {
        debugger;
        const site_id = record.site_id;
        const space_id = record.space_id;
        quantity = record.quantity;

        const foundRec = (vacancyData || []).filter(
          //props.referenceData.siteSpaces
          (itm) => itm.site_id == site_id && itm.space_id == space_id
        )[0];

        //Reset Price data
        price = formatNumber(foundRec.monthly_rate);
        tax_amount = formatNumber(
          foundRec.monthly_rate *
            quantity *
            (record.tax_rate ? Number(record.tax_rate) : 0) *
            0.01
        );

        subscription_total = formatNumber(
          foundRec.monthly_rate * quantity +
            foundRec.monthly_rate *
              quantity *
              (record.tax_rate ? Number(record.tax_rate) : 0) *
              0.01
        );

        total_reserve = formatNumber(subscription_total + state.setupFee);

        record.price = price;
        record.tax_amount = tax_amount;
        record.total_reserve = total_reserve;
        record.subscription_total = subscription_total;
      } else {
        //Reset Price data
        price = formatNumber(0);
        tax_amount = formatNumber(0);
        total_reserve = formatNumber(0);
        subscription_total = 0;

        record.price = price;
        record.tax_amount = tax_amount;
        record.total_reserve = total_reserve;
        record.subscription_total = subscription_total;
      }
    }
    setState({
      dateDiff,
      explanation,
      price,
      quantity,
      tax_amount,
      total_reserve,
      subscription_total: unformat(record.subscription_total),
    });

    formRef &&
      formRef.current.setFieldsValue({
        ...record,
      });

    setTimeout(() => {
      handleProrateOption(state.prorateOption); //ProrateOption
    }, 300);

    return record;
  };

  const handleSpace = (val) => {
    const record = formRef && formRef.current.getFieldsValue(true);
    const site_id = record.site_id;
    const space_id = record.space_id;

    // if (val != "-1") {
    //   setState({ newSpaceOption: false, space_id: val }, () => {
    //     // formRef.current.setFieldsValue({
    //     //   ...newRecord,
    //     // });
    //   });
    // } else {
    //   setState({ space_id: val });
    // }

    const newRecord = {
      ...record,
      space_id: val,
    };

    formRef.current.setFieldsValue({
      ...newRecord,
    });

    setState({ space_id: val });
    // debugger;
    const enhancedRecord = calcPriceForItem(state.vacancyData);
    debugger;
    if (!enhancedRecord) {
      formRef &&
        formRef.current.setFieldsValue({
          ...enhancedRecord,
        });
    }
  };

  const saveInvoice = (parms, paymentParms) => {
    // debugger;

    return new Promise((resolve, reject) => {
      let URL =
        process.env.REACT_APP_API_URL + `/stripeApi/createUpdateInvoice`;

      // let URL = `http://localhost:8080/stripeApi/createUpdateInvoice`;
      // if (process.env.NODE_ENV == "production") {
      //   URL = process.env.REACT_APP_API_URL + `/stripeApi/createUpdateInvoice`;
      // }
      PNfetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          session_user_id: parms[1],
          createInvoice: "Y",
          payByCashOrCheck:
            state.cardOption == 3 ||
            state.cardOption == 4 ||
            state.cardOption == 5
              ? "Y"
              : "N",
          parms,
          paymentParms,
        }),
      })
        //.then(statusHelper)
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });
  };

  const emailPass = async () => {
    //There is a credit on the account Send Instructions to Open up the Gate
    //items
    debugger;
    const minDt = Math.min(
      ...state.invoiceItemsData.map((itm) =>
        dayjs(itm.subscription_start_dt).toDate()
      )
    );
    const maxDt = Math.max(
      ...state.invoiceItemsData.map((itm) =>
        dayjs(itm.subscription_end_dt).toDate()
      )
    );
    console.log("minDt", new Date(minDt));
    console.log("maxDt", new Date(maxDt));
    // setLoading(true);
    let URL = process.env.REACT_APP_API_URL + `/kisi/createEmailPass`;
    // const optionToken = await AsyncStorage.getItem('accessToken');
    console.log("URL", URL);
    const response = await PNfetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: 'Bearer ' + optionToken,
      },
      body: JSON.stringify({
        name: state.customer.customer_name,
        email: state.customer.email,
        group_id: 49240, //req.body.user_id,
        valid_from: new Date(minDt), //moment.utc(),
        valid_until: new Date(maxDt),
      }),
    });

    const retObj = await response.json();
    return retObj;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const emailReceipt = (record) => {
    return new Promise((resolve, reject) => {
      let URL = process.env.REACT_APP_API_URL + `/email/sendTransactionEMail`;
      const stripeParms = {
        payment_id: record.payment_id, //state.invoiceId,
        session_user_id: state.invoice_user_id, // props.userId,
      };

      PNfetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          ...stripeParms,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });
  };

  const emailCharge = () => {
    const record = state.activeRecord;
    // MySwal.fire({
    //   title: "Invoice",
    //   html: "E-mailing the Charge, Please be patient",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   onOpen: () => {
    //     MySwal.showLoading();
    //   },
    //   onClose: () => {},
    // }).then((result) => {
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     console.log("Closed by Timer");
    //   }
    // });

    emailReceipt(record)
      .then((resultObj) => {
        console.log("*******((((((", resultObj);
        // MySwal.close();
        // const data = resultObj.data;
        NotificationManager.success(
          "E-mail sent successfully",
          "Invoice",
          1200
        );
      })
      .catch((error) => {
        MySwal.close();
        NotificationManager.error("Error in sending e-mail", "Invoice", 1200);
        console.log("Error", error);
      });
  };

  const printPayment = async (record) => {
    // MySwal.fire({
    //   title: "Payment",
    //   html: "Downloading the Payment. Please be patient.",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     // `MySwal` is a subclass of `Swal` with all the same instance & static methods
    //     MySwal.showLoading();
    //   },
    //   onClose: () => {},
    // }).then((result) => {
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     console.log("Closed by Timer");
    //   }
    // });

    const resultObj = await downloadPayment(record);
    // console.log("resultObj", resultObj);
    // MySwal.close();
    // window.open("data:application/pdf," + escape(resultObj));
    // return;
    // const data = resultObj.data;
    if (!hasErrors(resultObj)) {
      if (resultObj.messageType == "success") {
        // if (resultObj.errCode == "0") {
        // MySwal.close();
        openNotificationWithIcon("success", "Success", "Payment Downloaded.");
        //  // debugger;
        // var byteCharacters = atob(resultObj.data.data);
        // var byteNumbers = new Array(byteCharacters.length);
        // for (var i = 0; i < byteCharacters.length; i++) {
        //   byteNumbers[i] = byteCharacters.charCodeAt(i);
        // }
        var byteArray = new Uint8Array(resultObj.data.data);
        var file = new Blob([byteArray], { type: "application/pdf;base64" });
        var fileURL = URL.createObjectURL(file);

        // window.open(fileURL);
        // Open the window

        const pdfWindow = window.open(fileURL);
        // Call print on it
        setTimeout(() => {
          if (!pdfWindow) pdfWindow.print();
        }, 300);

        // resetPage();
        // showInvoices();
      } else {
        // MySwal.close();
        //Display Error
        openNotificationWithIcon(
          "error",
          "Error",
          resultObj.errDesc ? resultObj.errDesc : "An error has occured!"
        );
      }
    } else {
      // MySwal.close();
      //Display Error
      openNotificationWithIcon(
        "error",
        "Error",
        resultObj.message ? resultObj.message : "An error has occured!"
      );
    }
  };

  const downloadPayment = (record) => {
    // debugger;

    return new Promise((resolve, reject) => {
      let URL = process.env.REACT_APP_API_URL + `/email/printTransactionEMail`;
      const stripeParms = {
        payment_id: record.payment_id, //state.invoiceId,
        session_user_id: state.invoice_user_id, // props.userId,
      };

      PNfetch(URL, {
        method: "POST",
        // responseType: "arraybuffer",
        // responseEncoding: "binary",
        // responseType: "stream",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", //"application/json"
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          ...stripeParms,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        // .then((response) => resolve(response.json()))
        .catch((error) => reject(error));
    });
  };

  const uploadFile = (file_name, file_data) => {
    // debugger;

    return new Promise((resolve, reject) => {
      let URL = process.env.REACT_APP_API_URL + `/azurefile/uploadFile`;

      // let URL = `http://localhost:8080/azurefile/uploadFile`;
      // if (process.env.NODE_ENV == "production") {
      //   URL = `https://dev.parknation.org/services/azurefile/uploadFile`;
      // }
      PNfetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          session_user_id: state.invoice_user_id,
          file_data: file_data, //state.originalFile,
          file_name,
        }),
      })
        //.then(statusHelper)
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });
  };

  const onFinishVehicle = (values) => {
    console.log(values);
  };

  const handleVehicleFileDropped = async (e) => {
    // debugger;

    //  file_name: record.file_name,
    //     file_title: record.file_title,
    //     file_type: record.file_type,
    //     file_size: record.file_size,

    //  setState({ selectedFile: event.target.files[0] });
    const file = event.target.files;
    if (file) {
      await fileToBase64(file[0], (err, result) => {
        //  // debugger;
        if (result) {
          const pos = result.indexOf(",");
          const readType = result.substring(0, pos);
          const base64Data = result.substring(pos + 1);
          console.log("name", file[0].name);
          console.log("size", file[0].size);
          console.log("type", file[0].type);
          console.log("readType", readType);
          console.log("base64Data", base64Data);

          const record = formVehicleRef.current.getFieldsValue(true);

          setState({
            originalFile: file[0],
            file: result, //file[0],
            fileType: readType,
            fileContent: base64Data,
            document_name: file[0].name,
          });

          formVehicleRef.current.setFieldsValue({
            ...record,
            file_name: file[0].name,
            file_size: file[0].size,
            file_type: readType,
          });
        }
      });
    }
  };

  const setNewVehicleModalVisible = (visible) => {
    setState({ newVehicleModalVisible: visible });
  };

  const saveVehicleInDB = (parms) => {
    //  debugger;

    return new Promise((resolve, reject) => {
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_vehicles",
          input: parms,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });

    // return new Promise((resolve, reject) => {
    //   fetch(`https://dev.parknation.org/api/v1.0/payments`, {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       authorization: "Bearer " + localStorage.getItem("accessToken"),
    //     },
    //     body: JSON.stringify({
    //       ...parms,
    //     }),
    //   })
    //     .then((response) => resolve(response ? response.json() : null))
    //     .catch((error) => reject(error));
    // });
  };

  const saveVehicle = async (key) => {
    try {
      debugger;
      let resultObjVehicle;
      const record = formVehicleRef.current.getFieldsValue(true);
      console.log("record", record);
      const row = await formVehicleRef.current.validateFields();

      //     color: "White"
      // make: "BMW"
      // model: "X5"
      // user_id_fk: 1
      // vin: "d4325235"

      // const newSpaceTypeOption

      const fileContent = state.fileContent; //record.file_type + "," +
      if (!isNil(record.file_name) && trim(record.file_name) != "") {
        uploadFile(record.file_name, fileContent)
          .then(async (resultObj) => {
            // MySwal.close();
            // const data = resultObj.data;
            console.log("resultObj", resultObj);
            const parms = [
              state.invoice_user_id,
              record.vehicle_id ? record.vehicle_id : -1,
              // "-1",
              record.user_id_fk,
              record.license_num,
              record.model,
              record.make,
              record.color,
              record.vin ? record.vin : -1,
              record.mc ? record.mc : -1,
              record.dot ? record.dot : -1,
              record.notes ? record.notes : -1,
              resultObj.url,
              record.file_name,
              record.truck_num,
              record.trailer_num,
              record.push_notify,
            ];
            const resultObjVehicleData = await saveVehicleInDB(parms);

            if (!hasDBErrors(resultObjVehicleData)) {
              MySwal.close();

              resultObjVehicle = resultObjVehicleData.data[0][0];
              //  debugger;
              console.log("Vehicle Created", resultObjVehicle);
              NotificationManager.success(
                "Vehicle Updated successfully",
                "Vehicles",
                1200
              );
            } else {
              //Display Error
              openNotificationWithIcon(
                "error",
                "Error",
                resultObj.message ? resultObj.message : "An error has occured!"
              );
            }
            // MySwal.close();
          })
          .catch((error) => {
            // MySwal.close();
            console.log("Error", error);
          });

        props.refreshReferenceData({
          type: actionTypes.REFRESH_REFDATA_REQUEST,
          payload: {
            parms: [state.invoice_user_id],
          },
          user_id_fk: state.invoice_user_id,
        });

        setTimeout(() => {
          setState({
            newVehicleModalVisible: false,
            vehicle_id: resultObjVehicle.vehicle_id,
          });

          const oldRecord = formVehicleRef.current.getFieldsValue(true);
          const newRecord = {
            ...oldRecord,
            vehicle_id: resultObjVehicle.vehicle_id,
          };

          formVehicleRef.current.setFieldsValue({
            ...newRecord,
          });
        }, 300);
      } else {
        const parms = [
          state.invoice_user_id,
          record.vehicle_id ? record.vehicle_id : -1,
          // "-1",
          state.invoice_user_id,
          record.license_num,
          record.model,
          record.make,
          record.color,
          record.vin ? record.vin : -1,
          record.mc ? record.mc : -1,
          record.dot ? record.dot : -1,
          record.notes ? record.notes : -1,
          "", //resultObj.url,
          record.file_name,
          record.truck_num,
          record.trailer_num,
          record.push_notify,
        ];

        const resultObjVehicleData = await saveVehicleInDB(parms);
        if (!hasDBErrors(resultObjVehicleData)) {
          // MySwal.close();
          resultObjVehicle = resultObjVehicleData.data[0][0];
          //  debugger;
          console.log("Vehicle Created", resultObjVehicle);
          NotificationManager.success(
            "Vehicle Updated successfully",
            "Vehicles",
            1200
          );
        } else {
          //Display Error
          openNotificationWithIcon(
            "error",
            "Error",
            resultObj.message ? resultObj.message : "An error has occured!"
          );
        }

        props.refreshReferenceData({
          type: actionTypes.REFRESH_REFDATA_REQUEST,
          payload: {
            parms: [state.invoice_user_id],
          },
          user_id_fk: state.invoice_user_id,
        });

        setTimeout(() => {
          setState({
            newVehicleModalVisible: false,
            vehicle_id: resultObjVehicle.vehicle_id,
          });

          const oldRecord = formVehicleRef.current.getFieldsValue(true);
          const newRecord = {
            ...oldRecord,
            vehicle_id: resultObjVehicle.vehicle_id,
          };

          formVehicleRef.current.setFieldsValue({
            ...newRecord,
          });
        }, 300);
      }

      // setSpaceTypeVisible(false);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const displayMarkers = () => {
    return state.sites.map((site, index) => {
      // debugger;
      return (
        <Marker
          key={index}
          id={site.site_id}
          position={{
            lat: site.latitude,
            lng: site.longitude,
          }}
          onClick={(site) => {
            const siteObj = state.sites.filter(
              (rec) => rec.site_id == site.id
            )[0];
            setState({ showSiteDetailsModal: true, currentSite: siteObj });
            // alert("You clicked me! " + siteObj.address_line_1);
            // console.log("site! " + JSON.parse(site));
            // console.log("You clicked me! " + site.address_line_1);
          }}
        />
      );
    });
  };
  const hasDBErrors = (retObj) => {
    if (!isNil(retObj.errCode) && retObj.errCode != "0") {
      return true;
    } else {
      return false;
    }
  };

  const printSiteName = (item) => {
    //
    const custStr =
      (item.name ? item.name : " ") +
      " | " +
      (item.description ? item.description : " ") +
      " | " +
      (item.city ? item.city : "No City") +
      " | " +
      (item.state ? item.state : "No State") +
      " | " +
      (item.zipcode ? item.zipcode : "No Zip");
    return custStr;
  };

  const showAddVehicle = () => {
    formVehicleUseRef.resetFields();
    setState({ newVehicleModalVisible: true });
  };

  const getVacancyData = async (subscription_start_dt, subscription_end_dt) => {
    debugger;
    //dateRange
    const parms = [
      state.site_id,
      // state.vehicle_type_id,
      `${dayjs(subscription_start_dt).format("YYYY/MM/DD")}`,
      `${dayjs(subscription_end_dt).format("YYYY/MM/DD")}`,
    ];
    let URL = AppConfig.SQL_URL; //`https://api.parknation.org/sql/execSP`;

    // const optionToken = await AsyncStorage.getItem('accessToken');
    return new Promise((resolve, reject) => {
      // fetch(`https://dev.parknation.org/api/v1.0/users/${parms[1]}/cards`, {
      PNfetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_all_Siteavailable_Spaces",
          input: parms,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });
  };

  const getVacancies = () => {
    (async () => {
      if (
        isNil(state.subscription_start_dt) ||
        isNil(state.subscription_end_dt)
      ) {
        return false;
      }

      // setState({
      //   subscription_start_dt: dateRange[0],
      //   subscription_end_dt: dateRange[1],
      // });

      const resultObj = await getVacancyData(
        state.subscription_start_dt,
        state.subscription_end_dt
      );
      if (!hasErrors(resultObj)) {
        console.log("resultObj", resultObj);
        const distinctSpaceTypeIds = [
          ...new Set(resultObj.data[0].map((item) => item.space_type_id)),
        ];
        let availableSpaces = [];
        distinctSpaceTypeIds.map((distinctSpaceTypeId) => {
          const spaceTypeRows = resultObj.data[0].filter(
            (rec) => rec.space_type_id == distinctSpaceTypeId
          );
          availableSpaces.push({
            space_type_id: distinctSpaceTypeId,
            space_type_name: spaceTypeRows[0].space_type_name,
            count: spaceTypeRows.length,
          });
        });
        setState({
          vacancyData: resultObj.data[0],
          vacancyFilteredData: resultObj.data[0],
          filteredSpaceTypeName: "ALL",
          availableSpaces,
          space_id: undefined,
        });
      }
    })();
  };

  const printCustName = (item) => {
    //
    if (!item) {
      return "";
    }

    const custStr =
      (item.last_name ? item.last_name : " ") +
      " , " +
      (item.first_name ? item.first_name : " ") +
      " | " +
      (item.email ? item.email : "No Email") +
      " | " +
      (item.phone_number
        ? formatPhoneNumber(item.phone_number)
        : "No Phone #") +
      " | " +
      (item.company_name ? item.company_name : "No Company Name");
    return custStr;
  };

  const handleSite = (val) => {
    // const record = formRef.current.getFieldsValue(true);
    const site_id = val;
    const foundRec = state.sites.filter((itm) => itm.site_id == site_id)[0];

    setState({
      site_id: val,
      tax_rate: foundRec.tax_rate,
      tax: true,
      site_name: foundRec.name,
      siteRec: foundRec,
      site_phone_number: foundRec.site_phone_number,
      renderVersion: state.renderVersion + 1,
    });

    // console.log(
    //   `${state.mapSrc}${foundRec && foundRec.address_line_1}, ${
    //     foundRec && foundRec.city
    //   }, ${foundRec && foundRec.state}, USA`
    // );
  };

  // const formatPhoneNumber = (str) => {
  //   //Filter only numbers from the input
  //   let cleaned = ("" + str).replace(/\D/g, "");

  //   //Check if the input is of correct length
  //   let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return "(" + match[1] + ") " + match[2] + "-" + match[3];
  //   }
  //   return null;
  // };

  const disabledDateRange = (current) => {
    // const today = dayjs().add(1, "month");
    const today = dayjs();
    const endDate = dayjs().add(1, "year");

    return !dayjs(current).isBetween(today, endDate, "day", "[]");
  };

  const renderHLSCameras = () => {
    // const activeCameras = state.cameraData.filter(
    //   (camera) => camera.deviceType == "camera" && camera.status == "ATTD"
    // );
    debugger;
    const activeCameras = [
      {
        cameraId: 1,
        name: "Main Driveway",
        // src: "https://streams.parknation.org:444/stream/1/channel/0/hls/live/index.m3u8",
        src: "https://streams.parknation.org/streams/stream-1/video.m3u8",
      },
      {
        cameraId: 5,
        name: "Front PTZ",
        // src: "https://streams.parknation.org:444/stream/5/channel/0/hls/live/index.m3u8",
        src: "https://streams.parknation.org/streams/stream-5/video.m3u8",
      },
      {
        cameraId: 14,
        name: "Side Driveway",
        // src: "https://streams.parknation.org:444/stream/1/channel4/0/hls/live/index.m3u8",
        src: "https://streams.parknation.org/streams/stream-14/video.m3u8",
      },
    ];

    return activeCameras.map((camera) => {
      const videoJsOptions = {
        autoplay: state.cameraVisible,
        controls: true,
        preload: "metadata",
        muted: true,
        sources: [
          {
            // src: "/path/to/video.mp4",
            src: camera.src,
            type: "application/x-mpegURL",
          },
        ],
      };

      return (
        <Card.Grid style={gridStyle} key={uuid.v4()}>
          <div onClick={(e) => e.stopPropagation()}>
            <div
              key={camera.name}
              // onClick={(e) => showLiveCameraVideo(camera)}
            >
              <span
                style={{
                  color: "black",
                  display: "block",
                  marginBottom: "8px",
                }}
              >
                {camera.name}
              </span>
              <div
                style={{
                  borderRadius: 8,
                  overflow: "hidden",
                  width: "100%",
                  height: "220px",
                }}
              >
                <VideoPlayer {...videoJsOptions} key={camera.cameraId} />
              </div>
            </div>
          </div>
        </Card.Grid>
      );
    });
  };

  const renderCameras = () => {
    // const activeCameras = state.cameraData.filter(
    //   (camera) => camera.deviceType == "camera" && camera.status == "ATTD"
    // );

    // debugger;
    const activeCameras = [
      {
        cameraId: 1,
        name: "Main Driveway",
        src: "https://streams.parknation.org/streams/stream-1/video.m3u8",
      },
      {
        cameraId: 5,
        name: "Front PTZ",
        src: "https://streams.parknation.org/streams/stream-5/video.m3u8",
      },
      {
        cameraId: 14,
        name: "Side Driveway",
        src: "https://streams.parknation.org/streams/stream-14/video.m3u8",
      },
    ];

    return activeCameras.map((camera, index) => {
      return (
        <Col xs={24} md={24} lg={24}>
          <Card.Grid style={gridStyle}>
            <CameraRTC
              cameraName={camera.name}
              cameraId={camera.cameraId}
              channel={0}
              cameraHeight={"160px"}
            />
          </Card.Grid>
        </Col>
      );
    });
  };

  const updatedateRange = (dates) => {
    if (dates) {
      setState({
        subscription_start_dt: dates[0],
        subscription_end_dt: dates[1],
      });
    }
  };
  const repeatEndDtRecurrency = ["Never", "On Date"];

  const showReserveDrawer = (customerObj) => {
    debugger;
    console.log("customerObj", customerObj);
    console.log("start dt", state.subscription_start_dt);
    console.log("end dt", state.subscription_end_dt);
    formUseRef.resetFields();

    formRef &&
      formRef.current &&
      formRef.current.setFieldsValue({
        // subscription_start_dt: dayjs(state.subscription_start_dt),
        // subscription_end_dt: dayjs(state.subscription_end_dt),
        space_id: state.space_id,
        name: customerObj.last_name + ", " + customerObj.first_name,
      });

    setState({
      showLogin: false,
      showSummary: false,
      showReserve: true,
      createSubscription: "Y",
      invoice_user_id: customerObj.user_id,
      customer: customerObj,
      name: customerObj.last_name + ", " + customerObj.first_name,
      space_id: state.space_id,
      renderVersion: state.renderVersion + 1,
    });
    setTimeout(() => {
      handleProrateOption(4); //ProrateOption
    }, 300);
  };

  // const formatPhoneNumber = (str) => {
  //   //Filter only numbers from the input
  //   let cleaned = ("" + str).replace(/\D/g, "");

  //   //Check if the input is of correct length
  //   let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return "(" + match[1] + ") " + match[2] + "-" + match[3];
  //   }
  //   return null;
  // };

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
    setState({
      subscription_start_dt: newValue.startDate
        ? newValue.startDate
        : undefined,
      subscription_end_dt: newValue.endDate ? newValue.endDate : undefined,
      renderVersion: state.renderVersion + 1,
    });

    // formRef &&
    //   formRef.current &&
    //   formRef.current.setFieldsValue({
    //     subscription_start_dt: newValue.startDate
    //       ? dayjs(newValue.startDate)
    //       : undefined,
    //     subscription_end_dt: newValue.endDate
    //       ? dayjs(newValue.endDate)
    //       : undefined,
    //   });
  };

  return (
    <>
      <section
        className={` ${
          location.pathname === "/"
            ? "py-12 md:py-14 lg:pb-[50px] lg:pt-[81px] "
            : "py-12 md:py-14 lg:pt-10"
        }`}
      >
        <div className="container">
          <div
            className="bg-white shadow-[0px_4px_76px_0px_rgba(0,0,0,0.16)] rounded-[10px] p-[30px]"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <h2 className=" text-[18px]  md:text-md lg:text-lg text-center font-Manrope font-bold">
              {!props.isMobile ? `Select Unit` : "Select Unit"}
            </h2>
            <form
              action=""
              className="font-Poppins lg:flex items-end  gap-8 mt-[30px]"
            >
              <div className=" sm:flex justify-between gap-6 md:gap-10">
                <div className="w-full sm:w-[320px] lg:w-[350px]  xl:w-[460px]">
                  <label className="text-base w-full text-black block mb-[2px] mt-[19px] lg:mt-0">
                    Location
                  </label>

                  {/* <input
                  className="border-[1px] h-[41px] font-light  border-[#020202] border-opacity-10 font-Poppins text-black text-base rounded-[6px] bg-white  py-[11px] ps-2 mt-[10px]  focus:outline-primary w-full placeholder-black"
                  type="text"
                  required
                  placeholder="Location, 12345 USA"
                /> */}
                  <Select
                    size="large"
                    showSearch
                    className="text-[16px] align-top h-[41px] font-light  border-[#020202] border-opacity-10 font-Poppins text-black rounded-[6px] bg-white  mt-[10px]  focus:outline-primary w-full placeholder-black"
                    style={{ height: "100%" }}
                    placeholder="Select a Location"
                    optionFilterProp="children"
                    dropdownMatchSelectWidth={580}
                    onChange={handleSite}
                    value={state.site_id}
                    filterOption={(input, option) => {
                      return (
                        (option.searchText || "")
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {(state.sites || []).map((item) => (
                      <Select.Option
                        key={uuid.v4()}
                        value={item.site_id}
                        searchText={item.name}
                      >
                        <span>{printSiteName(item)}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className=" sm:flex gap-6 md:gap-10">
                <div className="w-full sm:w-[340px] lg:w-[320px] relative z-10">
                  <div className="w-full relative z-10">
                    <label className="text-base w-full text-black block mb-[2px] mt-[19px] lg:mt-0">
                      Date Range
                    </label>
                    <Datepicker
                      primaryColor={"blue"}
                      inputClassName="w-full rounded-md focus:ring-0 font-normal bg-grey-100 dark:bg-grey-900 dark:placeholder:text-grey-100"
                      value={dateRangeValue}
                      showShortcuts={true}
                      configs={{
                        shortcuts: {
                          today: "Today",
                          next3Days: {
                            text: "Next 3 days",
                            period: {
                              start: dayjs(),
                              end: dayjs().add(3, "d"),
                            },
                          },

                          customToday: {
                            text: "Next 7 Days ",
                            period: {
                              start: dayjs(),
                              end: dayjs().add(7, "d"),
                            },
                          },
                          next15Days: {
                            text: "Next 15 days",
                            period: {
                              start: dayjs(),
                              end: dayjs().add(15, "d"),
                            },
                          },
                          next230Days: {
                            text: "Next 30 days",
                            period: {
                              start: dayjs(),
                              end: dayjs().add(30, "d"),
                            },
                          },
                        },
                      }}
                      minDate={dayjs()}
                      maxDate={dayjs().add(1, "y")}
                      startFrom={dayjs()}
                      // disabledDates={[
                      //   {
                      //     startDate: dayjs(),
                      //     endDate: dayjs().add(1, "y"),
                      //   },
                      // ]}
                      showFooter={true}
                      displayFormat={"MM/DD/YYYY"}
                      onChange={handleValueChange}
                    />
                    {/* <ADatePicker
                    size="large"
                    format={"MM/DD/YYYY"}
                    disabledDate={disabledSubscriptionStartDate}
                    value={dayjs(state.subscription_start_dt)}
                    onChange={(e, ds) => setSubscriptionStartDt(e, ds)}
                    cla
                  ></ADatePicker>
                  <span> ~ </span>                     
                  <ADatePicker
                    size="large"
                    format={"MM/DD/YYYY"}
                    disabledDate={disabledSubscriptionEndDate}
                    value={dayjs(state.subscription_end_dt)}
                    onChange={(e, ds) => setSubscriptionEndDt(e, ds)}
                  ></ADatePicker> */}
                  </div>
                </div>
              </div>

              <Button
                size="large"
                type="primary"
                danger
                onClick={(e) => getVacancies()}
                className="px-[11px] mt-6 lg:mt-0 font-Manrope text-base font-semibold mx-auto lg:mx-0"
              >
                Search
              </Button>
            </form>
            {state.vacancyData && (
              <div className="mt-4">
                {state.vacancyData.length > 0 && (
                  <>
                    <div
                      style={{
                        fontSize: "14px",
                        marginLeft: "4px",
                        marginTop: "8px",
                      }}
                    >
                      Available Unit Types
                    </div>

                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                      <Row>
                        {(state.availableSpaces || []).map((rec) => {
                          return (
                            <Col xs={24} md={8} lg={6} xl={6}>
                              <div
                                style={{ padding: "15px 0px 5px" }}
                                onClick={(e) => filterSpaces(rec)}
                              >
                                <Badge count={rec.count}>
                                  <Avatar
                                    shape="square"
                                    style={{
                                      // fontSize: "24px",
                                      fontWeight: 600,
                                      width: "200px",
                                      height: "68px",
                                      lineHeight: "68px",
                                      backgroundColor: "#464d69",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {rec.space_type_name}
                                  </Avatar>
                                </Badge>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        marginLeft: "4px",
                        marginTop: "8px",
                      }}
                    >
                      {`Available Space(s) - ${state.filteredSpaceTypeName}`}
                    </div>
                  </>
                )}
                <Row gutter={[24, 24]} className={"mb-4"}>
                  {state.vacancyFilteredData.length > 0 ? (
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      style={{ maxHeight: "900px", overflowY: "auto" }}
                    >
                      {state.vacancyFilteredData.map((space) => {
                        return (
                          <Card bordered>
                            <PageHeader
                              // onBack={() => window.history.back()}
                              // xs={24}
                              // sm={24}
                              // md={24}
                              // lg={24}
                              // xl={24}
                              title={space.space_name}
                              // tags={
                              //   state.currentUser?.subscriptionCount > 0 ? (
                              //     <Tag color="blue">Recurring Billing</Tag>
                              //   ) : (
                              //     <Tag color="grey">Transient</Tag>
                              //   )
                              // }
                              subTitle={space.space_type_name}
                              extra={actionArray(space)}
                              className={"px-4 py-3"}
                              // onClick={(e) =>
                              //   setState({ space_id: space.space_id })
                              // }
                            >
                              <Row>
                                {/*<Col xs={12} md={12} lg={12} xl={6}>
                                   <Card
                                    size="small"
                                    // style={{ width: "150px" }}
                                    // title={
                                    //   <span
                                    //     style={{
                                    //       color: "#d8dae5",
                                    //     }}
                                    //   >
                                    //     Daily
                                    //   </span>
                                    // }
                                    // bordered={false}
                                    className={"ai-card-header"}
                                    style={{
                                      backgroundColor: "#464d69",
                                      color: "white",
                                      // margin: "12px",
                                    }}
                                    extra={
                                      <span
                                        style={{
                                          color: "#fff",
                                          fontSize: "21px",
                                        }}
                                      >
                                        {formatNumber(space.daily_rate)}
                                      </span>
                                    } //"#de9393"
                                  >
                                    <span
                                      style={{
                                        color: "#d8dae5",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Daily
                                    </span>
                                  </Card>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={6}>
                                  <Card
                                    size="small"
                                    // title={
                                    //   <span
                                    //     style={{
                                    //       color: "#d8dae5",
                                    //     }}
                                    //   >
                                    //     Weekly
                                    //   </span>
                                    // }
                                    // bordered={false}
                                    className={"ai-card-header"}
                                    style={{
                                      backgroundColor: "#464d69",
                                      color: "white",
                                      // margin: "12px",
                                    }}
                                    extra={
                                      <span
                                        style={{
                                          color: "#fff",
                                          fontSize: "21px",
                                        }}
                                      >
                                        {formatNumber(space.weekly_rate)}
                                      </span>
                                    } //"#de9393"
                                  >
                                    {" "}
                                    <span
                                      style={{
                                        color: "#d8dae5",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Weekly
                                    </span>
                                  </Card>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={6}>
                                  <Card
                                    size="small"
                                    // title={
                                    //   <span
                                    //     style={{
                                    //       color: "#d8dae5",
                                    //     }}
                                    //   >
                                    //     Weekend
                                    //   </span>
                                    // }
                                    // bordered={false}
                                    className={"ai-card-header"}
                                    style={{
                                      backgroundColor: "#464d69",
                                      color: "white",
                                      // margin: "12px",
                                    }}
                                    extra={
                                      <span
                                        style={{
                                          color: "#fff",
                                          fontSize: "21px",
                                        }}
                                      >
                                        {formatNumber(space.weekend_rate)}
                                      </span>
                                    } //"#de9393"
                                  >
                                    {" "}
                                    <span
                                      style={{
                                        color: "#d8dae5",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Weekend
                                    </span>
                                  </Card>
                                </Col> */}
                                <Col xs={24} md={24} lg={26} xl={24}>
                                  <Card
                                    size="small"
                                    // title={
                                    //   <span
                                    //     style={{
                                    //       color: "#d8dae5",
                                    //     }}
                                    //   >
                                    //     Monthly
                                    //   </span>
                                    // }
                                    // bordered={false}
                                    className={"ai-card-header"}
                                    style={{
                                      backgroundColor: "#464d69",
                                      color: "white",
                                      maxWidth: "150px",
                                      // margin: "12px",
                                    }}
                                    extra={
                                      <span
                                        style={{
                                          color: "#fff",
                                          fontSize: "21px",
                                        }}
                                      >
                                        {formatNumber(space.monthly_rate)}
                                      </span>
                                    } //"#de9393"
                                  >
                                    {" "}
                                    <span
                                      style={{
                                        color: "#d8dae5",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Monthly
                                    </span>
                                  </Card>
                                </Col>
                              </Row>
                            </PageHeader>
                          </Card>
                        );
                      })}
                    </Col>
                  ) : (
                    <Col
                      xs={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ maxHeight: "900px", overflowY: "auto" }}
                    >
                      <div style={{ fontSize: "18px" }}>
                        No spaces Available, Please contact the Site manager at{" "}
                        {formatPhoneNumber(state.site_phone_number)}
                      </div>
                    </Col>
                  )}
                  <Col xs={24} md={24} lg={12} xl={12}>
                    <>
                      <div className="w-full lg:w-[100%] mx-auto lg:mr-auto">
                        <div
                          style={{ height: "400px", marginBottom: "12px" }}
                          className="border-[5px] sm:border-[10px] border-[#D4D6DB] rounded-[24px]"
                        >
                          <iframe
                            src={`${state.mapSrc}${
                              state.siteRec && state.siteRec.address_line_1
                            }, ${state.siteRec && state.siteRec.city}, ${
                              state.siteRec && state.siteRec.state
                            }, USA`}
                            // src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM&zoom=17&&maptype=satellite&q=350 Berlin-Cross keys rd,Sicklerville, NJ,USA"
                            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11207.71096584784!2d-74.15525973265272!3d40.771082038444675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c256a1131334c9%3A0xd29d74da8b90a6e7!2sKearny%2C%20NJ%2C%20USA!5e0!3m2!1sen!2sin!4v1683604031625!5m2!1sen!2sin"
                            width="600"
                            height="100%"
                            allowfullscreen=""
                            loading="lazy"
                            className="w-full !h-full"
                            style={{ border: "0", borderRadius: "12px" }}
                            referrerpolicy="no-referrer-when-downgrade"
                          />
                        </div>
                      </div>
                      <List className="border-[5px] sm:border-[10px] border-[#D4D6DB] rounded-[24px]">
                        <List.Item
                          style={{
                            border: "0",
                            borderRadius: "12px",
                            border: "1px solid lightgrey",
                            padding: "20px",
                          }}
                          key={state.siteRec && state.siteRec.title}
                          actions={[
                            <IconText
                              icon={StarOutlined}
                              text={`Vacancies: ${state.vacancyData?.length}`}
                              key="list-vertical-star-o"
                            />,
                            <Rate
                              key="list-rate"
                              allowHalf
                              disabled
                              defaultValue={4.5}
                            ></Rate>,
                            // <IconText
                            //   icon={LikeOutlined}
                            //   text="156"
                            //   key="list-vertical-like-o"
                            // />,
                            <IconText
                              icon={MessageOutlined}
                              text="2"
                              key="list-vertical-message"
                            />,
                            <i
                              className="fas fa-camera"
                              key="list-vertical-camera"
                            ></i>,
                            <i
                              className="fas fa-user-shield"
                              key="list-vertical-lightbulb"
                            ></i>,
                            <i
                              className="fas fa-sun"
                              key="list-vertical-lightbulb"
                            ></i>,
                            <i
                              className="fas fa-restroom"
                              key="list-vertical-camera"
                            ></i>,
                          ]}
                          extra={
                            <div
                              style={{
                                textAlign: "-webkit-center",
                                padding: "8px",
                              }}
                            >
                              <Carousel
                                // autoplay
                                // effect="fade"
                                arrows
                                nextArrow={<RiArrowRightSLine />}
                                prevArrow={<RiArrowLeftSLine />}
                                style={{ width: "300px", maxHeight: "300px" }}
                              >
                                {state.siteRec &&
                                  state.siteRec.imagesURL &&
                                  // &&
                                  //   trim(item.imagesURL) != "" &&
                                  //   JSON.parse(item.imagesURL)
                                  //     .filter((x) => !!x)
                                  (state.siteRec.imagesURL || []).map(
                                    (img, idx) => (
                                      <div key={idx}>
                                        <Image style={contentStyle} src={img} />
                                      </div>
                                    )
                                  )}
                              </Carousel>
                            </div>
                          }
                        >
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                src={state.siteRec && state.siteRec.logo_url}
                              />
                            }
                            title={
                              <div
                                className={
                                  "d-flex align-items-center flex-wrap"
                                }
                              >
                                <div
                                  // href={state.siteRec.web_site_url}
                                  style={{ marginRight: "12px" }}
                                >
                                  <span style={{ marginRight: "8px" }}>
                                    {state.siteRec &&
                                      state.siteRec.logo_company_name}
                                  </span>
                                  <Button
                                    type="primary"
                                    danger
                                    style={{
                                      cursor: "pointer",
                                      // fontWeight: 700,
                                      color: "#fff",
                                    }}
                                    onClick={(e) =>
                                      setState({ cameraVisible: true })
                                    }
                                  >
                                    Live Camera
                                  </Button>
                                  {/*<Tag
                                  color={"#87d068"}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setState({ cameraVisible: true })
                                  }
                                >
                                  Live Camera
                                </Tag> */}
                                </div>
                              </div>
                            }
                            description={`${
                              state.siteRec && state.siteRec.address_line_1
                            }, ${state.siteRec && state.siteRec.city},  ${
                              state.siteRec && state.siteRec.state
                            } - ${state.siteRec && state.siteRec.zipcode}`}
                          />
                          {state.siteRec && state.siteRec.description}
                        </List.Item>
                      </List>
                    </>
                  </Col>
                </Row>
              </div>
            )}

            <div
              className={
                "map-container border-[5px] sm:border-[10px] border-[#D4D6DB] rounded-[24px]"
              }
              style={{ marginTop: "25px" }}
            >
              <Map
                google={props.google}
                zoom={4}
                style={{ width: "100%", height: "100%" }}
                initialCenter={{ lat: 39.8282, lng: -98.5795 }} //TO Center USA
              >
                {displayMarkers()}
                {/* <Marker position={{ lat: 48.0, lng: -122.0 }} /> */}
              </Map>
            </div>
          </div>
          <Modal
            title={
              <Row
                gutter={20}
                align={"middle"}
                justify={"space-between"}
                style={{ marginRight: "0px" }}
              >
                <Col span="24">
                  <>
                    <Title level={4} className={"mb-0"}>
                      {`${state.currentSite?.logo_company_name}`}
                    </Title>
                    <div>
                      {`${state.currentSite?.address_line_1} ${state.currentSite?.city} ${state.currentSite?.state} - ${state.currentSite?.zipcode}`}
                    </div>
                    <div>
                      <>
                        <PhoneOutlined />
                        {` ${formatPhoneNumber(
                          state.currentSite?.site_phone_number
                        )}
                      `}
                        <br />
                        <MailOutlined /> {` ${state.currentSite?.site_email}`}
                      </>
                    </div>
                  </>
                </Col>
                {/* <Col></Col> */}
              </Row>
            }
            centered
            open={state.showSiteDetailsModal}
            onOk={() => setState({ showSiteDetailsModal: false })}
            onCancel={() => setState({ showSiteDetailsModal: false })}
            width={1000}
            footer={null}
            // closable={false}
            maskClosable={false}
            className="childModal pn-modal-root"
            destroyOnClose={true}
            // height={600}
            style={{ zIndex: 999 }}
          >
            <div style={{ marginTop: "2x", padding: "20px" }}>
              <Row>
                <Col span="24" style={{ textAlign: "center" }}>
                  <Carousel
                    // autoplay
                    // effect="fade"
                    arrows
                    nextArrow={<RiArrowRightSLine />}
                    prevArrow={<RiArrowLeftSLine />}
                    style={{ maxHeight: "300px" }}
                  >
                    {state.currentSite?.imagesURL &&
                      // &&
                      //   trim(item.imagesURL) != "" &&
                      //   JSON.parse(item.imagesURL)
                      //     .filter((x) => !!x)
                      (state.currentSite?.imagesURL || []).map((img, idx) => (
                        <div key={idx}>
                          <Image style={contentStyle} src={img} />
                        </div>
                      ))}
                  </Carousel>
                </Col>
              </Row>
              <Row style={{ paddingTop: "50px" }}>
                <Col lg={8}>
                  <Descriptions
                    title="Details"
                    bordered
                    Layout={"horizontal"}
                    column={1}
                    className={"table-layout-fixed"}
                    labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item label="Opening Hours">
                      <span>24 Hours</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Parking fees (net)">
                      <div>12 Hours: 20$</div>
                      <div>24 Hours: 20$</div>
                      <div>Weekend: 20$</div>
                      <div>Week: 20$</div>
                      <div>Monthly: 20$</div>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col lg={8}>
                  {" "}
                  <Descriptions
                    title="Description"
                    bordered
                    Layout={"horizontal"}
                    column={1}
                    // className={"table-layout-fixed"}
                    // labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item>
                      <span
                        style={{
                          textShadow:
                            "1px 1px 2px white, 1px 1px 10px white, 1px 1px 14px white, 0 0 1em white, 0 0 0.2em white;",
                        }}
                      >
                        The secure truck park Truck Parking Arena 31 with 130
                        spaces is located north of Toulouse in the logistics
                        area Eurocentre.A wide range of services are offered,
                        such as: B. a bar-restaurant open seven days a week from
                        6 a.m. to 10.30 p.m., a break room with TV, sanitary
                        facilities with showers and toilets, Wi-Fi, a
                        self-service laundry, bicycles and a truck service
                        station.
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col lg={8}>
                  {" "}
                  <Descriptions
                    title="Amenities"
                    bordered
                    Layout={"horizontal"}
                    column={1}
                    className={"table-layout-fixed"}
                    labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item label="Security">
                      <span></span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Comfort">
                      <span></span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Infrastructure">
                      <span></span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Authentication">
                      <span></span>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row
                align={"middle"}
                justify={"end"}
                style={{ marginTop: "8px" }}
              >
                <Button
                  onClick={(e) => setState({ showSiteDetailsModal: false })}
                  // onClick={(e) => addReserve(state.currentSite)}
                  style={{
                    backgroundColor: "#464d69",
                    color: "white",
                    paddingInline: "16px",
                  }}
                >
                  Close
                </Button>
              </Row>
            </div>
          </Modal>
          <Modal
            // title="Saving Reservation"
            centered
            open={state.saveModalVisible}
            onOk={() => setSaveModalVisible(false)}
            onCancel={() => setSaveModalVisible(false)}
            width={1100}
            footer={null}
            closable={false}
            maskClosable={false}
            className="childModal"
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Spin>
                <Alert
                  message="Saving Reservation"
                  description="Saving Reservation, Please be Patient"
                  type="info"
                />
              </Spin>
            </Space>
          </Modal>
          <Modal
            title="Add /Edit Vehicle"
            centered
            open={state.newVehicleModalVisible}
            onOk={() => setNewVehicleModalVisible(false)}
            onCancel={() => setNewVehicleModalVisible(false)}
            width={1100}
            footer={null}
            closable={false}
            maskClosable={false}
            className="childModal"
            // destroyOnClose={true}
          >
            <Form
              {...layout}
              ref={formVehicleRef}
              form={formVehicleUseRef}
              // name="control-ref"
              onFinish={onFinishVehicle}
              initialValues={{
                user_id_fk: state.invoice_user_id,
                license_num: "",
                model: "",
                make: "",
                color: "",
                vin: "",
                mc: "",
                dot: "",
                notes: "",
                vehicle_id: "-1",
                file_name: "",
                file_title: "",
                file_type: "",
                file_size: " ",
                truck_num: "",
                trailer_num: "",
              }}
              scrollToFirstError
            >
              <Form.Item
                name="user_id_fk"
                label="User"
                hidden={true}
                rules={[
                  {
                    required: false,
                    message: "User is required",
                  },
                ]}
              ></Form.Item>
              <Form.Item
                name="license_num"
                label="License #"
                rules={[
                  {
                    required: true,
                    message: "License # is required",
                  },
                ]}
              >
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="color"
                label="Color"
                rules={[
                  {
                    required: true,
                    message: "Color is required ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="make"
                label="Make"
                rules={[
                  {
                    required: false,
                    message: "Make is required",
                  },
                ]}
              >
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="model"
                label="Model"
                rules={[
                  {
                    required: false,
                    message: "Model is required",
                  },
                ]}
              >
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="truck_num"
                label="Truck #"
                rules={[
                  {
                    required: false,
                    message: "License # is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="trailer_num"
                label="Trailer #"
                rules={[
                  {
                    required: false,
                    message: "License # is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="vin"
                label="Vin"
                rules={[
                  {
                    required: false,
                    // message: "Model is required",
                  },
                ]}
              >
                <Input
                  maxLength={17}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="mc"
                label="MC#"
                rules={[
                  {
                    required: false,
                    // message: "Model is required",
                  },
                ]}
              >
                <Input
                  maxLength={6}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="dot"
                label="DOT#"
                rules={[
                  {
                    required: false,
                    // message: "Model is required",
                  },
                ]}
              >
                <Input
                  maxLength={8}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="notes"
                label="Notes"
                rules={[
                  {
                    required: false,
                    // message: "Model is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="file"
                label="Upload Image"
                rules={[
                  {
                    required: false,
                    message: "Please Select a file",
                  },
                ]}
              >
                <input type="file" onChange={handleVehicleFileDropped} />
              </Form.Item>
              <Row>
                <Col span="8"></Col>
                <Col
                  span="16"
                  style={{ textAlign: "right", display: "inline-block" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "8px", width: "100px" }}
                    onClick={(e) => saveVehicle()}
                  >
                    Add Vehice
                  </Button>
                  <Button
                    htmlType="button"
                    style={{ marginRight: "8px", width: "100px" }}
                    onClick={(e) => setNewVehicleModalVisible(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              {/* <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item> */}
            </Form>
          </Modal>
          <Modal
            title="Add Payment Method - Clover"
            centered
            open={state.cloverPaymentModalVisible}
            onOk={() => setCloverPaymentModalVisible(false)}
            onCancel={() => setCloverPaymentModalVisible(false)}
            width={700}
            footer={null}
            closable={false}
            maskClosable={false}
            className="ant-modal-payment-method quick-charge-payment-method parentModal"
            //  className="childModal"
            destroyOnClose={true}
          >
            {/* <Typography.Title
          level={5}
          style={{ fontSize: 14, padding: "12px 24px", marginBottom: 0 }}
        >
          Payment Method Information
        </Typography.Title> */}
            <Divider style={{ margin: 0 }} />
            <CloverConnect
              user_id_fk={Number(state.invoice_user_id)}
              amountToPay={state.amountToPay}
              invoice_user_id={Number(state.invoice_user_id)}
              site_id={state.site_id}
              name={state.name}
              email={state.customer ? state.customer.email : ""}
              sites={state.sites}
              // {...props}
              parentState={state}
              closeSetupModal={closeCloverPaymentModalVisible}
              showUserDropdown="Y"
              showCheckDisclaimer="Y"
            ></CloverConnect>
          </Modal>
          <Modal
            title={
              <Row
                gutter={20}
                align={"middle"}
                justify={"space-between"}
                style={{ marginRight: "0px" }}
              >
                <Col>
                  <Title level={5} className={"mb-0"}>
                    Login
                  </Title>
                </Col>
                <Col>
                  <div style={{ margin: "-12px 0" }}></div>
                </Col>
              </Row>
            }
            centered
            open={state.showLogin}
            onOk={() => setState({ showLogin: false })}
            onCancel={() => setState({ showLogin: false })}
            width={750}
            //closable={false}
            maskClosable={false}
            className="childModal pn-modal-root"
            destroyOnClose={false}
            height={400}
            style={{ zIndex: 999 }}
            footer={null}
          >
            <div style={{ marginTop: "2px" }}>
              <Login
                showReserve={showReserveDrawer}
                default_site_id={state.site_id}
              />
            </div>
          </Modal>
          <Drawer
            title={
              <>
                <VideoCameraOutlined style={{ marginRight: "5px" }} /> Camera
                Live Feed
              </>
            }
            placement="right"
            closable={true}
            onClose={onCameraDrawerClose}
            open={state.cameraVisible}
            width={380}
            style={{
              padding: 0,
            }}
            contentWrapperStyle={{
              maxWidth: "96%",
              borderTop: "12px solid #ff932a",
            }}
          >
            {/* <div className="player-wrapper"> */}
            <div
              style={{
                display: "flex",
                minWidth: 0,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  padding: "20px 24px",
                  overflowY: "auto",
                }}
              >
                <List style={{ marginBottom: 20 }}>
                  <List.Item>
                    <div
                      style={{
                        flexBasis: "30%",
                        textAlign: "right",
                        paddingRight: 20,
                      }}
                    ></div>
                    <List.Item.Meta
                      title={
                        <Paragraph
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 1.35,
                            marginBottom: 0,
                          }}
                          level={3}
                        >
                          Event Details
                        </Paragraph>
                      }
                    />
                  </List.Item>
                  {/* <List.Item>
                  <div
                    style={{
                      flexBasis: "30%",
                      textAlign: "right",
                      paddingRight: 20,
                    }}
                  >
                    <Typography.Paragraph
                      style={{
                        textTransform: "uppercase",
                        marginBottom: 0,
                      }}
                    >
                      Event:
                    </Typography.Paragraph>
                  </div>
                  <List.Item.Meta description={<span></span>} />
                </List.Item> */}
                  <List.Item>
                    <div
                      style={{
                        flexBasis: "30%",
                        textAlign: "right",
                        paddingRight: 20,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography.Paragraph
                        style={{
                          textTransform: "uppercase",
                          marginBottom: 0,
                        }}
                      >
                        Timestamp:
                      </Typography.Paragraph>
                    </div>
                    <List.Item.Meta
                      description={dayjs().format("YYYY-MM-DD HH:mm:ss")}
                    />
                  </List.Item>
                  <List.Item>
                    <div
                      style={{
                        flexBasis: "30%",
                        textAlign: "right",
                        paddingRight: 20,
                      }}
                    >
                      <Typography.Paragraph
                        style={{
                          textTransform: "uppercase",
                          marginBottom: 0,
                        }}
                      >
                        Site:
                      </Typography.Paragraph>
                    </div>
                    <List.Item.Meta description={"1 Prairie Sun Lane"} />
                  </List.Item>
                  <List.Item>
                    <div
                      style={{
                        flexBasis: "30%",
                        textAlign: "right",
                        paddingRight: 20,
                      }}
                    >
                      <Typography.Paragraph
                        style={{
                          textTransform: "uppercase",
                          marginBottom: 0,
                        }}
                      >
                        Device:
                      </Typography.Paragraph>
                    </div>
                    <List.Item.Meta description={"Entry Gate"} />
                  </List.Item>
                </List>
                <Title style={{ fontSize: 16, marginBottom: 12 }} level={3}>
                  Live Video
                </Title>
                {props.isMobile ? renderHLSCameras() : renderCameras()}{" "}
                <Divider />
              </div>
            </div>
          </Drawer>
          <Drawer
            title={
              <>
                <CalendarOutlined style={{ marginRight: "5px" }} /> Reserve
              </>
            }
            placement="right"
            closable={true}
            onClose={onReserveDrawerClose}
            open={state.showReserve}
            width={800}
            // getContainer={false}  //()
            style={{
              padding: 0,
            }}
            contentWrapperStyle={{
              maxWidth: "96%",
              borderTop: "12px solid #ff932a",
            }}
          >
            <div
              style={{
                display: "flex",
                minWidth: 0,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#464d69",
                  color: "white",
                  lineHeight: "21px",
                  fontWeight: 600,
                  padding: "12px",
                  fontSize: "21px",
                }}
              >
                Reservation Details
              </div>
              <div
                style={{
                  // height: "100%",
                  padding: "20px 24px",
                  overflowY: "auto",
                }}
              >
                <Form
                  //{...layout}
                  {...layoutCol}
                  ref={formRef}
                  form={formUseRef}
                  // ref={formRef}
                  id="dataEntryDiv"
                  // name="control-ref"
                  // onFinish={onFinishItem}
                  initialValues={{
                    site_id: state.site_id,
                    space_id: state.space_id,
                    space_type_id: state.space_type_id,
                    item: state.item,
                    unit: state.unit, //1
                    note: state.note,
                    price: state.price,
                    quantity: state.quantity,
                    setupFee: state.setupFee,
                    setupFeeFormatted: formatNumber(state.setupFee),
                    tax: state.tax,
                    tax_rate: state.tax_rate,
                    vehicle_id: state.vehicle_id,
                    subscription_start_dt: dayjs(state.subscription_start_dt),
                    subscription_end_dt: dayjs(state.subscription_end_dt),
                    explanation: state.explanation,
                    user_id: state.user_id,
                    name: state.name,
                    site_name: state.site_name,
                    subscription_total: state.subscription_total,
                    total_reserve: state.total_reserve,
                    tax_amount: state.tax_amount,
                    createSubscription: state.createSubscription,
                    invoiceRecurEndDtPeriod: state.invoiceRecurEndDtPeriod,
                    prorateOption: state.prorateOption,
                    // explanation: computeExplanation({
                    //   subscription_start_dt: dayjs(state.subscription_start_dt),
                    //   subscription_end_dt: dayjs(state.subscription_end_dt),
                    //   site_id: state.site_id,
                    //   space_id: state.space_id,
                    // }),
                  }}
                >
                  <div className={"pn-modal-body"}>
                    <div style={{ fontWeight: 400, marginTop: "4px" }}>
                      Details
                    </div>
                    <div className="RSFormDiv">
                      <Form.Item
                        name="name"
                        label="Customer"
                        hidden={false}
                        rules={[
                          {
                            required: true,
                            message: "Customer is required",
                          },
                        ]}
                        className={"reserve-name"}
                        // style={{ marginBottom: "4px !important" }}
                      >
                        <Input
                          bordered={false}
                          className="is-disabled-normal"
                          // value={state.name ? state.name : ""}
                        />
                      </Form.Item>
                      <Form.Item
                        name="site_name"
                        label="Site"
                        hidden={false}
                        className={"reserve-name"}
                        // style={{ marginBottom: "4px !important" }}
                        rules={[
                          {
                            required: true,
                            message: "Site is required",
                          },
                        ]}
                      >
                        <Input
                          bordered={false}
                          className="is-disabled-normal"
                          // value={state.siteRec ? state.siteRec.site_name : ""}
                        />
                        {/* <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select a Site"
                      optionFilterProp="children"
                      // dropdownMatchSelectWidth={780}
                      onChange={handleSite}
                      filterOption={(input, option) => {
                        return (
                          (option.searchText || "")
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {(state.sites || []).map((item) => (
                        <Select.Option
                          key={uuid.v4()}
                          value={item.site_id}
                          searchText={item.name}
                        >
                          <span>{printSiteName(item)}</span>
                        </Select.Option>
                      ))}
                    </Select> */}
                      </Form.Item>
                      {/* <Form.Item
                      name="createSubscription"
                      label="Would you like to Create a recurring subscription"
                      rules={[
                        {
                          required: true,
                          message: "Recurring subscription is required",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={handleCreateSubscription}
                        value={state.createSubscription}
                        buttonStyle="solid"
                        // style={{ backgroundColor: "whitesmoke" }}
                        // optionType="button"
                      >
                        <Space direction="horizontal" wrap>
                          <Radio value={"Y"}>Yes</Radio>
                          <Radio value={"N"}>No</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item> */}
                      {state.createSubscription == "Y" ? (
                        <>
                          <Form.Item
                            name="prorateOption"
                            label="Prorating Options"
                            rules={[
                              {
                                required: true,
                                message: "Prorating Options is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              style={{ width: "500px", maxWidth: "100%" }}
                              placeholder="Select an Option"
                              optionFilterProp="children"
                              //popupMatchSelectWidth={780}
                              onChange={handleProrateOption}
                              value={state.prorateOption}
                              filterOption={(input, option) => {
                                return (
                                  (option.searchText || "")
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {prorateArray.map((item) => (
                                <Select.Option
                                  key={uuid.v4()}
                                  value={item.id}
                                  searchText={item.searchText}
                                >
                                  <span>{item.desc}</span>
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="subscription_start_dt"
                            label="Move-In Date"
                            rules={[
                              {
                                required: true,
                                message: "Start Date is required",
                              },
                            ]}
                          >
                            <ADatePicker
                              // className="w-full"
                              // className="is-disabled-normal"
                              allowClear={false}
                              size="large"
                              format={"MM/DD/YYYY"}
                              disabledDate={disabledSubscriptionStartDate}
                              value={dayjs(state.subscription_start_dt)}
                              onChange={(e, ds) =>
                                setSubscriptionStartDt(e, ds)
                              }
                            ></ADatePicker>
                          </Form.Item>

                          <Form.Item label="End">
                            <Form.Item
                              name="invoiceRecurEndDtPeriod"
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 8px)",
                              }}
                            >
                              <Select
                                showSearch
                                style={{ width: 200, verticalAlign: "middle" }}
                                placeholder="Select End Period"
                                optionFilterProp="children"
                                onChange={handleEndDtPeriod}
                                value={state.invoiceRecurEndDtPeriod}
                                size={"large"}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {repeatEndDtRecurrency.map((item) => (
                                  <Select.Option key={uuid.v4()} value={item}>
                                    {item}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.invoiceRecurEndDtPeriod !==
                                currentValues.invoiceRecurEndDtPeriod
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue("invoiceRecurEndDtPeriod") !=
                                "Never" ? ( //"Per Space"
                                  <Form.Item
                                    name="subscription_end_dt"
                                    style={{
                                      display: "inline-block",
                                      width: "calc(50% - 8px)",
                                    }}
                                    // extra={`A subscription requires minimum 2 month period`}
                                    // label="To"
                                    rules={[
                                      {
                                        required: true,
                                        message: "End Date is required",
                                      },
                                    ]}
                                  >
                                    <ADatePicker
                                      // className="is-disabled-normal"
                                      // className="w-full"
                                      allowClear={false}
                                      size="large"
                                      format={"MM/DD/YYYY"}
                                      disabledDate={disabledSubscriptionEndDate}
                                      value={dayjs(state.subscription_end_dt)}
                                      onChange={(e, ds) =>
                                        setSubscriptionEndDt(e, ds)
                                      }
                                    ></ADatePicker>
                                  </Form.Item>
                                ) : null
                              }
                            </Form.Item>
                          </Form.Item>
                        </>
                      ) : (
                        <Form.Item
                          label="Date Range"
                          // style={{ marginBottom: 15 }}
                        >
                          <Row gutter={8}>
                            <Col span={24} style={{ whiteSpace: "nowrap" }}>
                              <Datepicker
                                primaryColor={"blue"}
                                inputClassName="w-full rounded-md focus:ring-0 font-normal bg-grey-100 dark:bg-grey-900 dark:placeholder:text-grey-100"
                                value={dateRangeValue}
                                showShortcuts={true}
                                configs={{
                                  shortcuts: {
                                    today: "Today",
                                    next3Days: {
                                      text: "Next 3 days",
                                      period: {
                                        start: dayjs(),
                                        end: dayjs().add(3, "d"),
                                      },
                                    },

                                    customToday: {
                                      text: "Next 7 Days ",
                                      period: {
                                        start: dayjs(),
                                        end: dayjs().add(7, "d"),
                                      },
                                    },
                                    next15Days: {
                                      text: "Next 15 days",
                                      period: {
                                        start: dayjs(),
                                        end: dayjs().add(15, "d"),
                                      },
                                    },
                                    next230Days: {
                                      text: "Next 30 days",
                                      period: {
                                        start: dayjs(),
                                        end: dayjs().add(30, "d"),
                                      },
                                    },
                                  },
                                }}
                                minDate={dayjs()}
                                maxDate={dayjs().add(1, "y")}
                                startFrom={dayjs()}
                                // disabledDates={[
                                //   {
                                //     startDate: dayjs(),
                                //     endDate: dayjs().add(1, "y"),
                                //   },
                                // ]}
                                showFooter={true}
                                displayFormat={"MM/DD/YYYY"}
                                onChange={handleValueChange}
                              ></Datepicker>
                            </Col>
                          </Row>
                        </Form.Item>
                      )}
                      {/* <Form.Item
                      name="note"
                      label="Note"
                      // style={{ marginTop: "-35px" }}
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item> */}
                    </div>
                    <div style={{ fontWeight: 400, marginTop: "12px" }}>
                      Storage Locker Details
                    </div>
                    <div className="RSFormDiv">
                      <Form.Item
                        noStyle
                        shouldUpdate={
                          (prevValues, currentValues) =>
                            prevValues.unit !== currentValues.unit
                          // prevValues.site_id !== currentValues.site_id
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("unit") == 1 ? ( //"Per Space"
                            <Form.Item
                              name="space_type_id"
                              label="Unit Type"
                              rules={[
                                {
                                  required: true,
                                  message: "Unit Type is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                // value={state.unit}
                                // allowClear
                                style={{ width: "100%" }}
                                placeholder={`Select Unit Type`}
                                className={"filter-select"}
                                maxTagCount={4}
                                // dropdownMatchSelectWidth={780}
                                onChange={handleSpaceType}
                                filterOption={(inputValue, option) => {
                                  return (
                                    option.props.children
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) != -1
                                  );
                                }}
                              >
                                {[
                                  {
                                    space_type_id: -1,
                                    space_type_name: "All",
                                  },
                                  ...getDistinctSpaceTypes(),
                                ].map((item) => (
                                  <Select.Option
                                    key={uuid.v4()}
                                    value={item.space_type_id}
                                  >
                                    {item.space_type_name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={
                          (prevValues, currentValues) =>
                            prevValues.unit !== currentValues.unit
                          // prevValues.site_id !== currentValues.site_id
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("unit") == 1 ? ( //"Per Space"
                            <Form.Item
                              name="space_id"
                              label="Unit"
                              rules={[
                                {
                                  required: true,
                                  message: "Unit is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                // style={{ width: "50vw" }}
                                placeholder="Select a Unit"
                                //style={{ width: "calc(100% - 32px)" }}
                                optionFilterProp="children"
                                dropdownMatchSelectWidth={780}
                                // onSearch={(e) => {
                                //   if (e.length > 0)
                                //     setState({ newSpaceOption: e });
                                // }}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={(e) => {
                                //
                                //   if (e.length > 0) setState({ newSiteOption: e });
                                // }}
                                onChange={handleSpace}
                                filterOption={(input, option) => {
                                  return (
                                    (option.searchText || "")
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                              >
                                {getSpacesForSite(
                                  state.site_id,
                                  state.space_type_id
                                ).map((item) => (
                                  <Select.Option
                                    key={uuid.v4()}
                                    value={item.space_id}
                                    searchText={item.searchText}
                                  >
                                    <span
                                    // style={{
                                    //   color:
                                    //     getUsageCount(item) > 0
                                    //       ? "#e20000"
                                    //       : null,
                                    // }}
                                    >
                                      {printSpaceName(item)}
                                    </span>
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={
                          (prevValues, currentValues) =>
                            prevValues.unit !== currentValues.unit
                          // prevValues.site_id !== currentValues.site_id
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("unit") == 1 ? ( //"Per Space"
                            <>
                              <Form.Item
                                name="vehicle_id"
                                label="Vehicle On File"
                                // style={{ width: "calc(100% - 32px)" }}
                                style={{
                                  // display: "inline-block",
                                  // width: "calc(100% - 100px)",
                                  whiteSpace: "nowrap",
                                  margin: "0 8px 25px",
                                }}
                                rules={[
                                  {
                                    required: false,
                                    // message: "Space is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  style={{ width: "calc(100% - 110px)" }}
                                  placeholder="Select a Vehicle"
                                  optionFilterProp="children"
                                  dropdownMatchSelectWidth={780}
                                  value={state.vehicle_id}
                                  // onFocus={onFocus}
                                  // onBlur={onBlur}
                                  // onSearch={(e) => {
                                  //   if (e.length > 0)
                                  //     setState({ newVehicleOption: e });
                                  // }}
                                  onChange={handleVehicle}
                                  filterOption={(input, option) => {
                                    return (
                                      (option.searchText || "")
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {getVehiclesForUser(
                                    state.invoice_user_id
                                  ).map((item) => (
                                    <Select.Option
                                      key={uuid.v4()}
                                      value={item.vehicle_id}
                                      searchText={item.searchText}
                                    >
                                      <span>{printVehicleName(item)}</span>
                                    </Select.Option>
                                  ))}
                                </Select>
                                <Button
                                  style={{
                                    // fontSize: "18px",
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  // style={{
                                  //   display: "inline-block",
                                  //   width: "calc(30% - 8px)",
                                  //   margin: "0 8px",
                                  // }}
                                  onClick={(e) => {
                                    showAddVehicle();
                                  }}
                                  type="primary"
                                >
                                  {" "}
                                  Add Vehicle
                                </Button>
                              </Form.Item>
                            </>
                          ) : null
                        }
                      </Form.Item>
                    </div>

                    <div style={{ fontWeight: 400, marginTop: "12px" }}>
                      Amount
                    </div>
                    <div className="RSFormDiv">
                      <Form.Item
                        name="unit"
                        label="Unit"
                        hidden={true}
                        rules={[
                          {
                            required: true,
                            message: "Unit is required",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={handleUnit}
                          value={state.unit}
                          buttonStyle="solid"
                          //style={{ backgroundColor: "whitesmoke" }}
                          // optionType="button"
                          className={"ant-radio-inline-flex"}
                        >
                          <Space direction="horizontal" wrap>
                            {(props.referenceData.unitTypes || [])
                              .filter(
                                (rec) =>
                                  rec.id.toString() != "12" &&
                                  rec.id.toString() != "13" //12 -- Deposit, 13 -- Payment On Account
                              )
                              .map((item) => (
                                <>
                                  <Radio value={item.id}>{item.name}</Radio>
                                </>
                              ))}
                          </Space>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.unit !== currentValues.unit
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("unit") == 1 ? null : ( //"Per Space"
                            <Form.Item
                              name="item"
                              label="Item Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Item Name is required",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          )
                        }
                      </Form.Item>

                      {/* <Form.Item
                        name="setupFeeFormatted"
                        label="Move In Fee"
                        // className="quantity"
                        // style={{ marginTop: "-25px" }}
                        // extra={
                        //   state.createSubscription == "Y"
                        //     ? `Monthly includes first and last month`
                        //     : null
                        // }
                        rules={[
                          {
                            required: true,
                            message: "Move In Fee is required",
                          },
                        ]}
                      >
                        <InputNumber
                          className="is-disabled-normal"
                          bordered={false}
                          // style={{ fontWeight: 700 }}
                          min="0"
                          max="9999"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          precision="2"

                          // onChange={(val) =>
                          //   setState({
                          //     renderVersion: state.renderVersion + 1,
                          //   })
                          // }
                        />
                      </Form.Item> */}

                      <Form.Item
                        label="Rent"
                        rules={[
                          {
                            required: true,
                            message: "Rent is required",
                          },
                        ]}
                      >
                        <Form.Item
                          name="price"
                          rules={[
                            {
                              required: false,
                              message: "Rent is required",
                            },
                          ]}
                          // extra={
                          //   state.createSubscription == "Y"
                          //     ? `Monthly Rate ${state.price}`
                          //     : null
                          // }
                          style={{ display: "inline-block", width: "250px" }}
                        >
                          <InputNumber
                            className="is-disabled-normal"
                            min="-9999"
                            max="9999"
                            bordered={false}
                            // onChange={() =>
                            //   setState({
                            //     renderVersion: state.renderVersion + 1,
                            //   })
                            // }
                            style={{
                              fontWeight: 700,
                              color: "green",
                              width: "250px",
                            }}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            precision="2"
                          />
                        </Form.Item>
                        {/* {state.unit == 1 && state.createSubscription != "Y" && (
                          <span
                            style={{
                              display: "inline-block",
                              width: "calc(50% - 8px)",
                              margin: "0 8px",
                            }}
                          >
                            {!isNil(state.subscription_start_dt) &&
                              !isNil(state.subscription_end_dt) &&
                              dayjs(
                                state.subscription_start_dt,
                                "MM/DD/YYYY"
                              ) != "01/01/1900" && (
                                <div
                                  className={
                                    props.isMobile
                                      ? "priceExplanation-mobile"
                                      : "priceExplanation"
                                  }
                                >
                                  {getPriceContent()}
                                </div>
                              )}
                          </span>
                        )} */}
                      </Form.Item>
                      <Form.Item
                        name="tax_amount"
                        label="Tax Amount"
                        extra={`Sales Tax ${state.tax_rate}%`}
                        // valuePropName="checked"
                        // style={{ display: "inline-block" }}
                        // onChange={onTaxChange}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        //Disable Tax when it's a deposit
                      >
                        <InputNumber
                          className="is-disabled-normal"
                          bordered={false}
                          // style={{ fontWeight: 700 }}
                          min="0"
                          max="9999"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          precision="2"
                          // onChange={(val) =>
                          //   setState({
                          //     renderVersion: state.renderVersion + 1,
                          //   })
                          // }
                        />
                        {/* <Checkbox
                          className="is-disabled-normal"
                          // disabled={state.unit.toString() == "12"}
                        /> */}
                      </Form.Item>

                      {/* <Form.Item
                      name="quantity"
                      label="Quantity"
                      className="quantity"
                      style={{ marginTop: "-25px" }}
                      // extra={
                      //   state.createSubscription == "Y"
                      //     ? `Monthly includes first and last month`
                      //     : null
                      // }
                      rules={[
                        {
                          required: true,
                          message: "Quantity is required",
                        },
                      ]}
                    >
                      <InputNumber
                        className="is-disabled-normal"
                        bordered={false}
                        // style={{ fontWeight: 700 }}
                        min="0"
                        max="9999"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision="2"
                        // onChange={(val) =>
                        //   setState({
                        //     renderVersion: state.renderVersion + 1,
                        //   })
                        // }
                      />
                    </Form.Item> */}
                      {/* <Form.Item label="Taxes" className="reserve-name"> */}

                      <Form.Item
                        name="proratedRent"
                        label="Prorated Rent"
                        className="quantity"
                        style={{ marginTop: "-25px" }}
                        extra={
                          <>
                            {(state.prorateOption == 2 ||
                              state.prorateOption == 3) && (
                              <Row>
                                <Col span="12">Rent</Col>
                                <Col span="12">
                                  {formatNumber(state.subscription_total)}
                                </Col>
                              </Row>
                            )}
                            {(state.prorateOption == 3 ||
                              state.prorateOption == 4) && (
                              <Row>
                                <Col span="12">Prorated Rent</Col>
                                <Col span="12">
                                  {formatNumber(
                                    state.prorateAmount
                                    // getFieldValue("prorateAmount")
                                  )}
                                </Col>
                              </Row>
                            )}

                            {state.setupFee && state.setupFee > 0 ? (
                              <Row>
                                <Col span="12">Deposit</Col>
                                <Col span="12">
                                  {formatNumber(state.setupFee)}
                                </Col>
                              </Row>
                            ) : null}
                            <Row>
                              <Col span="12">Total Due</Col>
                              <Col span="12">
                                {formatNumber(unformat(state.total_reserve))}
                              </Col>
                            </Row>
                            <Row>
                              <Col span="12">
                                {`Amount due every ${dayjs(
                                  state.prorateSubStartDt
                                ).format("DD")} of the month starting 
                                            ${state.prorateSubStartDt}`}
                              </Col>
                              <Col span="12">
                                {formatNumber(state.subscription_total)}
                              </Col>
                            </Row>
                          </>
                        }
                        rules={[
                          {
                            required: false,
                            message: "Prorated Rent is required",
                          },
                        ]}
                      >
                        <InputNumber
                          className="is-disabled-normal"
                          bordered={false}
                          // style={{ fontWeight: 700 }}
                          min="0"
                          max="9999"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          precision="2"
                          // onChange={(val) =>
                          //   setState({
                          //     renderVersion: state.renderVersion + 1,
                          //   })
                          // }
                        />
                      </Form.Item>

                      {/* <span
                        className="ant-form-text"
                        style={{
                          verticalAlign: "middle",
                          marginTop: "3px",
                          marginLeft: "8px",
                        }}
                      >
                        <span>Sales Tax {state.tax_rate}%</span>
                      </span> */}
                      {/* </Form.Item> */}
                      <Form.Item
                        name="total_reserve"
                        label="Total Amount"
                        className="quantity"
                        // style={{ marginTop: "-25px" }}
                        rules={[
                          {
                            required: true,
                            message: "Total is required",
                          },
                        ]}
                      >
                        <InputNumber
                          className="is-disabled-normal"
                          bordered={false}
                          // style={{ fontWeight: 700 }}
                          min="0"
                          max="9999"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          precision="2"
                        />
                      </Form.Item>
                      <Form.Item label="mode" name="mode" noStyle hidden={true}>
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        label="Tax Rate"
                        name="tax_rate"
                        noStyle
                        hidden={true}
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        label="explanation"
                        name="explanation"
                        noStyle
                        hidden={true}
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                    <div style={{ fontWeight: 400, marginTop: "12px" }}>
                      Payment Details
                    </div>
                    <div className="RSFormDiv">
                      <Form.Item
                        name="cardOption"
                        label="Payment"
                        rules={[
                          {
                            required: true,
                            message: "Payment is required",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={onCardOptionChange}
                          value={state.cardOption}
                          buttonStyle="solid"
                          //style={{ backgroundColor: "whitesmoke" }}
                          // optionType="button"
                          className={"ant-radio-inline-flex"}
                        >
                          <Space direction="horizontal" wrap>
                            <Radio value={1}>Payment Methods on File</Radio>

                            <Radio value={8}>
                              {" "}
                              Manually Enter Card / ACH / eCheck
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.cardOption !== currentValues.cardOption
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("cardOption") == "1" ? ( //"Per Space"
                            <Form.Item
                              name="card_id"
                              labelWrap
                              label="Saved Payment Method"
                              rules={[
                                {
                                  required: true,
                                  message: "Payment is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: "500px", maxWidth: "100%" }}
                                placeholder="Select a saved Card"
                                optionFilterProp="children"
                                //dropdownMatchSelectWidth={780}
                                onChange={handleCard}
                                value={state.card_id}
                                filterOption={(input, option) => {
                                  return (
                                    (option.searchText || "")
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                              >
                                {(props.referenceData.cards || [])
                                  .filter(
                                    (itm) =>
                                      itm.user_id_fk == state.invoice_user_id
                                  )
                                  .map((item) => (
                                    <Select.Option
                                      key={uuid.v4()}
                                      value={item.card_id}
                                      searchText={item.searchText}
                                    >
                                      <span
                                        style={{
                                          color:
                                            item.defaultCard == "Y"
                                              ? "#464d69"
                                              : null,
                                          fontWeight:
                                            item.defaultCard == "Y"
                                              ? 600
                                              : null,
                                        }}
                                      >
                                        {printCard(item)}
                                      </span>
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </div>
                    <div style={{ fontWeight: 400, marginTop: "12px" }}>
                      By clicking on reserve, you are agreeing to{" "}
                      <a style={{ color: "#8009d9b8" }}>
                        LincolnLand Terms and conditions
                      </a>
                      , <a style={{ color: "#8009d9b8" }}>Cancelation policy</a>{" "}
                      and <a style={{ color: "#8009d9b8" }}>Lease</a>.
                    </div>
                  </div>
                  <div className={"pn-modal-footer"}>
                    <Row style={{ marginTop: "8px" }}>
                      <Col span={24} style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginRight: "8px", width: "100px" }}
                          onClick={(e) =>
                            setState({
                              saveReserveVersion: state.saveReserveVersion + 1,
                            })
                          }
                        >
                          Reserve
                        </Button>
                        <Button
                          htmlType="button"
                          style={{ width: "100px" }}
                          onClick={onReserveDrawerClose}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>

                {/* <List.Item.Meta
                    title={
                      <Paragraph
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          lineHeight: 1.35,
                          marginBottom: 0,
                        }}
                        level={3}
                      >
                        Reservation Details
                      </Paragraph>
                    }
                  />
                </List.Item>                   
                <List.Item>
                  <div
                    style={{
                      flexBasis: "30%",
                      textAlign: "right",
                      paddingRight: 20,
                    }}
                  >
                    <Typography.Paragraph
                      style={{
                        textTransform: "uppercase",
                        marginBottom: 0,
                      }}
                    >
                      Site:
                    </Typography.Paragraph>
                  </div>
                  <List.Item.Meta description={"350 Berlin Crosskeys"} />
                </List.Item>
                <List.Item>
                  <div
                    style={{
                      flexBasis: "30%",
                      textAlign: "right",
                      paddingRight: 20,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography.Paragraph
                      style={{
                        textTransform: "uppercase",
                        marginBottom: 0,
                      }}
                    >
                      Start Dt:
                    </Typography.Paragraph>
                  </div>
                  <List.Item.Meta description={<ADatePicker />} />
                </List.Item>
                <List.Item>
                  <div
                    style={{
                      flexBasis: "30%",
                      textAlign: "right",
                      paddingRight: 20,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography.Paragraph
                      style={{
                        textTransform: "uppercase",
                        marginBottom: 0,
                      }}
                    >
                      End Dt:
                    </Typography.Paragraph>
                  </div>
                  <List.Item.Meta description={<ADatePicker />} />
                </List.Item>

                <List.Item>
                  <div
                    style={{
                      flexBasis: "30%",
                      textAlign: "right",
                      paddingRight: 20,
                    }}
                  >
                    <Typography.Paragraph
                      style={{
                        textTransform: "uppercase",
                        marginBottom: 0,
                      }}
                    >
                      Space:
                    </Typography.Paragraph>
                  </div>
                  <List.Item.Meta description={"Entry Gate"} />
                </List.Item>
              </List>

              <Title style={{ fontSize: 16, marginBottom: 12 }} level={3}>
                Payment Details
              </Title>
              <List style={{ marginBottom: 20 }}>
                <Radio.Group
                  onChange={onCardOptionChange}
                  value={state.cardOption}
                  buttonStyle="solid"
                  // disabled={isNil(state.invoice_user_id)}
                >
                  <Space direction="vertical">
                    <Radio value={1}>Payment Methods on File</Radio>

                    <Radio value={8}>
                      {" "}
                      Manually Enter Card / ACH / eCheck
                    </Radio>
                  </Space>
                </Radio.Group>
              </List>
              <Divider />   */}
              </div>
              {/* <div
              style={{
                backgroundColor: "#464d69",
                color: "white",
                lineHeight: "21px",
                fontWeight: 600,
                padding: "12px",
                fontSize: "21px",
              }}
            >
              Payment Details
            </div>
            <div>
              <Radio.Group
                onChange={onCardOptionChange}
                value={state.cardOption}
                buttonStyle="solid"
                // disabled={isNil(state.invoice_user_id)}
              >
                <Space direction="vertical">
                  <Radio value={1}>Payment Methods on File</Radio>

                  <Radio value={8}> Manually Enter Card / ACH / eCheck</Radio>
                </Space>
              </Radio.Group>
            </div> */}
            </div>
          </Drawer>
          <Drawer
            title={
              <>
                <CalendarOutlined style={{ marginRight: "5px" }} /> Reserve
              </>
            }
            placement="right"
            closable={true}
            onClose={onReserveSummaryDrawerClose}
            open={state.showSummary}
            width={780}
            // getContainer={false}
            style={{
              padding: 0,
            }}
            contentWrapperStyle={{
              maxWidth: "96%",
              borderTop: "12px solid #ff932a",
            }}
          >
            <div
              style={{
                display: "flex",
                minWidth: 0,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#464d69",
                  color: "white",
                  lineHeight: "21px",
                  fontWeight: 600,
                  padding: "12px",
                  fontSize: "21px",
                }}
              >
                Reservation Confirmation
              </div>
              <div
                style={{
                  // height: "100%",
                  padding: "20px 24px",
                  overflowY: "auto",
                }}
              >
                <Form
                  //{...layout}
                  {...layoutCol}
                  ref={formRef}
                  form={formUseRef}
                  // ref={formRef}
                  id="dataEntryDiv"
                  // name="control-ref"
                  // onFinish={onFinishItem}
                  initialValues={{
                    site_id: state.site_id,
                    space_id: state.space_id,
                    space_type_id: state.space_type_id,
                    item: state.item,
                    unit: state.unit, //1
                    note: state.note,
                    price: state.price,
                    quantity: state.quantity,
                    setupFee: state.setupFee,
                    tax: state.tax,
                    tax_rate: state.tax_rate,
                    vehicle_id: state.vehicle_id,
                    subscription_start_dt: dayjs(state.subscription_start_dt),
                    subscription_end_dt: dayjs(state.subscription_end_dt),
                    explanation: state.explanation,
                    user_id: state.user_id,
                    name: state.name,
                    site_name: state.site_name,
                    total_reserve: state.total_reserve,
                    tax_amount: state.tax_amount,
                    // explanation: computeExplanation({
                    //   subscription_start_dt: dayjs(state.subscription_start_dt),
                    //   subscription_end_dt: dayjs(state.subscription_end_dt),
                    //   site_id: state.site_id,
                    //   space_id: state.space_id,
                    // }),
                  }}
                >
                  <div className={"pn-modal-body"}>
                    <div style={{ fontWeight: 400, marginTop: "12px" }}>
                      Details
                    </div>
                    <div className="RSFormDiv">
                      <div style={{ fontWeight: 400 }}>Reservation Details</div>
                      <Descriptions
                        bordered
                        layout={
                          state.windowSize.width < 576
                            ? "vertical"
                            : "horizontal"
                        }
                        column={1}
                        className={"table-layout-fixed"}
                        labelStyle={{ width: "150px" }}
                        label={"Reservation Details"}
                      >
                        <Descriptions.Item label="Customer">
                          {state.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Site">
                          {state.site_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Date Range">
                          {`${dayjs(state.subscription_start_dt).format(
                            "MM/DD/YYYY"
                          )} ~ ${dayjs(state.subscription_end_dt).format(
                            "MM/DD/YYYY"
                          )}`}
                        </Descriptions.Item>
                        {!isNil(state.note) && state.note != "" ? (
                          <Descriptions.Item label="Note">
                            {`${state.note}`}
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>

                      <div style={{ fontWeight: 400 }}>Unit Details</div>
                      <Descriptions
                        bordered
                        layout={
                          state.windowSize.width < 576
                            ? "vertical"
                            : "horizontal"
                        }
                        column={1}
                        className={"table-layout-fixed"}
                        labelStyle={{ width: "150px" }}
                        label={"Space Details"}
                      >
                        <Descriptions.Item label="Space#">
                          {printSpaceName(state.spaceRecord)}
                        </Descriptions.Item>
                        {state.vehicleRecord ? (
                          <Descriptions.Item label="Vehicle">
                            {state.vehicleRecord
                              ? printVehicleName(state.vehicleRecord)
                              : ""}
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>
                      <div style={{ fontWeight: 400 }}>Amount Details</div>
                      <Descriptions
                        bordered
                        layout={
                          state.windowSize.width < 576
                            ? "vertical"
                            : "horizontal"
                        }
                        column={1}
                        className={"table-layout-fixed"}
                        labelStyle={{ width: "150px" }}
                        label={"Amount Details"}
                      >
                        <Descriptions.Item label="Price">
                          {state.price}
                        </Descriptions.Item>
                        <Descriptions.Item label="Quantity">
                          {state.quantity}
                        </Descriptions.Item>

                        <Descriptions.Item label="Move In Fee">
                          {state.setupFee}
                        </Descriptions.Item>

                        <Descriptions.Item label="Tax Amount">
                          {state.tax_amount}
                        </Descriptions.Item>

                        <Descriptions.Item label="Sales Tax">
                          {`${state.tax_rate}%`}
                        </Descriptions.Item>

                        <Descriptions.Item label="Total Amount">
                          {`${state.total_reserve}`}
                        </Descriptions.Item>
                      </Descriptions>
                      <div style={{ fontWeight: 400 }}>Payment Details</div>
                      <Descriptions
                        bordered
                        layout={
                          state.windowSize.width < 576
                            ? "vertical"
                            : "horizontal"
                        }
                        column={1}
                        className={"table-layout-fixed"}
                        labelStyle={{ width: "150px" }}
                        label={"Payment Details"}
                      >
                        <Descriptions.Item label="Amount Paid">
                          {state.total_reserve}
                        </Descriptions.Item>

                        <Descriptions.Item label="Date Of Transaction">
                          {dayjs().format("MM/DD/YYYY")}
                        </Descriptions.Item>
                        {state.activeRecord ? (
                          <>
                            <Descriptions.Item label="Payment Type">
                              {`${state.activeRecord?.payment_type.toUpperCase()} `}
                            </Descriptions.Item>

                            <Descriptions.Item label="Payment Source">
                              {`${state.activeRecord?.payment_source.toUpperCase()}`}
                            </Descriptions.Item>

                            <Descriptions.Item label="Payment Method">
                              {state.activeRecord?.card_brand
                                ? state.activeRecord?.card_brand.toUpperCase()
                                : ""}{" "}
                              -{state.activeRecord?.last_4}
                            </Descriptions.Item>
                          </>
                        ) : (
                          <Descriptions.Item label="Payment Source">
                            <span>
                              Customer Credit on Account was used for the
                              reservation
                            </span>
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    </div>
                    <div style={{ fontWeight: 400, marginTop: "12px" }}>
                      Instructions
                    </div>
                    <div className="RSFormDiv">
                      <div style={{ fontWeight: 700 }}>
                        This Location has LincolnLand automated access control,
                        Simply approach the gate and tap "Open Gate" on your
                        dashboard, or use the email link that was sent to your
                        email.
                      </div>
                      {/* <div>
                      You can download the mobile app for ease of use.
                      <div style={{ whiteSpace: "nowrap" }}>
                        <Button
                          size="large"
                          style={{ margin: "4px" }}
                          href="https://play.google.com/store/apps/details?id=com.parknation"
                          icon={<GoogleOutlined />}
                        ></Button>
                        <Button
                          size="large"
                          style={{ margin: "4pxpx" }}
                          href="https://apps.apple.com/in/app/parknation/id1632802050"
                          icon={<AppleOutlined />}
                        ></Button>
                        
                      </div>
                    </div> */}
                    </div>
                  </div>
                  <div className={"pn-modal-footer"}>
                    <Row style={{ marginTop: "8px" }}>
                      <Col span={24} style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginRight: "8px", width: "100px" }}
                          // onClick={(e) =>
                          //   setState({
                          //     saveReserveVersion: state.saveReserveVersion + 1,
                          //   })
                          // }
                        >
                          Print
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginRight: "8px", width: "100px" }}
                          // onClick={(e) =>
                          //   setState({
                          //     saveReserveVersion: state.saveReserveVersion + 1,
                          //   })
                          // }
                        >
                          E-mail
                        </Button>
                        <Button
                          htmlType="button"
                          style={{ width: "100px" }}
                          onClick={onReserveSummaryDrawerClose}
                        >
                          Close
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </div>
          </Drawer>
          {/* <Drawer
          title={
            <>
              <CalendarOutlined style={{ marginRight: 5 }} /> Reservation
              Details
            </>
          }
          placement="right"
          closable={true}
          onClose={onReserveSummaryDrawerClose}
          open={state.showSummary}
          width={780}
          // getContainer={false}
          bodyStyle={{
            padding: 0,
          }}
          contentWrapperStyle={{
            maxWidth: "96%",
            borderTop: "12px solid #ff932a",
          }}
        >
          <div
            style={{
              display: "flex",
              minWidth: 0,
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "#464d69",
                color: "white",
                lineHeight: "21px",
                fontWeight: 600,
                padding: "12px",
                fontSize: "21px",
              }}
            >
              Reservation Details
            </div>
            <div
              style={{
                // height: "100%",
                padding: "20px 24px",
                overflowY: "auto",
              }}
            >
              {" "}
              <div
                style={{ width: "100%", height: "100%" }}
                // key={state.renderVersion}
              >
                <Card
                  bordered={false}
                  className={"p-0 card-head-wrap"}
                  title={
                    <Text
                      level={3}
                      style={{ fontSize: "22px", fontWeight: 500 }}
                    >{`Payment Details - ${state.payment_id}`}</Text>
                  }
                  extra={
                    <div className={"invoice-detail-extra"}>
                      <div className={"invoice-detail-amount"}>
                        <Statistic
                          // title="Customer Balance(Including current invoice)"
                          title="Payment Amount"
                          value={state.activeRecord?.total_amount}
                          // value={
                          //   state.invoiceTotal -
                          //   state.invoicePaidAmount
                          // }
                          precision={2}
                          valueStyle={{
                            whiteSpace: "nowrap",
                            color: "#008A25",
                            width: "110px",
                          }}
                          suffix={<DollarOutlined />}
                          style={{
                            display: "inline-block",
                            marginRight: "16px",
                          }}
                        />
                      </div>

                      <div className={"invoice-detail-action"}>
                        <Button
                          size="medium"
                          type="primary"
                          // ghost
                          // className="btn btn-sm btn-outline btn-secondary"
                          style={{ marginRight: "8px" }}
                          onClick={(e) => emailCharge()}
                        >
                          Email
                        </Button>
                        <Button
                          size="medium"
                          // type="dashed"
                          className="btn-secondary"
                          style={{ marginRight: "8px" }}
                          onClick={(e) => printPayment()}
                        >
                          Print
                        </Button>
                        <Button
                          size="medium"
                          type="dashed"
                          // style={{ width: "100px" }}
                          onClick={(e) => cancelPayment()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <Descriptions
                    title="Customer Information"
                    bordered
                    layout={
                      state.windowSize.width < 576 ? "vertical" : "horizontal"
                    }
                    column={1}
                    className={"table-layout-fixed"}
                    labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item
                      label={
                        <span>
                          <span
                            style={{
                              position: "relative",
                              top: "-3px",
                              left: "-9px",
                              color: "#e20000",
                            }}
                          >
                            *
                          </span>
                          <span
                            style={{
                              position: "relative",
                              left: "-7px",
                            }}
                          >
                            Customer
                          </span>
                        </span>
                      }
                    >
                      {state.invoice_user_id
                        ? printCustName(
                            props.referenceData.users.find(
                              (rec) => rec.user_id == state.invoice_user_id
                            )
                          )
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <span>
                          <span
                            style={{
                              position: "relative",
                              top: "-3px",
                              left: "-9px",
                              color: "#e20000",
                            }}
                          >
                            *
                          </span>
                          <span
                            style={{
                              position: "relative",
                              left: "-7px",
                            }}
                          >
                            Site 
                          </span>
                        </span>
                      }
                    >
                      {state.site_id
                        ? printSiteName(
                            state.sites.find(
                              (rec) => rec.site_id == state.site_id
                            )
                          )
                        : ""}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions
                    title="Payment Details"
                    bordered
                    layout={
                      state.windowSize.width < 576 ? "vertical" : "horizontal"
                    }
                    column={1}
                    className={"table-layout-fixed"}
                    labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item label="Active">
                      <span>
                        <Switch
                          checked={
                            state.activeRecord?.active &&
                            !isNil(state.activeRecord?.active)
                              ? true
                              : false
                          }
                          // onChange={onStatusChange}
                        ></Switch>
                      </span>
                    </Descriptions.Item>

                    <Descriptions.Item label="Payment ID">
                      {state.payment_id}
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span
                            style={{
                              position: "relative",
                              top: "-3px",
                              left: "-9px",
                              color: "#e20000",
                            }}
                          >
                            *
                          </span>
                          <span
                            style={{
                              position: "relative",
                              left: "-7px",
                            }}
                          >
                            Date of Transaction
                          </span>
                        </span>
                      }
                    >
                      {dayjs(state.updated_at).format("MM/DD/YYYY")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Amount Paid">
                      {formatNumber(state.activeRecord?.total_amount)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Note">
                      {state.activeRecord?.note}
                    </Descriptions.Item>

                    <Descriptions.Item label="Payment Type">
                      {state.activeRecord?.payment_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Payment Source">
                      {state.activeRecord?.payment_source}
                    </Descriptions.Item>
                    <Descriptions.Item label="Payment Method">
                      {state.activeRecord?.card_brand
                        ? state.activeRecord?.card_brand.toUpperCase()
                        : ""}{" "}
                      -{state.activeRecord?.last_4}
                    </Descriptions.Item>
                    {state.activeRecord?.check_number && (
                      <Descriptions.Item label="Check#">
                        {state.activeRecord?.check_number}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </Card>
              </div>
            </div>
          </div>
        </Drawer> */}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  console.log("State", state);
  const siteData = getSiteDataSelector(state);
  const referenceData = getReferenceDataSelector(state);
  const sessionData = getSessionDataSelector(state);
  const isMobile = getMobileDeviceSelector(state);
  // const vehiclesData = getVehicleDataSelector(state);
  // const user = getUserDataSelector(state);
  // const refData = getReferenceDataSelector(state);
  // const usersData = getUsersDataSelector(state);
  return {
    siteData,
    referenceData,
    isMobile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSitesData: (payload) => {
      debugger;
      //   dispatch(InvoiceA(userId, token));
      console.log("dispatch getSitesData");
      console.log("dispatch getSitesData payload", payload);
      dispatch(actionActions.getSitesData(payload));
    },
    refreshReferenceData: (payload) => {
      debugger;
      //   dispatch(InvoiceA(userId, token));
      console.log("dispatch refreshReferenceData");
      console.log("dispatch refreshReferenceData payload", payload);
      dispatch(actionActions.refreshReferenceData(payload));
    },
    insertUpdateVehicle: (payload) => {
      debugger;
      //   dispatch(InvoiceA(userId, token));
      console.log("dispatch insertUpdateVehicle");
      console.log("dispatch insertUpdateVehicle payload", payload);
      dispatch(actionActions.insertUpdateVehicle(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM",
  })(FindLocation)
);
