import React from "react";
import DataSecurityImg from "../../../assets/images/jpg/DataSecurity.jpg";
import { Link } from "react-router-dom";
const DataSecurity = () => {
  return (
    <>
      <section className='my-12 my:my-20 lg:my-28 xl:my-[150px] overflow-x-hidden'>
        <div className='container'>
          <div className='flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center justify-between'>
            <div data-aos='fade-right' data-aos-duration='1500'>
              <div className='max-w-[418px]'>
                <img className='w-full' src={DataSecurityImg} alt='Integrate' />
              </div>
            </div>
            <div data-aos='fade-left' data-aos-duration='1500'>
              <div className='max-w-[558px]'>
                <h2 className='text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4'>
                  Data Security{" "}
                </h2>
                <p className='text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12'>
                  Storage units often come with built-in security features such
                  as encryption, access controls, and data redundancy to protect
                  sensitive information from unauthorized access or data
                  breaches.
                </p>
                <Link
                  to='/account-sign-up'
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className='p-[8px_16px]  inline-block sm:p-[14px_26px] font-semibold first_btn whitespace-nowrap font-Manrope text-base'
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataSecurity;
