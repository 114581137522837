import React, { useRef } from "react";
import track1 from "../../../assets/images/webp/search_track1.webp";
import track2 from "../../../assets/images/webp/search_track2.webp";

import { LeftArrowRed, RightArrowRed } from "../../common/Icon";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import { LocationInformationsData } from "../../common/Helper";
import { Link } from "react-router-dom";

const Search = () => {
  const slider = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    centerMode: true,
    centerPadding: "60px",
    arrows: false,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className=" container lg:mt-[100px]">
        <div className="flex justify-between w-full flex-col lg:flex-row">
          <div className="lg:w-[47%] my-10 lg:my-0">
            <div className="flex items-center w-full justify-between lg:pr-[40px]">
              <p className="font-normal text-[18px] lg:text-[22px] text-[#020202] font-Poppins">
                USA 2343
              </p>
              <p className="font-semibold text-[18px] lg:text-[22px] text-[#EB2022] font-Poppins">
                $40.00
              </p>
            </div>
            <div className="relative ">
              <div
                className="absolute left-0 top-1/2 -translate-y-1/2 cursor-pointer z-10"
                onClick={() => slider.current.slickPrev()}
              >
                <LeftArrowRed />
              </div>
              <div className="mt-[30px] px-[45px] ">
                <Slider ref={slider} {...settings}>
                  <div className="overflow-hidden rounded-lg md:!h-[188px] ">
                    <img
                      className="px-2 hover:scale-105 duration-300 xs:w-full xs:h-full"
                      src={track1}
                      alt="haighway"
                    />
                  </div>
                  <div className="overflow-hidden rounded-lg md:!h-[188px]">
                    <img
                      className="px-2 hover:scale-105 duration-300 xs:w-full xs:h-full"
                      src={track2}
                      alt="haighway"
                    />
                  </div>
                  <div className="overflow-hidden rounded-lg md:!h-[188px]">
                    <img
                      className="px-2 hover:scale-105 duration-300 xs:w-full xs:h-full"
                      src={track1}
                      alt="haighway"
                    />
                  </div>
                  <div className="overflow-hidden rounded-lg md:!h-[188px]">
                    <img
                      className="px-2 hover:scale-105 duration-300 xs:w-full xs:h-full"
                      src={track2}
                      alt="haighway"
                    />
                  </div>
                </Slider>
              </div>
              <div
                className="absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer"
                onClick={() => slider.current.slickNext()}
              >
                <RightArrowRed />
              </div>
            </div>
            <p className="font-Poppins font-semibold text-[18px] md:text-md lg:text-lg mt-[31px] mb-4 text-[#020202]">
              Description
            </p>
            <p className="font-normal font-Poppins opacity-70 text-base">
              Lorem ipsum dolor sit amet consectetur. Dignissim sit euismod
              dictumst id duis felis sed. Hendrerit Lorem ipsum dolor sit amet
            </p>
            <div className="bg-[#F8F8F8] mt-[30px] p-[18px]">
              <p className="font-semibold font-Poppins text-[#020202]  text-[18px] md:text-md lg:text-lg">
                Location Features
              </p>
              <div className=" grid sm:grid-cols-2 gap-x-6">
                {LocationInformationsData.map((item, index) => {
                  return (
                    <div
                      className="lg:max-w-[219px] flex items-start mt-4"
                      key={index}
                    >
                      <span>{item.icon}</span>
                      <p className="font-normal font-Poppins text-base text-[#020202] ml-2">
                        {item.heading}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="lg:hidden">
              <p className="font-semibold font-Poppins mt-[30px] text-[18px] md:text-md lg:text-lg text-[#020202]">
                Prices
              </p>
              <div className="flex ">
                <div className="mt-4">
                  <p className="font-semibold text-[#020202] text-base font-Poppins">
                    Type
                  </p>
                  <p className="mt-4 font-Poppins font-normal text-base text-[#020202]">
                    Container/Chassis (20'/40'/45')
                  </p>
                  <p className="mt-4 font-Poppins font-normal text-base text-[#020202]">
                    Heavy Truck
                  </p>
                  <p className="mt-4 font-Poppins font-normal text-base text-[#020202]">
                    Ev Charging
                  </p>
                </div>
                <div className="mt-4 ms-[20px] sm:ms-[64px] ">
                  <p className="font-semibold text-[#020202] text-base font-Poppins">
                    Price/day
                  </p>
                  <p className="mt-4 font-Poppins font-normal text-base text-[#EB2022]">
                    $40.00
                  </p>
                  <p className="mt-4 font-Poppins font-normal text-base text-[#EB2022]">
                    $50.00
                  </p>
                  <p className="mt-4 font-Poppins font-normal text-base text-[#EB2022]">
                    $70.00
                  </p>
                </div>
              </div>
              <Link
                to="/contact-us"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="px-[41px] py-[14px] first_btn font-Manrope text-base font-semibold  mt-[53px] inline-block"
              >
                Book Now
              </Link>
            </div>
          </div>
          <div className="w-full lg:w-[50%] mx-auto lg:mr-auto">
            <div className="border-[5px] sm:border-[10px] border-[#D4D6DB] h-[500px] lg:h-full rounded-[24px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11207.71096584784!2d-74.15525973265272!3d40.771082038444675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c256a1131334c9%3A0xd29d74da8b90a6e7!2sKearny%2C%20NJ%2C%20USA!5e0!3m2!1sen!2sin!4v1683604031625!5m2!1sen!2sin"
                width="600"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                className="w-full !h-full"
                style={{ border: "0", borderRadius: "12px" }}
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
        <div className="hidden lg:block mb-10">
          <p className="font-semibold font-Poppins mt-[30px] text-[18px] md:text-md lg:text-lg text-[#020202]">
            Prices
          </p>
          <div className="flex ">
            <div className="mt-4">
              <p className="font-semibold text-[#020202] text-base font-Poppins">
                Type
              </p>
              <p className="mt-4 font-Poppins font-normal text-base text-[#020202]">
                Container/Chassis (20'/40'/45')
              </p>
              <p className="mt-4 font-Poppins font-normal text-base text-[#020202]">
                Heavy Truck
              </p>
              <p className="mt-4 font-Poppins font-normal text-base text-[#020202]">
                Ev Charging
              </p>
            </div>
            <div className="mt-4 ms-[20px] sm:ms-[64px] ">
              <p className="font-semibold text-[#020202] text-base font-Poppins">
                Price/day
              </p>
              <p className="mt-4 font-Poppins font-normal text-base text-[#EB2022]">
                $40.00
              </p>
              <p className="mt-4 font-Poppins font-normal text-base text-[#EB2022]">
                $50.00
              </p>
              <p className="mt-4 font-Poppins font-normal text-base text-[#EB2022]">
                $70.00
              </p>
            </div>
          </div>
          <Link
            to="/contact-us"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className="px-[41px] py-[14px] first_btn font-Manrope text-base font-semibold  mt-[53px] inline-block "
          >
            Book Now
          </Link>
        </div>
      </div>
    </>
  );
};

export default Search;
