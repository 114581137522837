import site1 from "../../assets/images/webp/sitecard.webp";
import star from "../../assets/images/webp/star.webp";
import blog1 from "../../assets/images/webp/blog1.webp";
import blog2 from "../../assets/images/webp/blog2.webp";
import blog3 from "../../assets/images/webp/blog3.webp";
import testimonials2 from "../../assets/images/webp/testimonials2.webp";
import testimonials3 from "../../assets/images/webp/testimonials3.webp";
import happyMan from "../../assets/images/webp/happy-young-man.webp";
import handsomeGuy from "../../assets/images/webp/handsome-bearded-guy.webp";
import africanMan from "../../assets/images/webp/african-american-man.webp";
import testimonials1 from "../../assets/images/webp/testimonials1.webp";
import facts_slider_img1 from "../../assets/images/webp/facts_slider_img1.webp";
import facts_slider_img2 from "../../assets/images/webp/facts_slider_img2.webp";
import facts_slider_img3 from "../../assets/images/webp/facts_slider_img3.webp";

import {
  Accounting,
  AppBased,
  Arrangement,
  CameraIcon,
  Cameras,
  CarIcon,
  CargoSafety,
  ConvenientParking,
  CostControl,
  DataRecordIcon,
  DigitalPayment,
  EffectiveRoute,
  EvCharging,
  FlexiblePayment,
  FuelStation,
  FuelStation2,
  FullProtectionIcon,
  FullSecure,
  InstallCamerEverywhere,
  LargeParking,
  MechanicsAvailable,
  MonthlyPlan,
  PaymentIcon,
  PaymentMethodIcon,
  Personalization,
  PrivacyIcon,
  ProvideEvChargingPoint,
  ProvideFullSecurity,
  PushNotification,
  Registeration,
  Reserve,
  RestBreaks,
  Security,
  SecurityGuardIcon,
  ShadesTrucksIcon,
  SimpleProcess,
  SpaceToTrucks,
  SpaceTruck,
  WellArrangement,
  SelectUnitIcon,
  ReserveUnitIcon,
  SelfStorageIcon,
  ResidentialIcon,
  BusinessStorageIcon,
  VehicleStorageIcon,
  MilitaryStorageIcon,
  CargoSafetyIcon,
  CostControlIcon,
} from "./Icon";

export const customerOuestionsHomePage = [
  {
    questions: "Q.1: What sizes of storage units are available?",
    answers:
      "We offer a variety of storage unit sizes to accommodate different needs, ranging from small 5x5 units to large 10x30 units. You can choose the size that best fits your requirements. ",
  },
  {
    questions: "Q.2: How much does it cost to rent a storage unit?",
    answers:
      "The cost of renting a storage unit depends on the size of the unit and the duration of the rental. Our prices are competitive and vary based on location and availability. Please contact our facility for specific pricing information.",
  },
  {
    questions: "Q3: Is there a minimum rental period for storage units?",
    answers:
      "We offer flexible rental terms, including both short-term and long-term options. There is no minimum rental period, and you can rent a unit for as long as you need.",
  },
  {
    questions: "Q4: Are the storage units climate-controlled?",
    answers:
      "Yes, our storage units are climate-controlled to protect your belongings from extreme temperatures, humidity, and other environmental factors. This helps to ensure the safety and preservation of your items.",
  },
];
export const techSupportOuestionsHomePage = [
  {
    questions:
      "Q.1 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.2 What equipment do i need to have or install this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.3 How soon can i begin to use this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.4 Our facility is a small family owned business, we do not even have an onsite office, can i still use the PN+ system & software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.5 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
];
export const faqTabsHomePage = [
  {
    heading: "Customer Questions",
  },
  // {
  //   heading: "Tech Support Questions",
  // },
];
export const customerOuestionsFintechPage = [
  {
    questions:
      "Q.1 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.2 What equipment do i need to have or install this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.3 How soon can i begin to use this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.4 Our facility is a small family owned business, we do not even have an onsite office, can i still use the PN+ system & software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
];
export const partnersOuestionsFintechPage = [
  {
    questions:
      "Q.1 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.2 What equipment do i need to have or install this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.3 How soon can i begin to use this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.4 Our facility is a small family owned business, we do not even have an onsite office, can i still use the PN+ system & software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.5 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
];
export const techSupportOuestionsFintechPage = [
  {
    questions:
      "Q.1 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.2 What equipment do i need to have or install this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions: "Q.3 How soon can i begin to use this system?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.4 Our facility is a small family owned business, we do not even have an onsite office, can i still use the PN+ system & software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.5 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
  {
    questions:
      "Q.6 What special training or knowledge do i need to be able to use the PN+ Software?",
    answers:
      "The system is designed to be both user friendly and simple to use. With minimal usage you should be able to quickly become familiar with the entire system and its capabilities.",
  },
];
export const faqTabsFintechPage = [
  {
    heading: "Customer Questions",
  },
  {
    heading: "Partners Questions",
  },
  {
    heading: "Tech Support Questions",
  },
];
export const testimonailsData = [
  {
    profileImg: testimonials1,
    heading: "J Romero",
    designation: "Truck Owner/Operator",
    description:
      "Awesome place.. my truck is always secure. Plenty of cameras and lighting. The site manager is always patroling to make sure nothing suspicious is going on. You can pay online with a card so it's up-to-date with technology. Reasonably priced.",
    delay: "200",
  },
  {
    profileImg: testimonials2,
    heading: "KGC",
    designation: "Truck Owner/Operator",
    description:
      "This place is awesome, great parking, great price, area is nice, and best of all the staff are so understanding and friendly. Have nothing bad to say. Also im an owner operator and I definitely recommend park nation!",
    delay: "400",
  },
  {
    profileImg: testimonials3,
    heading: "Marc Azar",
    designation: "Truck Owner/Operator",
    description:
      "Best place to park a truck  in the area. Plenty of room , plenty of light ,itsnextremely secure place and Reasonable  . The manager Keith is a terrific guy . He. Waited for me after hours  when I signed up to park there today . I hope to be here for a long time.",
    delay: "600",
  },
];
export const blogpagecards = [
  {
    image: blog1,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
  {
    image: blog2,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
  {
    image: blog3,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
  {
    image: blog1,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
  {
    image: blog2,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
  {
    image: blog3,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
];
export const blogcards = [
  {
    image: blog1,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
  {
    image: blog2,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
  {
    image: blog3,
    para1: "26/03.2023",
    heading: "Esther Howard",
    para2:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
  },
];
export const sitecards = [
  {
    image1: site1,
    para1: "09 March 2023",
    heading: "Malesuada viverra",
    image2: star,
    para2: "40$/Day",
  },
  {
    image1: site1,
    para1: "09 March 2023",
    heading: "Malesuada viverra",
    image2: star,
    para2: "40$/Day",
  },
  {
    image1: site1,
    para1: "09 March 2023",
    heading: "Malesuada viverra",
    image2: star,
    para2: "40$/Day",
  },
];
export const whatWeOfferData = [
  {
    icon: <SelfStorageIcon />,
    para: "Self Storage Units",
    delay: "200",
  },
  {
    icon: <ResidentialIcon />,
    para: "Residential Moving",
    delay: "400",
  },
  {
    icon: <BusinessStorageIcon />,
    para: "Business Storage",
    delay: "600",
  },
  // {
  //   icon: <ProvideEvChargingPoint />,
  //   para: "We provide EV charging point",
  //   delay: "800",
  // },
  {
    icon: <VehicleStorageIcon />,
    para: "Vehicle Storage",
    delay: "900",
  },
  // {
  //   icon: <FuelStation />,
  //   para: "We Have Fuel Station",
  //   delay: "1000",
  // },
  {
    icon: <MilitaryStorageIcon />,
    para: "Military Storage",
    delay: "1200",
  },
  {
    icon: <CargoSafetyIcon />,
    para: "Cargo Safety",
    delay: "1200",
  },
  {
    icon: <CostControlIcon />,
    para: "Const Control",
    delay: "1200",
  },
  // {
  //   icon: <MechanicsAvailable />,
  //   para: "24/7 mechanics  Available ",
  //   delay: "1200",
  // },
];

export const HowItWorkData = [
  {
    heading: "Select a Unit",
    para: "Reserving your unit is easy! From the unit dropdown menu, select the unit that is available",
    icon: <SelectUnitIcon />,
  },
  {
    heading: "Pick the dates",
    para: "Select the dates that you want to put your storage in that unit and click on Search button",
    icon: <Registeration />,
  },
  {
    heading: "Reserve your unit",
    para: "Pick an available unit that fits your storage",
    icon: <ReserveUnitIcon />,
  },
  {
    heading: "Register",
    para: "When you reserve your unit, a popup window will appear asking you for basic information, your name, company name, telephone number and e-mail address.",
    icon: <LargeParking />,
  },
  {
    heading: "Pay & Confirm",
    para: "After you register and Login, you can either pay your reservation with the card on file or enter a new card/ ACH to complete the reservation. You will receive an email with instructions on how to enter the lot.",
    icon: <PaymentIcon />,
  },
];
// ==== OURTEAMDATA START ====

export const OurTeamData = [
  {
    img: happyMan,
    ceo: "CEO",
    heading: "Esther Howard",
    para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
  },
  {
    img: handsomeGuy,
    ceo: "CEO",
    heading: "Esther Howard",
    para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
  },
  {
    img: africanMan,
    ceo: "CEO",
    heading: "Esther Howard",
    para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
  },
];

export const factSilderData = [
  {
    sliderImg: facts_slider_img1,
    description:
      "Trucking is essential for the U.S. Economy. A 2022 survey showed that when measured by weight, 72.5% of American freight was transported by truck. In 2021, there were around 4 million semi-trucks operating in the country",
  },
  {
    sliderImg: facts_slider_img2,
    description:
      "Hendrerit ullamcorper eu pellentesque sodales sit sagittis quis ut. Aliquam eleifend placerat porttitor tincidunt gravida vulputate. Pellentesque aliquam lacinia metus augue gravida nisl feugiat. Tristique feugiat egestas erat ac. Non elit in volutpat odio. Amet.",
  },
  {
    sliderImg: facts_slider_img3,
    description:
      "Nam semper et netus nulla et. Eleifend vulputate etiam dictum et eget pharetra. Vestibulum aenean mauris ullamcorper in mi at. Odio enim imperdiet massa dolor nunc purus at etiam. Sit blandit vitae nec ullamcorper nulla sed erat. Luctus amet commodo sed pulvinar.",
  },
];

// ==== OURTEAMDATA END ====

// ==== SECURITYLEVELDATA START ====
export const SecurityLevelData = [
  {
    securityIcons: <CameraIcon />,
    heading: "24/7 Camera  In all Direction",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <PaymentIcon />,
    heading: "Secure payment",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <ShadesTrucksIcon />,
    heading: "Shades For all Trucks",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <PaymentMethodIcon />,
    heading: "All payment method available",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <PrivacyIcon />,
    heading: "Privacy",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <SecurityGuardIcon />,
    heading: "Security guard for all gates",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <DataRecordIcon />,
    heading: "All Activities Data Record",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <FullProtectionIcon />,
    heading: "Full protection To All Trucks",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
];
// ==== SECURITYLEVELDATA END ====

// ==== SECURITYLEVELDATA START ====
export const ExploreMoreBenefitsData = [
  {
    securityIcons: <Accounting />,
    heading: "Accounting",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <PushNotification />,
    heading: "Push notification",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <SimpleProcess />,
    heading: "Simple Process",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <Personalization />,
    heading: "Personalization",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <FullSecure />,
    heading: "Full Secure",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
  {
    securityIcons: <DigitalPayment />,
    heading: "Digital Payment",
    para: "Nisl leo et eget vel orci vel nibh. Id et ipsum etiam in vitae amet vitae.",
  },
];
// ==== SECURITYLEVELDATA END ====

// ============ BenifitsOfCustomer Start ==============
export const CustomerBenifits = [
  {
    customerIcons: <SelfStorageIcon />,
    heading: "Self Storage Units",
  },
  {
    customerIcons: <ResidentialIcon />,
    heading: "Residential Moving",
  },
  {
    customerIcons: <BusinessStorageIcon />,
    heading: "Business Storage",
  },
  {
    customerIcons: <VehicleStorageIcon />,
    heading: "Vehicle Storage",
  },
  {
    customerIcons: <MilitaryStorageIcon />,
    heading: "Military Storage",
  },
  {
    customerIcons: <CargoSafetyIcon />,
    heading: "Cargo safety",
  },
  {
    customerIcons: <CostControlIcon />,
    heading: "Cost control",
  },
];

// ============ BenifitsOfCustomer End ==============

export const LocationInformationsData = [
  {
    icon: <SpaceTruck />,
    heading: "We give space to Trucks",
  },
  {
    icon: <Arrangement />,
    heading: "Well arrangement in parking",
  },
  {
    icon: <EvCharging />,
    heading: "We provide EV charging point",
  },
  {
    icon: <Cameras />,
    heading: "We install Camera’s Everywhere",
  },
  {
    icon: <FuelStation2 />,
    heading: "We Have Fuel Station",
  },
  {
    icon: <Security />,
    heading: "We provide full security",
  },
];
