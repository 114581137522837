import React from "react";
import shotMan from "../../../assets/images/jpg/Accessability.jpg";
import { Link } from "react-router-dom";

const WeDo = () => {
  return (
    <>
      <section className=' xl:pt-14'>
        <div className='container pt-16'>
          <div className='flex flex-col-reverse lg:flex-row items-center justify-between'>
            <div className='w-full lg:w-[60%] xl:w-[70%] lg:max-w-[554px] m-auto'>
              <div className='mt-8 lg:mt-0 lg:ps-10 xl:ps-0'>
                <h3 className='text-black font-bold lg:leading-[55px] text-lg md:text-[32px] lg:text-xl font-Manrope mb-4'>
                  How Can Lincoln Storage Help You?
                </h3>
                <p className='font-Poppins text-[#000000] font-normal leading-[25.6px] text-base mb-4'>
                  We offer secure and reliable storage facilities for both
                  personal and business needs.
                </p>
                <p className='font-Poppins text-[#000000] font-normal leading-[25.6px] mb-0 opacity-70 text-base'>
                  Our services include providing storage units of various sizes,
                  ensuring the safety and security of your belongings, offering
                  flexible storage options, and delivering exceptional customer
                  service. Whether you need to store personal items during a
                  move or require storage space for business inventory, Lincoln
                  Storage is here to meet your storage needs
                </p>
                <Link
                  to='/sign-up'
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className='first_btn px-[26px] py-[14px]   inline-block mt-6 lg:mt-11 text-base font-Manrope font-semibold border-[1px] border-primary'
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
            <div className=' w-full lg:w-[50%] xl:w-[40%] lg:ms-9'>
              <img
                className='w-full max-w-[520px] lg:h-[423px] lg:max-w-auto m-auto'
                src={shotMan}
                alt='truck-waiting'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WeDo;
