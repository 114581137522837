import React from "react";
import CommonHero from "../../components/common/CommonHero";
import CostEfficiency from "../../components/ForSiteOwner/features/CostEfficiency";
import DataStorage from "../../components/ForSiteOwner/features/DataStorage";
import DataBackup from "../../components/ForSiteOwner/features/DataBackup";
import DataSecurity from "../../components/ForSiteOwner/features/DataSecurity";
import Scalability from "../../components/ForSiteOwner/features/Scalability";
import DataSharing from "../../components/ForSiteOwner/features/DataSharing";
import DisasterRecovery from "../../components/ForSiteOwner/features/DisasterRecovery";
import Accessibility from "../../components/ForSiteOwner/features/Accessibility";
import DataOrganization from "../../components/ForSiteOwner/features/DataOrganization";
import DataArchiving from "../../components/ForSiteOwner/features/DataArchiving";
import BoxAccess from "../../components/ForSiteOwner/features/BoxAccess";

const Features = () => {
  const commonHero = {
    heading: "Features",
    herobgImg: "bg-features_and_about_us_hero_img",
  };
  return (
    <>
      <CommonHero commonHero={commonHero} />
      <CostEfficiency />
      <DataStorage />
      {/* <DataBackup /> */}
      <DataSecurity />
      <Scalability />
      <Accessibility />
      <DataSharing />
      <DisasterRecovery />
      <DataOrganization />
      <DataArchiving />
      <BoxAccess />
    </>
  );
};

export default Features;
