/**
 * App Config File
 */
const AppConfig = {
  SOCKET_ENDPOINT_URL: "https://api.parknation.org", //http://localhost:12000", //process.env.SOCKET_ENDPOINT_URL, "https://api.parknation.org/brivo/webhook
  SQL_URL: process.env.REACT_APP_API_URL + `/sql/execSP`,
  USPS_URL: "https://secure.shippingapis.com/ShippingAPI.dll",
  // process.env.NODE_ENV == "production"
  //   ? 'http://localhost:3000' +  `/sql/execSP`
  //   : `http://localhost:8080/sql/execSP`,
  TOKEN_URL: process.env.REACT_APP_API_URL + `/refreshToken`,
  LOGOUT_URL: process.env.REACT_APP_API_URL + `/logOut`,
  // process.env.NODE_ENV == "production"
  //   ? 'http://localhost:3000' +  `/refreshToken`
  //   : `http://localhost:8080/refreshToken`,
  PUBLIC_URL: "https://portal.parknation.org", //process.env.publicUrl,
  appLogo: require("../assets/images/png/logo.png"), //process.env.logo, // App Logo
  // appMiniLogo: require("../assets/img/logo-mini.png"),
  appMiniLogo: require("../assets/img/logo_main.png"),
  tenant: process.env.tenant, // Brand Name
  color: process.env.color,
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  miniSidebar: false, // Mini Sidebar
  enableSessionBackgroundImage: false, // Enable Session Background Image
  enableSessionGradientBackgroundImage: true, // Enable Session Background Image
  enableSidebarBackgroundImage: false, // Enable Sidebar Background Image
  sessionBgImage: require("../assets/img/carousel2.jpg"), // Session background image
  sidebarImage: require("../assets/img/sidebar-4.jpg"), // Select sidebar image
  isDarkSidenav: true, // Set true to dark sidebar
  enableThemeOptions: false, // Enable Theme Options
  gradientBg:
    "linear-gradient(to bottom right, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%))",
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "en",
  },
  // enableUserTour: process.env.NODE_ENV === "production" ? true : false, // Enable / Disable User Tour
  copyrightText: `Copyright © ${new Date().getFullYear()} ${
    process.env.copyright
  }. All Rights Reserved.`, // Copy Right Text
  bgColor: "#00add0",
  // light theme colors
  themeColors: {
    primary: "rgba(4, 41, 77, 1)",
    secondary: "#677080",
    success: "#00D014",
    danger: "#FF3739",
    warning: "#FFB70F",
    info: "#00D0BD",
    dark: "#464D69",
    default: "#FAFAFA",
    greyLighten: "#A5A7B2",
    grey: "#677080",
    white: "#FFFFFF",
    purple: "#896BD6",
    yellow: "#D46B08",
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: "#424242",
  },
};

export default AppConfig;
