import React, { useState, useEffect } from "react";
// import Logo from "../../assets/images/webp/logo.webp";
import Logo from "../../assets/images/png/logo.png";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { MenuIconBlack, CrossIcon, DropDown } from "./Icon";
import { useSetState } from "ahooks";
import { GlobalStoreContainer } from "../../../src/store";
import { isNil } from "lodash";
import {
  Button,
  Select,
  Row,
  Col,
  Card,
  Tooltip,
  Badge,
  Statistic,
  List,
  Carousel,
  Space,
  Avatar,
  Rate,
  Image,
  Drawer,
  Typography,
  Divider,
  Tag,
  Modal,
  DatePicker as ADatePicker,
  Radio,
  Form,
  InputNumber,
  Checkbox,
  Popover,
  Input,
  Table,
  notification,
  Alert,
  Spin,
} from "antd";

const Header = (props) => {
  const [state, setState] = useSetState({
    UserObj: undefined,
    saveReserveVersion: 1,
    saveModalVisible: false,
    file: undefined,
    fileType: undefined,
    fileContent: undefined,
    document_name: undefined,
    originalFile: undefined,
    newVehicleModalVisible: false,
    searchText: "",
    animating: false,
    site_id: undefined,
    tax_rate: undefined,
    tax: true,
    site_name: undefined,
    vacancyData: undefined,
    vacancyFilteredData: undefined,
    cameraVisible: false,
    showLogin: false,
    showReserve: false,
    invoice_user_id: undefined,
    cloverPaymentModalVisible: false,
    customer: undefined,
    unit: 1,
    sites: [],
    renderVersion: 1,
    space_id: undefined,
    space_type_id: -1,
    siteRec: undefined,
    quantity: 1,
    card_id: undefined,
    user: undefined,
    name: undefined,
    note: "",
    availableSpaces: [],
    vehicle_type_id: 1,
    token: undefined,
  });

  const [toggle, setToggle] = useState(false);
  const loaction = useLocation();

  let links = document.querySelectorAll(".nav_links");

  links.forEach((item) => {
    item.addEventListener("click", function () {
      setToggle(false);
    });
  });

  if (toggle) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }

  const retrieveToken = async () => {
    return await localStorage.getItem("refreshToken"); //accessToken
  };

  const clearToken = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("session_id");
  };

  const logOut = () => {
    debugger;
    clearToken();
    let store = GlobalStoreContainer.store;
    // store.dispatch({ type: "CLEAR_ALL_REDUCERS_DATA" });
    store.dispatch({ type: "LOGOUT_USER" });
    window.location.href = "/";
  };

  const checkTokenAPI = async (parms) => {
    //  debugger;

    let URL = process.env.REACT_APP_API_URL + `/checkTokenAPI`;
    // let URL = `http://localhost:8080/reactlogin`;
    // if (process.env.NODE_ENV == "production") {
    //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
    // }

    return await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...parms,
      }),
    })
      .then((response) => response.json())
      .catch((error) => error);
  };

  useEffect(() => {
    (async () => {
      debugger;

      const token = await retrieveToken();
      if (token) {
        console.log("token", token);
        const parms = {
          accessToken: token,
        };
        const resultObj = JSON.parse(await checkTokenAPI(parms));
        console.log("Sign In resultObj", resultObj);

        if (resultObj.messageType == "success") {
          console.log("user", localStorage.getItem("user"));
          setState({
            token: token,
            UserObj: JSON.parse(localStorage.getItem("user")),
            renderVersion: state.renderVersion + 1,
          });
        } else {
          console.log("Token Expired");
          setState({
            token: undefined,
            UserObj: undefined,
            renderVersion: state.renderVersion + 1,
          });
        }
      }
    })();
  }, [props]);

  return (
    <>
      <nav className='bg-[#fafafa] z-50 overflow-visible'>
        <div className='container mx-auto flex justify-between items-center py-[12px]'>
          <div className='w-full'>
            <Link aria-label='logo' to='/'>
              <img className=' w-[35%] sm:w-[75px] ' src={Logo} alt='logo' />
            </Link>
          </div>
          <div className='w-full hidden lg:block'>
            <ul className='flex gap-7 lg:px-6'>
              <li
                className={` whitespace-nowrap font-Poppins cursor-pointer hover:text-primary duration-300 ease-in-out ${
                  loaction.pathname === "/" ? "text-primary " : "text-[#4d4d4d]"
                }`}
              >
                <Link aria-label='Search' to='/'>
                  Home
                </Link>
              </li>
              <li
                className={` whitespace-nowrap font-Poppins cursor-pointer hover:text-primary duration-300 ease-in-out ${
                  loaction.pathname === "/search"
                    ? "text-primary "
                    : "text-[#4d4d4d]"
                }`}
              >
                <Link aria-label='Search' to='/search'>
                  Search
                </Link>
              </li>
              <li
                className={` whitespace-nowrap font-Poppins cursor-pointer hover:text-primary duration-300 ease-in-out ${
                  loaction.pathname === "/how-it-work"
                    ? "text-primary "
                    : "text-[#4d4d4d]"
                }`}
              >
                <Link aria-label='How it works' to='/how-it-work'>
                  How it works
                </Link>
              </li>
              {/* <li
                className={` whitespace-nowrap font-Poppins cursor-pointer hover:text-primary duration-300 ease-in-out ${
                  loaction.pathname === "/security"
                    ? "text-primary "
                    : "text-[#4d4d4d]"
                }`}
              >
                <Link aria-label="Security" to="/security">
                  Security
                </Link>
              </li> */}
              <li
                className={` whitespace-nowrap font-Poppins cursor-pointer hover:text-primary duration-300 ease-in-out ${
                  loaction.pathname === "/contact-us"
                    ? "text-primary "
                    : "text-[#4d4d4d]"
                }`}
              >
                <Link aria-label='Contact us' to='/contact-us'>
                  Contact us
                </Link>
              </li>
              {/* <li
                className={` whitespace-nowrap font-Poppins cursor-pointer hover:text-primary duration-300 ease-in-out ${
                  loaction.pathname === "/all-sites"
                    ? "text-primary "
                    : "text-[#4d4d4d]"
                }`}
              >
                <Link aria-label="All sites" to="/all-sites">
                  All sites
                </Link>
              </li> */}
              <li className='whitespace-nowrap font-Poppins text-[#4d4d4d] cursor-pointer  relative transition-ease-in-out after:duration-300 hover:text-black '>
                <Menu>
                  <Menu.Button className='flex items-center justify-between'>
                    More{" "}
                    <span className='ps-2'>
                      <DropDown />
                    </span>
                  </Menu.Button>
                  <Menu.Items className='absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-b-2xl bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col overflow-visible'>
                    {/* <Menu.Item className="ps-4 w-full pr-10 py-2 rounded-b-md border-0 p-2">
                      {({ active }) => (
                        <Link
                          aria-label="blog"
                          to="/blog"
                          className={` border-0 ${
                            active && "bg-primary text-white"
                          } ${
                            loaction.pathname === "/blog"
                              ? "bg-primary text-white"
                              : ""
                          }`}
                        >
                          Blogs
                        </Link>
                      )}
                    </Menu.Item> */}
                    <Menu.Item className='ps-4 w-full pr-10 py-2 rounded-b-md border-0 p-2'>
                      {({ active }) => (
                        <Link
                          aria-label='about us'
                          to='/about-us'
                          className={` border-0 ${
                            active && "bg-primary text-white"
                          } ${
                            loaction.pathname === "/about-us"
                              ? "bg-primary text-white"
                              : ""
                          }`}
                        >
                          About us
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </li>
            </ul>
          </div>

          <div className='lg:flex hidden  gap-2.5 w-full ms-3 xs:ms-0 justify-center lg:justify-end'>
            {!isNil(state.token) ? (
              <>
                <Row>
                  <Col>
                    {`Logged in as ${state.UserObj?.customer_name}`}
                    <br />
                    {`Site: ${state.UserObj?.site_name}`}
                    <br />
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => logOut()}
                    >
                      Log out
                    </span>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Link
                  aria-label='sign up'
                  to='/sign-up'
                  className=' p-[4px_12px] 2xs:p-[8px_16px] text-center sm:p-[14px_26px] font-semibold common_btn whitespace-nowrap font-Manrope'
                >
                  Register
                </Link>
                <Link
                  aria-label='login'
                  to='/login'
                  className='p-[4px_12px] 2xs:p-[8px_16px] sm:p-[14px_26px] text-center first_btn font-semibold font-Manrope whitespace-nowrap'
                >
                  Log in
                </Link>
              </>
            )}
          </div>
          <div className='w-full lg:hidden flex justify-end'>
            <button
              onClick={() => {
                setToggle(true);
              }}
            >
              <MenuIconBlack />
            </button>
          </div>
        </div>
      </nav>
      <div
        className={` transition-all ease-in-out duration-300 fixed  left-0 w-full z-20 h-screen bg-primary flex justify-center items-center flex-col lg:hidden ${
          toggle ? "top-0" : "top-[-300%]"
        }`}
      >
        <div className='absolute top-0 end-0 p-6'>
          <button
            onClick={() => {
              setToggle(false);
            }}
          >
            <CrossIcon />
          </button>
        </div>
        <div className='w-full flex justify-center items-center'>
          <ul className=' text-base  md:text-lg'>
            <li className='whitespace-nowrap font-Poppins text-white text-center mb-3  '>
              <Link
                aria-label='Search'
                to='/search'
                className='nav_links relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
              >
                Search
              </Link>
            </li>
            <li className='whitespace-nowrap font-Poppins text-white text-center mb-3 '>
              <Link
                aria-label='Howitwork'
                to='/how-it-work'
                className='nav_links relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
              >
                How it works
              </Link>
            </li>
            {/* <li className="whitespace-nowrap font-Poppins text-white text-center mb-3  ">
              <Link
                aria-label="Security"
                to="/security"
                className="nav_links relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full"
              >
                Security
              </Link>
            </li> */}
            <li className='whitespace-nowrap font-Poppins text-white text-center mb-3 '>
              <Link
                aria-label='Contact us'
                to='/contact-us'
                className='nav_links relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
              >
                Contact us
              </Link>
            </li>
            <li className='whitespace-nowrap font-Poppins text-white text-center mb-3 '>
              <Link
                aria-label='all-sites'
                to='/all-sites'
                className='nav_links relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
              >
                All sites
              </Link>
            </li>

            <li className='whitespace-nowrap font-Poppins text-white text-center mb-3 '>
              <Link
                aria-label='all-sites'
                to='/blog'
                className='nav_links relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
              >
                Blogs
              </Link>
            </li>
            <li className='whitespace-nowrap font-Poppins text-white text-center mb-3 '>
              <Link
                aria-label='all-sites'
                to='/about-us'
                className='nav_links relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
              >
                About us
              </Link>
            </li>

            <div className='text-center  w-full justify-center lg:justify-end'>
              {!isNil(state.token) ? (
                <>
                  <Row>
                    <Col>{`Welcome ${state.user?.customer_name}`}</Col>
                  </Row>
                  <Row>
                    <Col>{`Site ${state.user?.site_name}`}</Col>
                  </Row>
                </>
              ) : (
                <>
                  <Link
                    aria-label='sign up'
                    to='/sign-up'
                    className='  text-base p-[9px_18px] mb-3 2xs:p-[8px_16px] sm:p-[14px_26px] font-semibold common_btn whitespace-nowrap font-Manrope'
                  >
                    Register
                  </Link>
                  <Link
                    aria-label='login'
                    to='/login'
                    className='text-base p-[9px_18px] 2xs:p-[8px_16px] sm:p-[14px_26px] first_btn font-semibold border-white font-Manrope whitespace-nowrap'
                  >
                    Log in
                  </Link>
                </>
              )}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
