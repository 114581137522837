import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Space } from "antd";
import { MaskedInput as MI } from "antd-mask-input";
import { CheckCircleOutlined, PhoneOutlined } from "@ant-design/icons";
import { NotificationManager } from "react-notifications";
// import "react-phone-number-input/style.css";

// import PhoneInput, {
//   formatPhoneNumber,
//   formatPhoneNumberIntl,
//   isValidPhoneNumber,
//   isPossiblePhoneNumber,
// } from "react-phone-number-input";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

// import { NotificationManager } from "react-notifications";
import { trim } from "lodash";
import { PNfetch } from "../../../api/pnfetch.js";
import OtpVerifyModal from "./otp.jsx";

const validateRules = (name, label) => {
  return [
    {
      required: true,
      message: `${label} is required`,
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          !value ||
          // this.inputMaskRef.current.mask.getRawValue().length == 10
          getFieldValue(name).indexOf("_") === -1
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(`Please enter the Complete ${label}!`));
      },
    }),
  ];
};

const sendRequest = async (endpoint, body) => {
  const URL = process.env.REACT_APP_API_URL + endpoint;
  const response = await PNfetch(URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(body),
  });
  return await response.json();
};

const RPhoneInput = ({
  verifyPhone = false,
  disabled = false,
  name,
  label,
  required = true,
  width = 500,
  onVerify,
  value,
  onChange,
  onValidate,
}) => {
  const [isNumberVerified, setIsNumberVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [otpModal, showOtpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (!label) {
    label = "Phone Number";
  }
  // Callback of send otp number
  const handleSentOtp = React.useCallback(async (phoneNumber, countryCode) => {
    // console.log("real", formatPhoneNumber(phoneNumber));

    // const phone_number = trim(formatPhoneNumber(phoneNumber) || "").replace(
    //   /[^\d]/g,
    //   ""
    // );
    // console.log("next", phone_number);
    // if (!phone_number || phone_number.length != 10) {
    //   NotificationManager.error(`Please Enter Valid ${label}`, label, 1100);
    //   return false;
    // }

    // formatPhoneNumber(unmaskedValue);
    debugger;
    if (!phoneNumber || phoneNumber?.length < 10) {
      //!isPossiblePhoneNumber(phoneNumber)
      NotificationManager.error(`Please Enter Valid ${label}`, label, 1100);
      return false;
    }

    //countryCode
    try {
      const response = await sendRequest("/twilio/otp", {
        // phone: "+1" + phone_number,
        phone: "+" + countryCode + phoneNumber,
      });
      if (response.messageType == "error") {
        NotificationManager.error(response.message, label, 1100);
        return false;
      }
      showOtpModal(true);
    } catch (error) {
      NotificationManager.error(error.message, label, 1100);
    }
  }, []);

  // Callback verify opt number
  const handleVerifyOtp = React.useCallback(
    async (otpNumber, phoneNumber, countryCode) => {
      if (!otpNumber || otpNumber < 6) {
        NotificationManager.error(
          "Please enter 6 digit otp number",
          label,
          1100
        );
        return false;
      }
      setIsLoading(true);
      const phone_number = trim(phoneNumber || "").replace(/[^\d]/g, "");
      try {
        const response = await sendRequest("/twilio/otpVerify", {
          // phone: "+1" + phone_number,
          phone: "+" + countryCode + phone_number,
          otp: otpNumber,
        });
        if (response.messageType == "error") {
          NotificationManager.error(response.message, "OTP Verification", 1100);
          setIsLoading(false);
          return false;
        } else if (
          response.verification_check.status == "approved"
          // response.verification_check.status == "pending"
        ) {
          debugger;
          NotificationManager.success(
            `${label} is Verified`,
            "OTP Verification",
            1100
          );
          //Call the parent to pass the result
          if (onValidate) {
            const data = {
              phone_number: phoneNumber,
              country_code: countryCode,
            };
            onValidate(true, data);
          }
          showOtpModal(false);
          setIsNumberVerified(true);
        } else {
          if (onValidate) {
            const data = {
              phone_number: phoneNumber,
              country_code: countryCode,
            };
            onValidate(false, data);
          }
          NotificationManager.error(
            "Invalid OTP number, Please try again",
            "OTP Verification",
            1100
          );
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        NotificationManager.error(error.message, "OTP Verification", 1100);
      }
    },
    []
  );

  // Set phone number on change
  const handleOnChange = React.useCallback((phone, country) => {
    debugger;
    const reducedPhone = phone.replace(country.dialCode, "");
    if (isNumberVerified) {
      setIsNumberVerified(false);
    }

    // if (
    //   !reducedPhone ||
    //   trim((reducedPhone || "").replace(/[^\d]/g, "")).length < 10
    // ) {
    //   // NotificationManager.error(
    //   //   `Please Enter Valid Phone Number`,
    //   //   "Phone Number",
    //   //   1100
    //   // );
    //   // return false;
    // }
    debugger;
    console.log("country.dialCode", country.dialCode);
    const country_code = country.dialCode.toString();
    setPhoneNumber(reducedPhone);
    setCountryCode(country_code);
    if (onValidate) {
      const data = {
        phone_number: reducedPhone,
        country_code: country_code,
      };
      onValidate(false, data);
    }
  }, []);
  // const handleOnChange = React.useCallback((unmaskedValue) => {
  //   debugger;
  //   if (isNumberVerified) {
  //     setIsNumberVerified(false);
  //   }

  //   // console.log("real", formatPhoneNumber(unmaskedValue));
  //   // const fpn = formatPhoneNumber(unmaskedValue);

  //   // const phone_number = trim(fpn || "").replace(/[^\d]/g, "");
  //   console.log("real phone_number", unmaskedValue);
  //   setPhoneNumber(unmaskedValue);
  //   if (onChange) {
  //     onChange(unmaskedValue);
  //   }
  // }, []);
  // const handleOnChange = React.useCallback(({ unmaskedValue }) => {
  //   debugger;
  //   if (isNumberVerified) {
  //     setIsNumberVerified(false);
  //   }
  //   setPhoneNumber(unmaskedValue);
  //   if (onChange) {
  //     onChange(unmaskedValue);
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Space>
        {/* <MI
          size="10"
          mask="(000) 000 - 0000"
          lazy="false"
          name={name}
          placeholder={`Enter ${label}`}
          // style={{ width: width + "px" }}
          onChange={handleOnChange}
          value={value}
          disabled={disabled}
        /> */}
        {/* <PhoneInput
          defaultCountry="US"
          international
          countryCallingCodeEditable={false}
          placeholder="Enter phone number"
          value={value}
          onChange={handleOnChange}
        /> */}
        <PhoneInput
          name={name}
          country={"us"}
          preferredCountries={["us"]}
          countryCodeEditable={false}
          value={value}
          placeholder='Enter phone number'
          onChange={handleOnChange}
          onValidate={onValidate}
          // onChange={(phone) => this.setState({ phone })}
        />
        {verifyPhone && !isNumberVerified && (
          <Button
            type='text'
            style={{
              border: "none",
            }}
            icon={<PhoneOutlined />}
            onClick={() => handleSentOtp(phoneNumber, countryCode)}
          ></Button>
        )}
        {isNumberVerified && (
          <Button
            type='text'
            style={{
              border: "none",
              color: "#008A25",
            }}
            icon={<CheckCircleOutlined />}
          ></Button>
        )}
      </Space>
      {verifyPhone && (
        <OtpVerifyModal
          open={otpModal}
          onCancel={() => showOtpModal(false)}
          onVerify={(otp) => handleVerifyOtp(otp, phoneNumber, countryCode)}
          loading={isLoading}
          destroyOnClose={true}
        />
      )}
    </React.Fragment>
  );
};

RPhoneInput.propTypes = {
  verifyPhone: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  width: PropTypes.number,
  onVerify: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(RPhoneInput);
