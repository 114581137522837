import React from "react";
import cameraSurveillance from "../../../assets/images/jpg/DataArchiving.jpg";
import { ListCheck } from "../../common/Icon";
const DataArchiving = () => {
  return (
    <>
      <section className='my-12 md:my-20 lg:my-28 xl:my-[150px] overflow-x-hidden'>
        <div className='container'>
          <div className='flex flex-col lg:flex-row gap-12 lg:gap-[41px] items-center'>
            <div data-aos='fade-right' data-aos-duration='1500'>
              <div className='max-w-[580px]'>
                <img
                  className='w-full'
                  src={cameraSurveillance}
                  alt='cameraSurveillance'
                />
              </div>
            </div>
            <div data-aos='fade-left' data-aos-duration='1500'>
              <div className='max-w-[540px]'>
                <h2 className='text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4'>
                  Data Archiving{" "}
                </h2>
                <p className='text-base font-Poppins font-normal xl:pe-12 text-[#000] leading-[160%] opacity-70 mb-12'>
                  Storage units allow for long-term data archiving, ensuring
                  that important data is preserved and accessible for future
                  reference or compliance purposes. This is particularly
                  important for businesses that need to retain data for
                  regulatory or legal reasons.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataArchiving;
