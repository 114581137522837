import React, { useState } from "react";
import { HowItWorkData } from "../../common/Helper";
import { Arrows } from "../../common/Icon";
import Process from "../../../view/ForTruckDrivers/Process";
import BenifitsOfCustomer from "./BenifitsOfCustomer";
import { Link } from "react-router-dom";

const Customer = () => {
  const [indexValue, setIndexValue] = useState(0);
  const [value, setValue] = useState(HowItWorkData[indexValue]);
  const clickHandler = (index, item) => {
    setIndexValue(index);
    setValue(item);
  };
  return (
    <>
      <section className=''>
        <div className='bg-how_it_works bg-center bg-cover'>
          <h1 className='text-[#FAFAFA] font-extrabold font-Manrope text-center text-[32px] md:text-xl lg:text-2xl py-[44px]'>
            How it works
          </h1>
          <p className='text-[#FAFAFA] font-Manrope text-center text-[24px] py-10 px-20'>
            Lincoln Land Storage Units is a trusted and reliable storage
            facility that offers secure and convenient storage solutions for
            individuals and businesses. With a wide range of unit sizes
            available, customers can find the perfect space to store their
            belongings, whether it's furniture, documents, or equipment.
          </p>
        </div>
        <div className='container py-10 md:py-14 xl:pt-[140px] lg:pb-[80px]'>
          {/* <h2 className=" text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold text-center mb-5 md:mb-0">
            Search for a Unit
          </h2> */}
          <div className='flex bg-[#ffffff]  md:mt-[47px] gap-6 justify-between flex-col md:flex-row overflow-auto'>
            <div className='overflow-auto no-scrollbar md:max-w-[27%] md:ml-[25px] lg:ml-0'>
              <div className='mt-2  w-[920px] md:w-full text-center md:text-left flex md:flex-col mb-4 lg:mb-0 relative after:absolute after:bottom-0 after:left-0 after:right-0 after:bg-[#BEC2CA] after:h-[2px] after:w-full !transition-all duration-300 md:static '>
                {HowItWorkData &&
                  HowItWorkData.map((item, index) => {
                    return (
                      <div
                        className={`flex justify-between items-center md:block white cursor-pointer pr-3 ps-1 pb-2 md:pb-0 md:ps-0 md:pr-0 relative ${
                          item.heading === value.heading
                            ? "  text-primary after:absolute after:bottom-0 after:left-0 after:bg-primary after:h-[2px] after:w-full !transition-all duration-300 md:after:static z-10"
                            : ""
                        }`}
                        key={index}
                        onClick={() => clickHandler(index, item)}
                      >
                        <p
                          className={`font-Manrope font-semibold  text-base whitespace-nowrap md:whitespace-normal lg:text-md md:pb-[10px]  relative md:text-left text-center ${
                            item.heading === value.heading
                              ? " md:text-primary  md:after:absolute md:after:bottom-0 md:after:left-0 md:after:bg-primary md:after:h-[2px] after:w-[88%]  after:right-0 !transition-all duration-300 "
                              : "text-black"
                          }`}
                        >
                          {item.heading}
                        </p>
                        {index <= 3 ? (
                          <span className='md:inline-block md:rotate-90 md:mt-[15px] md:mb-[5px] ms-6 md:ms-14 '>
                            <Arrows />
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className='md:w-[65%] lg:w-[71%] bg-[#F8F8F8] rounded-[20px] md:py-12 lg:ps-[36px] lg:pr-[27px] md:max-h-[395px] lg:h-[295px] p-6 md:px-5  flex flex-col justify-between'>
              <div className='flex flex-col lg:flex-row items-center lg:items-start'>
                <span className='sm:pr-[20px] lg:pr-[49px]  h-[48px] w-[48px] xl:h-[70px]  md:h-auto md:w-auto  '>
                  {value.icon}
                </span>
                <p className='max-w-[627px] text-[#000000] font-Poppins text-base text-center lg:text-start mt-4 lg:mt-0 opacity-70'>
                  {value.para}
                </p>
              </div>
              <div className='flex justify-center lg:justify-end'>
                <Link
                  to='/sign-up'
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className='first_btn inline-block sm:py-[14px] py-[9px] px-[18px] mt-6 lg:mt-0 sm:px-[26px] text-base text-end font-semibold   font-Manrope'
                >
                  Join Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        <BenifitsOfCustomer />
        <Process />
      </section>
    </>
  );
};

export default Customer;
