/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useCallback, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  formInitialValues,
  mapContainerStyle,
  PNGroups,
} from "./constants.jsx";
import { Button, Col, Form, Input, Row, Select, notification } from "antd";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import RPhoneInput from "./phoneInput.jsx";
import { titleCase } from "../../../util/common.js";
import { AutoComplete } from "antd";
import * as uuid from "uuid";
import { NotificationManager } from "react-notifications";
// import AddressInput from "../AddressInputSrc";
import ZipCodeInput from "./ZipCodeInput.jsx";
const { TextArea } = Input;

const googleLibaries = ["places"];
import { debounce, trim } from "lodash";
import { PNfetch, placeLookup } from "../../../api/pnfetch.js";

const AddressInput = ({ placeholder, onSelect, value, onChange }) => {
  const [addresses, setAddresses] = useState([]);
  // Search address on type
  const handleOnSearch = React.useCallback(
    debounce(async (address) => {
      let URL = process.env.REACT_APP_API_URL + `/addressLookup`;
      try {
        const response = await PNfetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            addressLookup: address,
          }),
        });
        const retObj = await response.json();
        const addresses = (retObj.data.predictions || []).map((address) => ({
          description: address.description,
          place_id: address.place_id,
        }));
        if (addresses.length > 0) {
          setAddresses(addresses);
        }
      } catch (e) {
        console.log(e.message);
      }
    }, 250),
    []
  );

  // Get final address of selected address
  const handleOnSelectAddress = React.useCallback(async (value, option) => {
    //  debugger;
    const response = await placeLookup(option.key);
    if (response) {
      const finalAddress = {
        address: response?.address,
        location: {
          longitude: response?.longitude,
          latitude: response?.latitude,
          center: {
            lat: response?.latitude,
            lng: response?.longitude,
          },
        },
      };
      if (onSelect) {
        onSelect(finalAddress);
      }
    }
  }, []);

  return (
    <AutoComplete
      placeholder={placeholder ?? ""}
      onSearch={handleOnSearch}
      onSelect={handleOnSelectAddress}
      value={value}
      onChange={onChange}
    >
      {addresses.map((item) => (
        <AutoComplete.Option key={item.place_id} value={item.description}>
          <span>{`${titleCase(item.description)}`}</span>
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};

// Zip code validation rules
const validateZipRules = () => {
  return [
    {
      required: true,
      message: "Zip Code is required",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("zipcode").indexOf("_") == -1) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("Please enter the Complete Zip code!"));
      },
    }),
  ];
};

const validatePhoneRules = (name, label) => {
  return [
    {
      required: true,
      message: `${label} is required`,
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          !value ||
          // this.inputMaskRef.current.mask.getRawValue().length == 10
          getFieldValue(name).indexOf("_") === -1
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(`Please enter the Complete ${label}!`));
      },
    }),
  ];
};

const NewCustomerForm = React.forwardRef((props, formRef) => {
  debugger;
  const {
    initialValues = formInitialValues,
    sites = [],
    roles = [],
    onCancel,
    showMap = true,
    showButtons = true,
    showAddress = true,
    disableEmailandPhone = false,
    showNotes,
    onSave,
    phoneVerification,
    showDefaultSite,
    showStatus,
    showGroup,
    ...rest
  } = props;

  const [mapCenter, setMapCenter] = useState({
    lat: 39.760198,
    lng: -74.971551,
  });

  useEffect(() => {
    debugger;
    console.log("initialValues", initialValues);
    // // let URL = process.env.REACT_APP_API_URL + `/geocodeLookup`;
    // // const response = await PNfetch(URL, {
    // //   method: "POST",
    // //   headers: {
    // //     Accept: "application/json",
    // //     "Content-Type": "application/json",
    // //     authorization: "Bearer " + localStorage.getItem("accessToken"),
    // //   },
    // //   body: JSON.stringify({
    // //     addressLookup:
    // //       initialValues.address_line_1 +
    // //       "," +
    // //       initialValues.city +
    // //       "," +
    // //       initialValues.state,
    // //   }),
    // // });
    // // const retObj = await response.json();
    // // // longitude: resp.longitude,
    // // //   latitude: resp.latitude,

    // setMapCenter({
    //   lat: retObj?.latitude,
    //   lng: retObj?.longitude,
    // });
    if (initialValues.mapCenter) {
      setMapCenter(initialValues.mapCenter);
    }
  }, []);

  const { isLoaded: isLoadedGoogleMapScript } = useLoadScript({
    googleMapsApiKey: "AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM",
    libraries: googleLibaries,
  });

  const Buttons = (
    <div className={"pn-modal-footer"}>
      <Row style={{ padding: "8px" }}>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginRight: "8px" }}
            onClick={onSave}
          >
            Create Account
          </Button>
          <Button
            htmlType='button'
            style={{ marginRight: "8px" }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );

  // const validatePhoneNumber = useCallback((isValid) => {
  //   formRef.current.setFieldsValue({
  //     phoneVerified: isValid,
  //   });
  // }, []);

  const validatePhoneNumber = useCallback((isValid, data) => {
    debugger;
    formRef.current.setFieldsValue({
      phoneVerified: isValid,
      phone_number: data.country_code + data.phone_number,
      country_code: data.country_code,
    });
  }, []);

  // Validate zip code and Log In
  const validateZipCode = useCallback((isValid, data) => {
    if (isValid === false) {
      notification.error({
        message: "Zip Code Check",
        description: data,
      });
      formRef.current.setFieldsValue({
        zipcode: "",
      });
      return false;
    }
    if (isValid) {
      formRef.current.setFieldsValue({
        city: titleCase(data.city),
        state: data.state,
      });
    }
  }, []);

  const printSiteName = (item) => {
    //
    const custStr =
      (item.name ? item.name : " ") +
      "| " +
      (item.description ? item.description : " ") +
      " | " +
      (item.city ? item.city : "No City") +
      " | " +
      (item.state ? item.state : "No State") +
      " | " +
      (item.zipcode ? item.zipcode : "No Zip");
    return custStr;
  };

  // const printSiteName = (item) => {
  //   //
  //   const custStr =
  //     (item.name ? item.name : " ") +
  //     "| " +
  //     (item.description ? item.description : " ");

  //   return custStr;
  // };

  // on select address fill address fields
  const handleOnSelectAddress = useCallback(({ address, location }) => {
    debugger;
    const record = formRef.current.getFieldsValue(true);
    //We are reading the entered Value
    // record.apt_suite_number = ""; //address?.home;
    // record.street_address = address?.home + " " + address?.street;
    // record.city = titleCase(address?.city);
    // record.state = address?.region;
    // record.country = address?.country;
    // record.zipcode = address?.postal_code;
    // record.mapCenter = location.center;
    formRef.current.setFieldsValue({
      ...record,
      street_address: address?.home + " " + address?.street,
      city: titleCase(address?.city),
      state: address?.region,
      country: address?.country,
      zipcode: address?.postal_code,
      mapCenter: location.center,
    });
    if (showMap) {
      setMapCenter(location.center);
    }
  }, []);

  return (
    <React.Fragment>
      <Form initialValues={initialValues} ref={formRef} {...rest}>
        {/*{showButtons && Buttons}*/}
        <div className={"pn-modal-body"}>
          <div style={{ fontWeight: 400 }}>Contact Information</div>
          <div className='PNFormDiv'>
            <Form.Item label='Name' style={{ marginBottom: 0 }}>
              <Form.Item
                name='first_name'
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                // label="First Name"
                rules={[
                  {
                    required: true,
                    message: "First Name is required",
                  },
                ]}
              >
                <Input placeholder='Enter First Name' />
              </Form.Item>

              <Form.Item
                name='last_name'
                // label="Last Name"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Last Name is required",
                  },
                ]}
              >
                <Input placeholder='Enter Last Name' />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name='phone_number'
              label='Cell Phone'
              rules={validatePhoneRules("phone_number", "Phone Number")}
              extra='Please verify the phone number by clicking on phone icon'
            >
              <RPhoneInput
                disabled={disableEmailandPhone}
                name='phone_number'
                label='Phone Number'
                verifyPhone={phoneVerification}
                onValidate={validatePhoneNumber}
              />
            </Form.Item>
            <Form.Item
              name='phoneVerified'
              label='phoneVerified'
              hidden={true}
            ></Form.Item>
            <Form.Item
              name='street_address'
              label='Street Address'
              extra='Address is auto populated. Type the initial letters.'
            >
              <AddressInput
                onSelect={handleOnSelectAddress}
                placeholder='Enter Address here'
              />
            </Form.Item>
            <Form.Item name='apt_suite_number' label='Apt./Suite'>
              <Input />
            </Form.Item>
            <Form.Item
              name='zipcode'
              label='Zip Code'
              rules={validateZipRules()}
            >
              <ZipCodeInput
                size='6'
                lazy='false'
                placeholder='Enter Zip Code'
                onValidate={validateZipCode}
              />
            </Form.Item>
            <Form.Item
              name='city'
              label='City'
              rules={[
                {
                  required: true,
                  message: "City is required",
                },
              ]}
            >
              <Input placeholder='Enter City here' />
            </Form.Item>
            <Form.Item
              name='state'
              label='State'
              rules={[
                {
                  required: true,
                  message: "State is required",
                },
              ]}
            >
              <Input placeholder='Enter State here' />
            </Form.Item>
            {/* <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country is required",
                },
              ]}
            >
              <Input placeholder="Enter Country here" />
            </Form.Item> */}
            {/* {showNotes && (
              <Form.Item
                name="notes"
                label="Notes"
                rules={[
                  {
                    required: false,
                    message: "Note is required",
                  },
                ]}
              >
                <TextArea
                  placeholder="Enter a Note"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            )}
            {showDefaultSite && (
              <Form.Item
                name="default_site_id"
                label="Default Site"
                rules={[
                  {
                    required: true,
                    message: "Default Site is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Site"
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={780}
                  filterOption={(input, option) => {
                    return (
                      (option.searchText || "")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {(sites || []).map((item) => (
                    <Select.Option
                      key={uuid.v4()}
                      value={item.site_id}
                      searchText={`${item.name}`}
                    >
                      <span>{`${printSiteName(item)}`}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {showStatus && (
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Role is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Status"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      (option.searchText || "")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {(roles || []).map((item) => (
                    <Select.Option
                      key={uuid.v4()}
                      value={item.id}
                      searchText={`${item.name}`}
                    >
                      <span>{`${titleCase(item.name)}`}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )} */}
          </div>
          {showAddress && (
            <>
              <div style={{ fontWeight: 400, marginTop: "8px" }}>
                Login Information
              </div>
              <div className='PNFormDiv'>
                {/* <Form.Item name="company_name" label="Company Name">
                  <Input placeholder="Enter Company Name" />
                </Form.Item> */}
                <Form.Item
                  name='email'
                  label='E-mail'
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    disabled={disableEmailandPhone}
                    placeholder='Enter E-mail'
                  />
                </Form.Item>
                {/* <Form.Item
                  name="user_name"
                  label="Username"
                  rules={[
                    {
                      required: true,
                      message: "Username is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter User Name" />
                </Form.Item> */}
                <Form.Item
                  name='password'
                  label='Password'
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder='Must be at least 4 characters.'
                    minLength={4}
                  />
                </Form.Item>
                <Form.Item
                  name='password_confirm'
                  label='PWD Confirmation'
                  rules={[
                    {
                      required: true,
                      message: "Password Confirmation is required",
                    },
                    {
                      message:
                        "Confirm Password should match with the password",
                      validator: (_, value) => {
                        debugger;
                        const password =
                          formRef.current.getFieldValue("password");
                        if (trim(value) == trim(password)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Confirm Password should match with the password"
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder='Please confirm password.'
                    minLength={4}
                  />
                </Form.Item>
              </div>
            </>
          )}
          {showMap && isLoadedGoogleMapScript && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={mapCenter}
              zoom={15}
            >
              <MarkerF position={mapCenter} />
            </GoogleMap>
          )}
        </div>
        {showButtons && Buttons}
      </Form>
    </React.Fragment>
  );
});

NewCustomerForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  phoneVerification: PropTypes.bool,
  sites: PropTypes.array,
  roles: PropTypes.array,
  showMap: PropTypes.bool,
  showDefaultSite: PropTypes.bool,
  showStatus: PropTypes.bool,
  showGroup: PropTypes.bool,
  showAddress: PropTypes.bool,
  showButtons: PropTypes.bool,
  disableEmailandPhone: PropTypes.bool,
  showNotes: PropTypes.bool,
  mapCenter: PropTypes.object,
  phoneVerified: PropTypes.bool,
};

export default NewCustomerForm;
