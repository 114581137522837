import React from "react";
import professional from "../../../assets/images/jpg/AboutUsPageimage2.jpg";
import smallProfessional from "../../../assets/images/jpg/AboutUsPageimage3.jpeg";

const CreatingNewopportunity = () => {
  return (
    <>
      <section className='mt-12 sm:my-12 lg:mt-[140px] xl:mb-24 '>
        <div className='container flex flex-col-reverse lg:flex-row gap-4 md:gap-6 text-center md:text-start items-center'>
          <div className='w-full lg:w-[50%] xl:w-1/2 mt-10 lg:mt-0'>
            <h2 className=' text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold xl:me-5'>
              Your Trusted Storage Solution Welcome to Lincoln Storage
            </h2>
            <p className='text-base mt-4 mb-5 text-[#000000] opacity-70 font-Poppins'>
              Your trusted storage solution provider. We understand that finding
              a reliable and secure storage facility is essential for both
              personal and business needs. With our state-of-the-art facilities
              and exceptional customer service, we are committed to providing
              you with the best storage experience possible.
            </p>
          </div>
          <div className='w-full lg:w-[45%] xl:w-1/2 relative sm:ms-0 sm:ps-10 xl:ps-0'>
            <div className='mx-auto max-w-[220px] sm:max-w-[400px] lg:max-w-[381px]'>
              <img
                className='shadow-[-21px_-21px_4px_0px_#EB2022,0px_4px_4px_0px_#00000040] rounded-lg md:rounded-2xl'
                src={professional}
                alt='aboutBig'
              />
              <img
                className='absolute  right-[7.5px] sm:right-14 lg:-right-10 xl:-right-2 max-w-[170px] 4xl:max-w-[200px] sm:max-w-[250px] md:max-w-[298px] -bottom-[19px] shadow-[0px_4px_4px_0px_#00000040] rounded-lg md:rounded-2xl'
                src={smallProfessional}
                alt='aboutsmall'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatingNewopportunity;
