import React from "react";
// import FooterLogo from "../../assets/images/webp/footerlogo.webp";
import FooterLogo from "../../assets/images/jpg/lincoln-logo.jpg";
import GooglePlay from "../../assets/images/webp/playstore.webp";
import AppleStore from "../../assets/images/webp/applestore.webp";
import { Facebook, Instagram, Twitter } from "./Icon";
import { Link } from "react-router-dom";
import ContactUsBox from "./ContactUsBox";

const Footer = () => {
  return (
    <>
      <ContactUsBox />
      <footer className='bg-secondary font-Poppins pt-[175px] '>
        <div className='container'>
          <div className='md:flex justify-between gap-6'>
            <div className='xs:text-center md:text-start'>
              {/* <img
                src={FooterLogo}
                alt='FooterLogo'
                className='w-[25%] xs:mx-auto md:mx-0'
              /> */}
              <p className='text-[#ffffff] opacity-60 max-w-[305px] xs:mx-auto md:mx-0 mx-0 md:max-w-[198px] mt-[22px] mb-[12px]'>
                1 Prairie Sun Lane Mattoon, IL 61938 United States
              </p>
              <div className='flex gap-4 xs:justify-center md:justify-start'>
                <a
                  className='hover:-translate-y-1 duration-300 ease-in-out'
                  href='https://facebook.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Facebook />
                </a>
                <a
                  className='hover:-translate-y-1 duration-300 ease-in-out'
                  href='https://twitter.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Twitter />
                </a>
                <a
                  className='hover:-translate-y-1 duration-300 ease-in-out'
                  href='https://instagram.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Instagram />
                </a>
              </div>
            </div>

            <div className='w-full  lg:w-1/2  text-white xs:flex justify-between mt-6 md:mt-11 lg:mt-[41px] gap-6'>
              <div className='w-[256px] flex justify-between '>
                <ul className='whitespace-nowrap '>
                  <li className='text-[#ffffff] font-semibold '>Quick Links</li>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='Search'
                      to='/search'
                    >
                      Search
                    </Link>
                  </li>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='About'
                      to='/about-us'
                    >
                      About us
                    </Link>
                  </li>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='Features'
                      to='/features'
                    >
                      Features
                    </Link>
                  </li>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='How it works'
                      to='/how-it-work'
                    >
                      How it works
                    </Link>
                  </li>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {/* <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='blog'
                      to='/blog'
                    >
                      Blogs
                    </Link> */}
                  </li>
                </ul>
                <ul className='mt-6  mx-[41px] whitespace-nowrap'>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='Security'
                      to='/security'
                    >
                      Security
                    </Link>
                  </li>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='Contact us'
                      to='/contact-us'
                    >
                      Contact us
                    </Link>
                  </li>
                  <li
                    className='opacity-70 mt-[10px]  duration-300 ease-in-out'
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      className='relative after:absolute after:h-[2px] after:w-0 after:bottom-0  after:bg-white after:left-[50%] 
                after:translate-x-[-50%] after:rounded-md 
              hover:left-0 !transition-ease-in-out after:duration-300 
              hover:opacity-100  hover:after:w-full'
                      aria-label='All sites page'
                      to='/all-sites'
                    >
                      All sites page
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className='mt-[18px] h-[2px] opacity-20 bg-white' />
          <p className='text-xs opacity-70 text-white text-center py-[14px]'>
            Copyright@2023
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
