/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
// import { Badge } from "reactstrap";
// import AppConfig from "Constants/AppConfig";

// import Button from "@mui/material/Button";

import { PNfetch } from "../../../api/pnfetch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
import { NotificationManager } from "react-notifications";
import * as $ from "jquery";
// import "video.js/dist/video-js.css";

// import {
//   rangePresets,
//   formatPhoneNumber,
//   printCustName,
//   printSiteName,
// } from "Util/common";
// import {
//   exportToExcelReport,
//   exportToPlainExcelReport,
// } from "../../util/export";
// import {
//   getEntitlementsDataSelector,
//   getSessionDataSelector,
//   getReferenceLayoutDataSelector,
//   getErrorDataSelector,
//   getReferenceDataSelector,
//   getMobileDeviceSelector,
//   getCustomersDataSelector,
// } from "../../reselect/sagaReselect";
// import { GlobalStoreContainer } from "../../store";
// import { filterTotalResults } from "Util/search";
import {
  Table,
  Switch,
  Radio,
  Form,
  Space,
  Input,
  InputNumber,
  Layout,
  Popconfirm,
  Typography,
  Select,
  Row,
  Col,
  Button,
  Menu,
  Popover,
  Dropdown,
  message,
  Modal,
  Checkbox,
  Empty,
  notification,
  Drawer,
  Card,
} from "antd";
// //import Highlighter from "react-highlight-words";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
import { formatMoney, unformat } from "accounting-js";
// import { FormInstance } from "antd/lib/form";
// import ReactDragListView from "react-drag-listview";
import * as uuid from "uuid";
import {
  DownOutlined,
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  DownloadOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
// intl messages
// //import IntlMessages from "Util/IntlMessages";
// import classnames from "classnames";
import { bindActionCreators } from "redux";
// import { types as entitlementTypes } from "../../reducers/entitlementsreducer";
// import { actions as entitlementActions } from "../../reducers/entitlementsreducer";
import { orderBy, trim, isNil, debounce, cloneDeep } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ClipLoader, GridLoader } from "react-spinners";
// import { css } from "@emotion/react";

const baseUrl = "https://streams.parknation.org:444/";

// Can be a string as well. Need to ensure each key-value pair ends with ; // border-color: red;
// const override = css`
//   display: block;
//   margin: 0 auto;
// `;

const { Header, Sider, Content } = Layout;
const Option = Select.Option;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const gridStyle = {
  width: "100%",
  textAlign: "center",
  borderRadius: "20px",
  overflow: "hidden",
  // backgroundColor: "#464d69",
};

class CameraRTC extends Component {
  grid = 8;

  iframeRef = React.createRef();

  onInputChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.debouncedGridSearch();
    });
  };

  state = {
    enodeVehicles: [],
    // invoice_user_id:
    //   this.props.sessionData.role == 4 ? this.props.userId : undefined,
    customer: undefined,
    evModalVisible: false,
    layoutId: "-1",
    paymentTransactions: [],
    payOutsdata: [],
    showDrawer: false,
    balance: undefined,
    selectedSite: 5,
    id: undefined,
    description: undefined,
    name: undefined,
    newSpaceTypeModalVisible: false,
    currentPage: 1,
    currentGridFilteredData: [],
    bordered: false,
    loading: false,
    filteredInfo: null,
    sortedInfo: null,
    //pagination,
    size: "default",
    //expandable,
    title: undefined,
    //showHeader,
    //footer,
    rowSelection: {},
    scroll: undefined,
    xScroll: "fixed",
    yScroll: true,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    searchText: "",
    searchedColumn: "",
    renderVersion: 1,
    editingKey: "",
    data: [],
    dataOrig: [],
    columnsToShow: this.columns,
    visible: false,
    selectedColumns: [],
  };

  formRef = React.createRef();
  formatNumber = (val) => {
    return formatMoney(val, {
      symbol: "$",
      precision: 2,
      thousand: ",",
      format: {
        pos: "%s %v",
        neg: "%s (%v)",
        zero: "%s --",
      },
    });
  };

  formatPerc = (val) => {
    return `${formatMoney(val, {
      symbol: "",
      precision: 2,
      thousand: ",",
      format: {
        pos: "%s %v",
        neg: "%s (%v)",
        zero: "%s --",
      },
    })} %`;
  };

  constructor(props) {
    super(props);
    // this.state = {
    //   criticalAlerts: [],
    // };
  }
  componentDidUpdate(prevProps, prevState) {}
  componentWillUnmount() {
    let player = $(`#videoPlayer${this.props.cameraId}`)[0];
    if (player) {
      player = null;
    }

    if (this.webrtc) {
      this.webrtc.close();
      delete this.webrtc._client;
      this.webrtc = null;

      // this.webrtc.close();
      // this.webrtc = null;
    }
  }

  startCamera = () => {
    $(`#videoPlayer${this.props.cameraId}`)[0].addEventListener(
      "loadeddata",
      () => {
        $(`#videoPlayer${this.props.cameraId}`)[0].play();
        // makePic();
      }
    );
    $(`#videoPlayer${this.props.cameraId}`)[0].addEventListener("error", () => {
      console.log("video_error");
    });
    this.startPlay();
  };
  componentDidMount() {
    setTimeout(() => {
      this.startCamera();
    }, 100);
  }

  openNotificationWithIcon = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };

  hasDBErrors = (retObj) => {
    if (!isNil(retObj.errCode) && retObj.errCode != "0") {
      return true;
    } else {
      return false;
    }
  };

  hasErrors = (retObj) => {
    //response.status >= 200 && response.status < 300
    // debugger;
    console.log("retObj", retObj);
    if (isNil(retObj)) {
      return true;
    } else if (
      (!isNil(retObj.data) &&
        !isNil(retObj.data.status) &&
        retObj.data.status != "succeeded" &&
        retObj.data.status != "processing") ||
      (!isNil(retObj.message) && retObj.messageType == "error")
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleCancel = () => {
    this.setState({ data: this.state.dataOrig });
  };

  onLiveDrawerClose = () => {
    this.setState({
      showDrawer: false,
    });
  };

  webrtc = null;
  mediaStream = null;

  //   disconnect = async() => {
  //   try {
  //     this.webrtc.removeEventListener("message", this.onSignalMessage);
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   try {
  //     this.dataChannel.removeEventListener("open", this.onChannelOpen);
  //     this.dataChannel.removeEventListener("close", this.onChannelClose);
  //     this.dataChannel.removeEventListener("message", this.onChannelMessage);
  //     delete this.dataChannel._client;
  //   } catch (err) {
  //      console.log(err);
  //   }
  //   try {
  //     this.webrtc.removeEventListener("message", this.handleSocketMessage);
  //     this.webrtc.removeEventListener("close", this.handleSocketClose);
  //     this.webrtc.removeEventListener(
  //       "negotiationneeded",
  //       this.onConnectionNegotiate
  //     );
  //     this.webrtc.removeEventListener("icecandidate", this.onICECandidate);
  //     this.webrtc.removeEventListener(
  //       "iceconnectionstatechange",
  //       this.onIceConnectionStateChange
  //     );
  //     this.webrtc.close();
  //     delete this.webrtc._client;
  //     this.webrtc = null;
  //   } catch (err) {
  //      console.log(err);
  //   }
  //   return true;
  // }

  startPlay = async () => {
    debugger;
    this.mediaStream = new MediaStream();
    $(`#videoPlayer${this.props.cameraId}`)[0].srcObject = this.mediaStream;
    this.webrtc = new RTCPeerConnection({
      iceServers: [
        {
          urls: ["stun:stun.l.google.com:19302"],
        },
      ],
      sdpSemantics: "unified-plan",
    });
    this.webrtc.onnegotiationneeded = this.handleNegotiationNeeded;
    this.webrtc.onsignalingstatechange = this.signalingstatechange;

    this.webrtc.ontrack = this.ontrack;
    let offer = await this.webrtc.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    });
    await this.webrtc.setLocalDescription(offer);
  };

  ontrack = (event) => {
    console.log(event.streams.length + " track is delivered");
    this.mediaStream.addTrack(event.track);
  };

  signalingstatechange = async () => {
    debugger;
    let uuid = this.props.cameraId; // $("#uuid").val();
    let channel = this.props.channel; //$("#channel").val();
    let url =
      baseUrl +
      "stream/" +
      uuid +
      "/channel/" +
      channel +
      "/webrtc?uuid=" +
      uuid +
      "&channel=" +
      channel;

    switch (this.webrtc.signalingState) {
      case "have-local-offer":
        $.post(
          url,
          {
            data: btoa(this.webrtc.localDescription.sdp),
          },
          (data) => {
            try {
              console.log(data);
              this.webrtc.setRemoteDescription(
                new RTCSessionDescription({
                  type: "answer",
                  sdp: atob(data),
                })
              );
            } catch (e) {
              console.warn(e);
            }
          }
        );
        break;
      case "stable":
        break;
      case "closed":
        break;

      default:
        console.log(
          `unhandled signalingState is ${this.webrtc.signalingState}`
        );
        break;
    }
  };

  handleNegotiationNeeded = async () => {
    debugger;
    let uuid = this.props.cameraId; //$("#uuid").val();
    let channel = this.props.channel; // $("#channel").val();
    let url =
      baseUrl +
      "stream/" +
      uuid +
      "/channel/" +
      channel +
      "/webrtc?uuid=" +
      uuid +
      "&channel=" +
      channel;
    let offer = await this.webrtc.createOffer();

    await this.webrtc.setLocalDescription(offer);
    $.post(
      url,
      {
        data: btoa(this.webrtc.localDescription.sdp),
      },
      (data) => {
        try {
          console.log(data);
          this.webrtc.setRemoteDescription(
            new RTCSessionDescription({
              type: "answer",
              sdp: atob(data),
            })
          );
        } catch (e) {
          console.warn(e);
        }
      }
    );
  };

  openKisiGate = () => {
    //  // debugger;
    //https://api.brivo.com/v1/api/users
    let URL = process.env.REACT_APP_API_URL + `/kisi/unlockGateWOFence`;
    return new Promise((resolve, reject) => {
      // fetch("http://localhost:8080/brivoUsers", {
      PNfetch(URL, {
        // fetch("/brivoAPIUsers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });
  };

  openGate = async () => {
    const resultObj = await this.openKisiGate();
    if (!this.hasErrors(resultObj)) {
      this.openNotificationWithIcon(
        "success",
        "Kisi",
        "Gate opened successfully."
      );
      // this.props.history.push({
      //   pathname: "/striperesponse",
      // });
    } else {
      //Display Error
      this.openNotificationWithIcon(
        "error",
        "Error",
        resultObj.message ? resultObj.message : "An error has occured!"
      );
    }
  };

  render() {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Typography.Paragraph style={{ color: "black", marginBottom: "8px" }}>
          <div>
            <span>{this.props.cameraName}</span>
          </div>
        </Typography.Paragraph>
        <div
          style={{
            borderRadius: "0 0 8px 8px",
            overflow: "hidden",
            width: "100%",
            height: this.props.cameraHeight,
          }}
          // onClick={(e) => this.showLiveCameraVideo(camera)}
        >
          <div data-vjs-player>
            <video
              id={`videoPlayer${this.props.cameraId}`}
              ref={(node) => (this.videoNode = node)}
              className="video-js video-js-enhanced vjs-big-play-centered"
              autoPlay
              controls
              muted
              playsInline
            ></video>
          </div>
          <canvas id="canvas" className="d-none"></canvas>
          {/* <input
                    type="hidden"
                    id="uuid"
                    value={this.props.cameraId}
                    // value="27aec28e-6181-4753-9acd-0456a75f0289"
                  />
                  <input
                    type="hidden"
                    id="channel"
                    value={this.props.channel}
                  /> */}
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = (state, ownProps) => {
  console.log("state", state);
  // const entitlementData = getEntitlementsDataSelector(state);
  // const sessionData = getSessionDataSelector(state);
  // const layoutData = getReferenceLayoutDataSelector(state);
  // const errorData = getErrorDataSelector(state);
  // const referenceData = getReferenceDataSelector(state);
  // const isMobile = getMobileDeviceSelector(state);
  // const customersData = getCustomersDataSelector(state);
  // const operatorUsers = customersData.filter(
  //   (rec) => rec && rec.role.toString() == "3"
  // );
  //    const userId = authUser.user ? authUser.user.id : null;
  return {
    // data: entitlementData,
    // userId: state.authUser.user.user_id,
    // sessionData,
    // layoutData,
    // errorData,
    // referenceData,
    // // loading:
    // //   operatorUsers.length > 0 && state.entitlementsData
    // //     ? state.entitlementsData.loading
    // //     : false,
    // isMobile,
    // customersData,
    // operatorUsers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      // ...entitlementActions,
      // ...actionActions,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(CameraRTC);
