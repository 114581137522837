import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "./Header";
import Login from "./../../view/Login";
import SignUp from "./../../view/SignUp";
import Homepage from "../../view/ForTruckDrivers/Homepage";
import AboutUs from "../../view/ForTruckDrivers/AboutUs";
import SearchPage from "./../../view/ForTruckDrivers/SearchPage";
import HowItWork from "../../view/ForTruckDrivers/HowItWork";
import Blog from "./../../view/ForTruckDrivers/Blog";
import BlogDetails from "./../../view/ForTruckDrivers/BlogDetails";
import Security from "./../../view/ForTruckDrivers/Security";
import Fact from "./../../view/ForTruckDrivers/Fact";
import ContactUs from "./../../view/ForTruckDrivers/ContactUs";
import AllSites from "./../../view/ForTruckDrivers/AllSites";
import Features from "../../view/ForSiteOwners/Features";
import Fintech from "../../view/ForSiteOwners/Fintech";
import Footer from "../../components/common/Footer";
import Howitworkpage from "../ForTruckDrivers/howitwork/Howitworkpage";
import Authenticator from "../../view/Authenticator";
import GetStarted from "../../view/GetStarted";
import SignIn from "../../view/SignIn";
import RecoveryCode from "../../view/RecoveryCode";
import AccountSignUp from "../../view/AccountSignUp";
import NotFound from "./NotFound";
import Backtotop from "./Backtotop";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

// const browserHistory = createBrowserHistory({ window });

// import AddCustomer from "../ForTruckDrivers/addcustomer/index";

// const Logout = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   useEffect(() => {
//     dispatch(logout()).then(() => {
//       navigate("/", { replace: true });
//     });
//   }, []);

//   return <LoadingSpinner />;
// };

export const withRouter = (Component) => {
  debugger;
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

const NationRoute = () => {
  debugger;
  const location = useLocation();
  // let user;
  // user = useSelector((state) => (state ? state.authUser.refreshToken : false));
  // console.log("state.authUser Route", state.authUser);
  return (
    <>
      {/* <HistoryRouter history={browserHistory}> */}
      {/* {
        // location.pathname === "/sign-up" ||
        location.pathname === "/login" ? (
          // location.pathname === "/sign-in" ||
          // location.pathname === "/recovery-code" ||
          // location.pathname === "/account-sign-up" ||
          // location.pathname === "/get-started" ||
          // location.pathname === "/authenticator"
          ""
        ) : (
          <Header />
        )
      }  */}
      <Header />
      <Routes>
        <Route path='/login' element={<Login />} />
        {/* <Route path="/logout" element={<LogOut />} /> */}
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/authenticator' element={<Authenticator />} />
        <Route path='/get-started' element={<GetStarted />} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/recovery-code' element={<RecoveryCode />} />
        <Route path='/account-sign-up' element={<AccountSignUp />} />

        {/* FOR TRUCK DRIVERS  */}
        <Route path='/' element={<Homepage />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/how-it-work' element={<Howitworkpage />} />
        <Route path='/search' element={<SearchPage />} />
        <Route path='/sign-up' element={<HowItWork />} />
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path='/blog-detail' element={<BlogDetails />} />
        {/* <Route path="/security" element={<Security />} /> */}
        <Route path='/fact' element={<Fact />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/all-sites' element={<AllSites />} />
        {/* <Route path="/reserve" element={<AddCustomer />} /> */}

        {/* FOR Site Owners  */}
        <Route path='/features' element={<Features />} />
        <Route path='/fintech' element={<Fintech />} />
        <Route path='' element={<NotFound />} />
      </Routes>
      {location.pathname === "/login" ||
      location.pathname === "/sign-up" ||
      location.pathname === "/get-started" ||
      location.pathname === "/sign-in" ||
      location.pathname === "/account-sign-up" ||
      location.pathname === "/recovery-code" ||
      location.pathname === "/authenticator" ? (
        ""
      ) : (
        <Footer />
      )}
      <Backtotop />
      {/* </HistoryRouter> */}
    </>
  );
};

export default withRouter(NationRoute);
