import React from "react";
import Watching from "../../../assets/images/jpg/AboutUsPageimage4.jpg";
import { Link } from "react-router-dom";
const ParkNationLooking = () => {
  return (
    <>
      <section className='lg:py-[50px]'>
        <div className='container py-12'>
          <div className='lg:flex items-center justify-between'>
            <div className=' w-full lg:w-[50%] xl:w-[40%]'>
              <img
                className='w-full lg:h-[423px] max-w-[500px] lg:max-w-auto m-auto'
                src={Watching}
                alt='truck-waiting'
              />
            </div>
            <div className=' w-full lg:w-[60%] xl:w-[70%] lg:max-w-[554px] m-auto'>
              <div className='mt-8 lg:mt-0 lg:ps-10 xl:ps-0'>
                <h3 className='text-black font-bold lg:leading-[55px] text-lg md:text-[32px] lg:text-xl font-Manrope mb-4'>
                  Why Choose Lincoln Storage?
                </h3>
                <ol className='font-Poppins text-[#000000] font-normal leading-[25.6px] mb-8 opacity-70 text-base list-decimal px-4'>
                  <li className='mb-4'>
                    <span className='font-bold'>Secure and Reliable</span>: At
                    Lincoln Storage, we prioritize the safety and security of
                    your belongings. Our facilities are equipped with advanced
                    security systems, including 24/7 surveillance cameras,
                    secure access controls, and on-site staff to ensure that
                    your items are protected at all times.
                  </li>
                  <li className='mb-4'>
                    <span className='font-bold'>Flexible Storage Options</span>:
                    We offer a wide range of storage units to accommodate your
                    specific needs. Whether you need a small unit for personal
                    items or a larger space for business inventory, we have the
                    perfect solution for you. Our units are clean,
                    well-maintained, and climate-controlled to protect your
                    belongings from damage.
                  </li>
                  <li className='mb-4'>
                    <span className='font-bold'>Convenient Location</span>: Our
                    storage facilities are conveniently located in strategic
                    areas, making it easy for you to access your stored items
                    whenever you need them. Whether you're moving to a new home,
                    decluttering your space, or need temporary storage during a
                    renovation, we are here to make the process as smooth as
                    possible.
                  </li>
                  <li className='mb-4'>
                    <span className='font-bold'>Competitive Pricing</span>: We
                    believe that quality storage should be affordable. That's
                    why we offer competitive pricing options to fit your budget.
                    With no hidden fees or long-term commitments, you can trust
                    that you're getting the best value for your money with
                    Lincoln Storage.
                  </li>
                  <li className='mb-4'>
                    <span className='font-bold'>
                      Exceptional Customer Service
                    </span>
                    : Our friendly and knowledgeable staff is dedicated to
                    providing you with exceptional customer service. We are here
                    to answer any questions you may have, assist you in choosing
                    the right storage unit, and ensure that your storage
                    experience is hassle-free.
                  </li>
                </ol>
                <p className='font-Poppins text-[#000000] font-normal leading-[25.6px] mb-0 opacity-70 text-base'>
                  Contact Us Today If you're in need of a reliable and secure
                  storage solution, look no further than Lincoln Storage.
                  Contact us today to learn more about our storage options and
                  to reserve your unit. Experience the peace of mind that comes
                  with storing your belongings with Lincoln Storage.
                </p>
                <Link
                  to='/sign-up'
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className='first_btn px-[26px] py-[14px]  inline-block mt-6 lg:mt-11 text-base font-Manrope font-semibold'
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ParkNationLooking;
