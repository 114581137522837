import React from "react";
import EvCharging from "../../../assets/images/jpg/DataOrganization.jpg";
const DataOrganization = () => {
  return (
    <>
      <section className='py-12 md:py-20 lg:py-28 xl:py-[100px] bg-[rgba(168,168,168,0.08)] overflow-x-hidden'>
        <div className='container'>
          <div className='flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center'>
            <div className='order-2 lg:order-none' data-aos='fade-right'>
              <div className='max-w-[558px]'>
                <h2 className='text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4'>
                  Data Organization{" "}
                </h2>
                <p className='text-base font-Poppins font-normal xl:pe-20 text-[#000] leading-[160%] opacity-70 '>
                  Storage units provide tools and features to help users
                  organize and categorize their data. This makes it easier to
                  locate specific files or information when needed, improving
                  productivity and efficiency.
                </p>
              </div>
            </div>
            <div data-aos='fade-left' data-aos-duration='1500'>
              <div className='max-w-[520px]'>
                <img className='w-full' src={EvCharging} alt='EvCharging' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataOrganization;
