import React from "react";
import LicenceDash from "../../../assets/images/webp/license-plate.webp";
import { CheckIcon } from "../../common/Icon";

const BoxAccess = () => {
  return (
    <>
      <section className='py-12 md:py-20 lg:py-28 xl:py-[100px] bg-[rgba(168,168,168,0.08)] overflow-x-hidden'>
        <div className='container'>
          <div className='flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center'>
            <div
              className='order-2 lg:order-none xl:pe-12'
              data-aos='fade-right'
            >
              <div className='max-w-[516px]'>
                <h2 className='text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4'>
                  Access to your box 24/7{" "}
                </h2>
                <p className='text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 '>
                  Lincoln Storage helps manage the units to keep your storage
                  safe and secure
                </p>
              </div>
            </div>
            <div data-aos='fade-left' data-aos-duration='1500'>
              <div className='max-w-[522px] '>
                <img className='w-full' src={LicenceDash} alt='EvCharging' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxAccess;
